import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar } from '@minna-technologies/minna-ui/components/Snackbar';
import { ClientKeyProps } from '../../store/ducks/authentication';
import isEmpty from 'lodash/isEmpty';
import { colors } from '../style/colors';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { ClientKeyCard } from './ClientKeyCard';
import { NoActiveKeysComponent } from './NoActiveKeysComponent';

const useStyles = makeStyles(() => ({
  container: {
    marginLeft: '8px',
    width: '100%',
    height: 'fit-content',
  },
  activeKeysTitle: {
    color: colors.black80,
    paddingBottom: '16px',
  },
}));

interface ListClientKeysComponentProps {
  clientKeys: ClientKeyProps[];
}

export const ListClientKeysComponent = (props: ListClientKeysComponentProps) => {
  const classes = useStyles();
  const [copyClientKeyIdSnackbar, setCopyClientKeyIdSnackbarContent] = useState('');

  const showSnackbar = !!copyClientKeyIdSnackbar;

  const ActiveKeysHeader = () => <Title className={classes.activeKeysTitle}>Active keys</Title>;
  if (isEmpty(props.clientKeys)) {
    return (
      <div>
        <ActiveKeysHeader />
        <NoActiveKeysComponent />
      </div>
    );
  } else {
    return (
      <div className={classes.container}>
        <ActiveKeysHeader />
        {props.clientKeys.map((clientKey: ClientKeyProps) => {
          return (
            <ClientKeyCard
              key={clientKey.id}
              clientKey={clientKey}
              setCopyClientKeyIdSnackbarContent={setCopyClientKeyIdSnackbarContent}
            />
          );
        })}
        <Snackbar
          autoClose
          variant={'default'}
          open={showSnackbar}
          message={copyClientKeyIdSnackbar}
          data-test={'copy-client-key-snackbar'}
          onClose={() => setCopyClientKeyIdSnackbarContent('')}
        />
      </div>
    );
  }
};
