import { AppDispatch, RootState } from '../setup';
import { callWithErrorHandler } from '../../app/error/CommonErrorListner';
import { Market } from '@minna-technologies/integration-portal-types/tech/minna/models';
import * as ClientKeyApi from '@minna-technologies/integration-portal-v1/ClientKeyApi';
import * as APIKeysApi from '@minna-technologies/integration-portal-v1/APIKeysAPI';
import { ListIntegrationPortalClientKeyResponse } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/clientkey';
import { BasicErrorResponse, parseErrorToBasicErrorResponse } from '../../app/error/ServerErrorModels';
import { Sentry } from '../../utils/sentry';
import { APIKeyResponse } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/apikey';

export enum RegisterClientStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  NOT_STARTED = 'NOT_STARTED',
}

export interface ClientKeyProps {
  id: string;
  name: string;
  market: Market;
  clientNamespaceAccessLevel: string;
  // createdAt: Date; // TODO this does not exist in data. Look at how we can improve that.
}

export interface APIKeyProps {
  id: string;
  name: string;
  prefix: string;
  createdAt: string;
  market: Market;
}

interface AuthenticationState {
  clientKeys: ClientKeyProps[];
  registerClientKeyStatus: RegisterClientStatus;
  maybeRegisterErrorMessage: string | undefined;
  apiKeys: APIKeyProps[];
  temporaryPlainTextApiKey: string | undefined;
}

const initialState: AuthenticationState = {
  clientKeys: [],
  registerClientKeyStatus: RegisterClientStatus.NOT_STARTED,
  maybeRegisterErrorMessage: undefined,
  apiKeys: [],
  temporaryPlainTextApiKey: undefined,
};

export const authenticationSelectors = {
  clientKeys: (state: RootState) => state.authentication.clientKeys,
  registerClientKeyStatus: (state: RootState) => state.authentication.registerClientKeyStatus,
  maybeRegisterErrorMessage: (state: RootState) => state.authentication.maybeRegisterErrorMessage,
  apiKeys: (state: RootState) => state.authentication.apiKeys,
  temporaryPlainTextApiKey: (state: RootState) => state.authentication.temporaryPlainTextApiKey,
};

export const authenticationActions = {
  listClientKeys,
  registerClientKey,
  resetRegisterClientKeyStatus,
  listAPIKeys,
  registerNewAPIKey,
  removePlainTextAPIKey,
};

export const actionTypes = {
  SET_CLIENT_KEYS: 'SET_CLIENT_KEYS',
  REGISTER_CLIENT_KEY: 'REGISTER_CLIENT_KEY',
  SET_CLIENT_KEY_REGISTRATION_STATUS: 'SET_CLIENT_KEY_REGISTRATION_STATUS',
  RESET_CLIENT_KEY_REGISTRATION_STATUS: 'RESET_CLIENT_KEY_REGISTRATION_STATUS',
  SET_REGISTER_CLIENT_KEY_ERROR: 'SET_REGISTER_CLIENT_KEY_ERROR',
  SET_API_KEYS: 'SET_API_KEYS',
  SET_TEMPORARY_PLAIN_TEXT_API_KEY: 'SET_TEMPORARY_PLAIN_TEXT_API_KEY',
};

const authenticationEvents = {
  setClientKeys: (clientKeys: ClientKeyProps[]) => ({
    type: actionTypes.SET_CLIENT_KEYS,
    clientKeys,
  }),
  registerClientKey: (clientKey: ClientKeyProps) => ({
    type: actionTypes.REGISTER_CLIENT_KEY,
    clientKey,
  }),
  setClientKeyRegistrationStatus: (status: RegisterClientStatus) => ({
    type: actionTypes.SET_CLIENT_KEY_REGISTRATION_STATUS,
    status,
  }),
  resetRegisterClientKeyStatus: () => ({
    type: actionTypes.RESET_CLIENT_KEY_REGISTRATION_STATUS,
  }),
  setRegisterClientKeyError: (error: string | undefined) => ({
    type: actionTypes.SET_REGISTER_CLIENT_KEY_ERROR,
    error,
  }),
  setApiKeys: (apiKeys: APIKeyResponse[]) => ({
    type: actionTypes.SET_API_KEYS,
    apiKeys,
  }),
  setTemporaryPlainTextApiKey: (temporaryPlainTextApiKey: string | undefined) => ({
    type: actionTypes.SET_TEMPORARY_PLAIN_TEXT_API_KEY,
    temporaryPlainTextApiKey,
  }),
};

export const authenticationReducer = (state: RootState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_CLIENT_KEYS:
      return {
        ...state,
        clientKeys: action.clientKeys,
      };
    case actionTypes.REGISTER_CLIENT_KEY: {
      const currentClientKeys = state.clientKeys;
      const newClientKeys = currentClientKeys.concat([action.clientKey]);
      return {
        ...state,
        clientKeys: newClientKeys,
      };
    }
    case actionTypes.SET_CLIENT_KEY_REGISTRATION_STATUS:
      return {
        ...state,
        registerClientKeyStatus: action.status,
      };
    case actionTypes.RESET_CLIENT_KEY_REGISTRATION_STATUS:
      return {
        ...state,
        registerClientKeyStatus: RegisterClientStatus.NOT_STARTED,
      };
    case actionTypes.SET_REGISTER_CLIENT_KEY_ERROR:
      return {
        ...state,
        maybeRegisterErrorMessage: action.error,
      };
    case actionTypes.SET_API_KEYS:
      return {
        ...state,
        apiKeys: action.apiKeys,
      };
    case actionTypes.SET_TEMPORARY_PLAIN_TEXT_API_KEY:
      return {
        ...state,
        temporaryPlainTextApiKey: action.temporaryPlainTextApiKey,
      };

    default:
      return state;
  }
};

function registerClientKey(
  environmentName: string,
  clientKeyName: string,
  clientNamespace: string,
  market: string,
  publicKey: string
) {
  return async (dispatch: AppDispatch) => {
    try {
      const clientNamespaceOrDefault = clientNamespace === '' ? 'default' : clientNamespace;
      const newClientKey = await ClientKeyApi.registerClientKey(environmentName, {
        clientNamespaceAccessLevel: clientNamespaceOrDefault,
        name: clientKeyName,
        market: market as Market,
        publicKey: publicKey,
      });
      dispatch(authenticationEvents.registerClientKey(newClientKey));
      dispatch(authenticationEvents.setClientKeyRegistrationStatus(RegisterClientStatus.SUCCESS));
    } catch (error) {
      dispatch(authenticationEvents.setClientKeyRegistrationStatus(RegisterClientStatus.FAILURE));
      const maybeErrorMessage: BasicErrorResponse | undefined = parseErrorToBasicErrorResponse(error as Error);
      Sentry.captureExceptionWithMessage(error, 'Failed to insert client key', { extra: { environmentName, market } });
      if (maybeErrorMessage) {
        dispatch(authenticationEvents.setRegisterClientKeyError(maybeErrorMessage.error.message));
      } else {
        dispatch(authenticationEvents.setRegisterClientKeyError('Unexpected error registering client key'));
      }
    }
  };
}

function listClientKeys(environmentName: string) {
  return async (dispatch: AppDispatch) => {
    try {
      const clientKeys: ListIntegrationPortalClientKeyResponse = await callWithErrorHandler(
        ClientKeyApi.listClientKeys(environmentName)
      );
      dispatch(authenticationEvents.setClientKeys(clientKeys.clientKeys));
    } catch (err) {
      Sentry.captureExceptionWithMessage(err, 'Failed to fetch client keys', { extra: { environmentName } });
      throw err;
    }
  };
}

function resetRegisterClientKeyStatus() {
  return async (dispatch: AppDispatch) => {
    dispatch(authenticationEvents.resetRegisterClientKeyStatus());
    dispatch(authenticationEvents.setRegisterClientKeyError(undefined));
  };
}

function listAPIKeys(environmentName: string) {
  return async (dispatch: AppDispatch) => {
    const apiKeys = await APIKeysApi.listApiKeysForPlatform(environmentName);
    dispatch(authenticationEvents.setApiKeys(apiKeys.apiKeys));
  };
}

function registerNewAPIKey(apiKeyName: string, market: Market, environmentName: string) {
  return async (dispatch: AppDispatch) => {
    const newAPIKey = await APIKeysApi.generateAPIKey(environmentName, { name: apiKeyName, market });
    dispatch(authenticationEvents.setTemporaryPlainTextApiKey(newAPIKey.plainTextKey));
    dispatch(authenticationActions.listAPIKeys(environmentName));
  };
}

function removePlainTextAPIKey() {
  return async (dispatch: AppDispatch) => {
    dispatch(authenticationEvents.setTemporaryPlainTextApiKey(undefined));
  };
}
