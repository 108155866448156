import React, { ReactElement } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../style/colors';
import {
  EnvironmentName,
  EnvironmentType,
} from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    paddingLeft: '16px',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
  },
  environmentName: {
    color: colors.black,
  },
  environmentText: {
    color: colors.black80,
  },
}));

const getEnvironmentText = (environmentName: EnvironmentName, environmentType: EnvironmentType) => {
  const lowerCaseEnvName = environmentName.toLowerCase();
  if (environmentType == EnvironmentType.TestEnvironment) {
    if (lowerCaseEnvName == 'sandbox') {
      return 'Test environment for testing Minna’s APIs.';
    } else if (lowerCaseEnvName == 'pre-production') {
      return 'Test environment for testing Minna’s APIs.';
    } else {
      return 'Custom test environment';
    }
  } else if (environmentType == EnvironmentType.Production) {
    return 'Live environment with real customers.';
  } else {
    return 'Local development environment';
  }
};

interface EnvironmentProps {
  environmentName: EnvironmentName;
  environmentType: EnvironmentType;
}

export const EnvironmentComponent: React.FunctionComponent<EnvironmentProps> = (
  props: EnvironmentProps
): ReactElement => {
  const classes = useStyles();
  return (
    <span className={classes.mainContainer}>
      <span className={classes.textContainer}>
        <b className={classes.environmentName}>{capitalizeFirstLetter(props.environmentName)}</b>
        <span className={classes.environmentText}>
          {getEnvironmentText(props.environmentName, props.environmentType)}
        </span>
      </span>
    </span>
  );
};
