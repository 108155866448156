import { Sentry } from '../../utils/sentry';

export enum MixpanelEvents {
  OVERALL_CSAT_RATING = 'Overall CSAT rating',
  OVERALL_CSAT_COMMENT = 'Overall CSAT comment',
  VIEWED_PAGE = 'Viewed page',
  CLICKED_BUTTON = 'Clicked button',
}

export enum Page {
  DASHBOARD = 'Dashboard',
  GET_STARTED = 'Get started',
  AUTHENTICATION = 'Authentication',
  CANCELLATION_LIST = 'Cancellation list',
  CANCELLATION_DETAILS = 'Cancellation details',
  SECURITY = 'Security',
  ERROR = 'Error',
}

export const pageFromUrl = (url: URL) => {
  const path = url.pathname;
  if (path.includes('/authentication')) return Page.AUTHENTICATION;
  else if (path.includes('/dashboard')) return Page.DASHBOARD;
  else if (path.includes('/get-started')) return Page.GET_STARTED;
  else if (path.endsWith('/cancellations')) return Page.CANCELLATION_LIST;
  else if (path.includes('/cancellations/')) return Page.CANCELLATION_DETAILS;
  else {
    Sentry.captureMessage('Could not find a matching Page from the URL', { extra: { currentUrl: url } });
    return Page.ERROR; // Returning something so that the app don't crash
  }
};

export enum Location {
  Menu = 'Menu',
  Center = 'Center',
  Right = 'Right',
  BottomRight = 'Bottom right',
  TopLeft = 'Top left',
  TopRight = 'Top right',
}
