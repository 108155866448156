import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StarAndCommentRating } from './StarAndCommentRating';
import { trackingActions, trackingSelectors } from '../../store/ducks/tracking';
import {
  CsatInteractionType,
  CsatType,
} from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/user/customersatisfaction';
import { makeStyles } from '@material-ui/core/styles';
import { mixpanelService } from '../../services/mixpanel/mixpanelService';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
  },
}));
export const OverallCsatComponent = () => {
  const dispatch = useDispatch();
  const showOverallCsat = useSelector(trackingSelectors.showOverallCsat);
  const [rating, setRating] = useState(0);

  const trackCsat = (rating: number) => {
    setRating(rating);
    mixpanelService.trackOverallCsat(rating);
    dispatch(
      trackingActions.registerCsatQuestionAttempt(CsatType.OverallCsat, CsatInteractionType.AnsweredCsatQuestion)
    );
  };

  const trackFeedback = (feedback: string) => {
    mixpanelService.trackOverallCsatWithComment(rating, feedback);
    setTimeout(() => hideCsat(), 2000); // To show the "Thank you" dialog for 2 seconds.
  };

  const registerDismissedQuestionAndClose = () => {
    dispatch(
      trackingActions.registerCsatQuestionAttempt(CsatType.OverallCsat, CsatInteractionType.DismissedCsatQuestion)
    );
    hideCsat();
  };

  const hideCsat = () => {
    dispatch(trackingActions.setShowOverallCSAT(false));
  };
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {!!showOverallCsat && (
        <StarAndCommentRating
          starQuestionText="Overall, how satisfied are you with working in this portal?"
          starBodyText="Your feedback helps us improve it."
          textQuestionText="Thank you!"
          textBodyText="Would you like to share any other feedback with us to explain your rating?"
          onRatingGiven={trackCsat}
          onTextFeedbackGiven={trackFeedback}
          onClose={registerDismissedQuestionAndClose}
          data-test="overall-csat"
        />
      )}
    </div>
  );
};
