import React from 'react';
import { IconProps, SvgIcon } from '@minna-technologies/minna-ui/icons';

export const BookIcon: React.ComponentType<IconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <svg width="24" height="22" viewBox="0 0 24 22" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.819318 0.08048C0.978173 0.0209095 1.1474 -0.00607219 1.3169 0.0011439C5.08356 0.165744 8.75129 1.24887 12 3.15306C15.2487 1.24887 18.9156 0.16578 22.6823 0.00118026C22.8518 -0.00603583 23.0218 0.0209095 23.1807 0.08048C23.3391 0.139894 23.484 0.230514 23.6068 0.346961C23.7309 0.46436 23.8298 0.605836 23.8974 0.762752C23.9651 0.919943 24 1.08931 24 1.26047C24 1.26061 24 1.26032 24 1.26047V17.9995C23.9997 18.3246 23.8739 18.6377 23.6489 18.8724C23.4239 19.107 23.117 19.2458 22.7922 19.2598C22.7461 19.2618 22.6999 19.2595 22.6542 19.253C21.0343 19.0222 19.3847 19.1167 17.8017 19.5308C16.2187 19.945 14.7342 20.6705 13.4348 21.665C13.3831 21.7047 13.3263 21.7374 13.2661 21.7623C12.8823 21.9214 12.4677 21.9896 12.0544 21.9625C12.0365 21.9638 12.0183 21.9645 12 21.9645C11.9817 21.9645 11.9635 21.9638 11.9456 21.9625C11.5323 21.9896 11.1177 21.9214 10.7339 21.7623C10.6737 21.7374 10.6169 21.7047 10.5652 21.665C9.26581 20.6705 7.78127 19.945 6.19829 19.5308C4.61532 19.1167 2.96567 19.0222 1.34576 19.253C1.30008 19.2595 1.25388 19.2618 1.20777 19.2598C0.882983 19.2458 0.576112 19.107 0.35111 18.8724C0.126109 18.6378 0.000335097 18.3253 4.17233e-07 18.0002L0 1.25345C0.000910342 1.0834 0.036327 0.915298 0.104086 0.759326C0.171665 0.603766 0.270025 0.463495 0.393238 0.346956C0.515995 0.23051 0.660884 0.139893 0.819318 0.08048ZM12.75 20.3039C14.1526 19.2753 15.7377 18.5204 17.422 18.0797C19.0789 17.6462 20.8008 17.5249 22.5 17.7208V1.512C19.0615 1.7018 15.7188 2.71019 12.75 4.45214V20.3039ZM11.25 4.45214C8.2812 2.71019 4.93851 1.7018 1.5 1.512V17.7208C3.1992 17.5249 4.92113 17.6462 6.57796 18.0797C8.26234 18.5204 9.84737 19.2753 11.25 20.3039V4.45214ZM23.6068 0.346961C23.6071 0.347268 23.6074 0.347575 23.6077 0.347883L23.091 0.891465L23.6058 0.34604C23.6061 0.346347 23.6064 0.346654 23.6068 0.346961Z"
      />
    </svg>
  </SvgIcon>
);
