import { useSelector } from 'react-redux';
import { ConsoleViewOption, userSelectors } from '../../store/ducks/user';
import * as urls from './urls';
import { externalAuthenticationLinkLegacy, externalDocsMainLink } from './urls';

/**
 * We have different links depending on if the user is using v1/2 or v3 of our API. We should therefor link to different
 * URLs. This hook helps with the logic.
 */
export interface VersionedDocsUrls {
  authenticationArticle: string;
  mainDocs: string;
  apiReference: string;
}

export const useVersionedDocsUrls = () => {
  const currentVersion = useSelector(userSelectors.currentConsoleViewOption);

  return {
    authenticationArticle: versioned(urls.externalAuthenticationLinkV3, externalAuthenticationLinkLegacy),
    mainDocs: versioned(urls.externalDocsMainLinkV3, externalDocsMainLink),
    apiReference: versioned(urls.externalApiReferenceV3, urls.externalApiReferenceLink),
  } as VersionedDocsUrls;

  function versioned(latest: string, older: string) {
    return currentVersion === ConsoleViewOption.API_VERSION_LEGACY ? older : latest;
  }
};
