import { AppDispatch, RootState } from '../setup';
import { shouldShowOverallCsat } from '../../app/customersatisfaction/customerSatisfactionLogic';
import * as CustomerSatisfactionApi from '@minna-technologies/integration-portal-v1/CustomerSatisfactionApi';
import {
  CsatInteractionType,
  CsatType,
} from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/user/customersatisfaction';
import { userSelectors } from './user';
import { parseISO } from 'date-fns';

interface TrackingState {
  showOverallCsat: boolean;
}

const initialState: TrackingState = {
  showOverallCsat: false,
};

export const trackingSelectors = {
  showOverallCsat: (state: RootState) => state.tracking.showOverallCsat,
};

export const trackingActions = {
  registerCsatQuestionAttempt,
  fetchCsatInteractionsHistory,
  setShowOverallCSAT,
};

export const actionTypes = {
  SET_SHOW_OVERALL_CSAT: 'SET_SHOW_OVERALL_CSAT',
};

const trackingEvents = {
  setShowOverallCSAT: (showOverallCsat: boolean) => ({ type: actionTypes.SET_SHOW_OVERALL_CSAT, showOverallCsat }),
};

export const trackingReducer = (state: RootState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SHOW_OVERALL_CSAT:
      return {
        ...state,
        showOverallCsat: action.showOverallCsat,
      };
    default:
      return state;
  }
};

// ACTIONS

function fetchCsatInteractionsHistory() {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const user = userSelectors.user(getState());
    const mixpanelFeatureToggle = userSelectors.featureToggles(getState()).mixpanel;
    const interactions = await CustomerSatisfactionApi.getCsatInteractions();
    const showOverallCsatWidget = shouldShowOverallCsat(
      parseISO(user.createdAt),
      interactions.csatInteractions,
      mixpanelFeatureToggle
    );
    dispatch(trackingEvents.setShowOverallCSAT(showOverallCsatWidget));
  };
}

function registerCsatQuestionAttempt(type: CsatType, interactionType: CsatInteractionType) {
  return async () => {
    await CustomerSatisfactionApi.registerCsatQuestionAttempts({ type, interactionType });
  };
}

function setShowOverallCSAT(show: boolean) {
  return async (dispatch: AppDispatch) => {
    dispatch(trackingEvents.setShowOverallCSAT(show));
  };
}
