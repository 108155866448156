import React, { useEffect } from 'react';
import { IntroCardTemplate } from '../../components/templates/IntroCardTemplate';
import { NumberedList } from '../../components/NumberedList';
import { CopyButton } from '../../components/CopyButton';
import { Highlight } from './Highlight';
import { ClientEnvironment } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { makeStyles } from '@material-ui/core/styles';
import { BodyBlack80 } from '../../components/text/BodyBlack80';
import { useEnvironmentName } from '../../components/EnvironmentNameHook';
import { mixpanelService } from '../../../services/mixpanel/mixpanelService';
import { Location, Page } from '../../../services/mixpanel/mixpanelEvents';
import { Sentry } from '../../../utils/sentry';

const useStyles = makeStyles(() => ({
  listContainer: {
    marginTop: '16px',
  },
  textRow: {
    marginBottom: '8px',
  },
  code: {
    lineHeight: '1.5',
    marginRight: '16px',
  },
  stepOne: {
    marginBottom: '16px',
  },
  spacer: {
    display: 'inline-block',
    width: '32px',
  },
  codeContent: {
    display: 'flex',
    alignItems: 'center',
  },
}));
export const GetStartedWithV3 = ({ environment }: { environment: ClientEnvironment }) => {
  const classes = useStyles();
  const environmentName = useEnvironmentName();
  const apiKey = environment.maybeAPIKey?.plainTextKey;
  const copyCode = `curl --request GET \\
  --url ${environment.integrationApiUrl}/v3/ping \\
  --header 'Content-Type: application/json' \\
  --header 'Authorization: Bearer ${apiKey || 'YOUR_API_KEY'}'
  `;

  useEffect(() => {
    if (!apiKey) {
      Sentry.captureMessage('No Default API key was available for this environment', { extra: { environmentName } });
    }
  }, [apiKey, environmentName]);
  const trackClickedCopyAPIKey = () => {
    mixpanelService.trackClickedButton(Page.GET_STARTED, 'Copy API key', {
      Location: Location.Center,
      Environment: environmentName,
    });
  };

  const trackClickedCopyPingCurl = () => {
    mixpanelService.trackClickedButton(Page.GET_STARTED, 'Copy ping curl', {
      Location: Location.Center,
      Environment: environmentName,
    });
  };

  const step1 = () => {
    return (
      <div className={classes.stepOne}>
        <BodyBlack80 className={classes.textRow} data-test="getting-started-api-key">
          API key: <b>{apiKey}</b> &nbsp;{' '}
          {apiKey && (
            <CopyButton
              contentToCopy={apiKey}
              successMessage={`Copied the "${environment.maybeAPIKey?.keyInfo.name}" key to clipboard.`}
              afterCopy={() => trackClickedCopyAPIKey()}
            />
          )}
        </BodyBlack80>
        <BodyBlack80 className={classes.textRow}>Add this header to all HTTP requests:</BodyBlack80>
        <Highlight>
          Authorization: Bearer {apiKey}
          <span className={classes.spacer}> </span>
        </Highlight>
        <i>
          This key is scoped to market {environment.maybeAPIKey?.keyInfo.market}. To access other markets, visit{' '}
          <a href={`${environmentName}/authentication`}>Authentication</a>
        </i>
      </div>
    );
  };

  const step2 = () => {
    return (
      <div>
        <BodyBlack80>Ping your environment to verify connection:</BodyBlack80>
        <div>
          <Highlight>
            <span className={classes.codeContent}>
              <pre className={classes.code}> {copyCode}</pre>
              <span>
                <CopyButton
                  contentToCopy={copyCode}
                  successMessage={`Copied the curl request to the clipboard.`}
                  afterCopy={() => trackClickedCopyPingCurl()}
                />
              </span>
            </span>
          </Highlight>
        </div>
      </div>
    );
  };
  return (
    <div data-test="get-started-with-v3-card">
      <IntroCardTemplate headerText="To quickly get started with this environment, use a generated API key">
        <div className={classes.listContainer}>
          <NumberedList listItems={[step1(), step2()]} />
        </div>
      </IntroCardTemplate>
    </div>
  );
};
