import { IntegrationPortalCancellationSearchResult } from '@minna-technologies/integration-portal-types/tech/minna/core/api/integrationportal';
import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../style/colors';
import { format } from 'date-fns';

interface CancellationsListComponentProps {
  cancellations: IntegrationPortalCancellationSearchResult[];

  onCancellationClicked(cancellation: IntegrationPortalCancellationSearchResult): void;
}

const useStyles = makeStyles(() => ({
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },
  table: {
    width: '100%',
  },
  headerText: {
    fontWeight: 'bold',
  },
  oddLine: {
    cursor: 'pointer',
    backgroundColor: colors.background,
    paddingLeft: '16px',
    paddingRight: '24px',
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
  },
  evenLine: {
    paddingLeft: '16px',
    paddingRight: '24px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
  },
}));

export const CancellationsListComponent = (props: CancellationsListComponentProps) => {
  const classes = useStyles();

  const headerColumn = (text: string) => (
    <TableCell key={text}>
      <Subheading2 style={{ fontWeight: 'bold' }}>{text}</Subheading2>
    </TableCell>
  );

  const onCancellationClicked = (cancellation: IntegrationPortalCancellationSearchResult) => {
    props.onCancellationClicked(cancellation);
  };

  return (
    <Card className={classes.container}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {['Cancellation ID', 'User ID', 'Merchant', 'Namespace', 'Status', 'Last updated', ' '].map(headerColumn)}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.cancellations.map((cancellation, index) => {
            const date = cancellation.stateLastChangedAt ? cancellation.stateLastChangedAt : cancellation.createdAt;
            const parsedDate: Date = new Date(Date.parse(date));
            return (
              <TableRow
                key={cancellation.cancellationId}
                className={index % 2 === 0 ? classes.oddLine : classes.evenLine}
                onClick={() => onCancellationClicked(cancellation)}
              >
                <TableCell data-test={'cell-cancellation-id'}>{cancellation.cancellationId}</TableCell>
                <TableCell data-test={'cell-user-id'}>{cancellation.userId}</TableCell>
                <TableCell data-test={'cell-merchant'}>{cancellation.serviceProviderName}</TableCell>
                <TableCell data-test={'cell-branchName'}>{cancellation.branchName || 'N/A'}</TableCell>
                <TableCell data-test={'cell-status'}>{cancellation.status.detailedCancellationStatus}</TableCell>
                <TableCell data-test={'cell-state-last-changed'}>{format(parsedDate, 'yyyy-MM-dd hh:mm:ss')}</TableCell>
                <TableCell>
                  {' '}
                  <ChevronRightIcon nativeColor={colors.primary} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
};
