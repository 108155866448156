import React from 'react';
import { colors } from '../../style/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { IconButton } from '@minna-technologies/minna-ui/components/Buttons/IconButton';
import { CloseIcon } from '@minna-technologies/minna-ui/icons/Close';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { StarPictogram } from '@minna-technologies/minna-ui/pictograms/Star';

interface ThankUserProps {
  onClose(): void;
}
const useStyles = makeStyles(() => ({
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pictogramHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  pictogram: {
    marginRight: '16px',
  },
}));

export const ThankUserComponent = (props: ThankUserProps) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.headerRow}>
        <div className={classes.pictogramHeader}>
          <StarPictogram className={classes.pictogram} />
          <div>
            <Headline3 color={colors.primary}>Your feedback was sent!</Headline3>
            <Body style={{ marginTop: '8px' }}>Thank you for sharing, it helps us improve.</Body>
          </div>
          <br />
        </div>
        <IconButton onClick={() => props.onClose()}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};
