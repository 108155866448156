import React, { useEffect } from 'react';
import { ConsoleViewSwitch, LegacyApiVersion, V3APIVersion } from '../navigation/consoleviews/ConsoleViewSwitch';
import { ClientKeysPage } from './ClientKeysPage';
import { APIKeysPage } from './APIKeysPage';
import { EnvironmentIcon } from '@minna-technologies/minna-ui/icons/Environment';
import { colors } from '../style/colors';
import { Page } from '../../services/mixpanel/mixpanelEvents';
import { PageHeader } from '../components/PageHeader';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../store/ducks/user';
import { useEnvironmentName } from '../components/EnvironmentNameHook';
import { PageNotFound } from '../navigation/error/PageNotFound';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import { mixpanelService } from '../../services/mixpanel/mixpanelService';

export const AuthenticationPage = () => {
  const environmentName = useEnvironmentName();
  const userEnvironments = useSelector(userSelectors.userEnvironments);
  const viewVersion = useSelector(userSelectors.currentConsoleViewOption);
  const currentEnvironment = userEnvironments?.find(
    (environment) => environment.environmentName.toLowerCase() == environmentName.toLowerCase()
  );

  useEffect(() => {
    if (currentEnvironment) {
      mixpanelService.trackViewedPage(Page.AUTHENTICATION, {
        Environment: currentEnvironment.environmentName,
        Version: viewVersion,
      });
    }
  }, [currentEnvironment]);

  if (currentEnvironment === undefined) {
    return <PageNotFound />;
  } else {
    return (
      <div>
        <PageHeader
          title={'Authentication'}
          subTitle={capitalizeFirstLetter(environmentName)}
          icon={<EnvironmentIcon nativeColor={colors.primary} />}
          environmentUrl={currentEnvironment.integrationApiUrl}
          currentPage={Page.AUTHENTICATION}
        />
        <ConsoleViewSwitch>
          <LegacyApiVersion>
            <ClientKeysPage currentEnvironment={currentEnvironment} />
          </LegacyApiVersion>
          <V3APIVersion>
            <APIKeysPage currentEnvironment={currentEnvironment} />
          </V3APIVersion>
        </ConsoleViewSwitch>
      </div>
    );
  }
};
