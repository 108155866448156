import { PositiveButton } from '@minna-technologies/minna-ui/components/Buttons/PositiveButton';
import { WhiteSecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/WhiteSecondaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import React from 'react';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { IllustrationProps } from '@minna-technologies/minna-ui/illustrations';

interface ErrorComponentProps {
  headerText: string;
  bodyText: string;
  recoverActionText?: string;
  escapeActionText?: string;
  illustration: React.ReactElement<IllustrationProps>;
  onRecoverClick?(): void;
  onEscapeClick?(): void;
}

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../style/colors';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100vh',
    background: colors.primaryGradient,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    margin: '18px 8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardContainer: {
    width: '500px',
    textAlign: 'center',
    marginLeft: '8px',
    marginRight: '8px',
    marginBottom: '39px',
  },
  cardContent: { margin: '16px 16px 24px 16px' },
  headerRow: { marginTop: '32px', marginBottom: '16px' },
  row: { marginBottom: '32px' },
  image: { userSelect: 'none' },
}));

export const ErrorComponent: React.FC<ErrorComponentProps> = ({
  headerText,
  bodyText,
  recoverActionText,
  escapeActionText,
  illustration,
  onEscapeClick,
  onRecoverClick,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Card className={classes.cardContainer}>
          <div className={classes.cardContent}>
            {illustration}
            <div className={classes.headerRow}>
              <Title data-test="error-component-title">{headerText}</Title>
            </div>

            <div className={classes.row}>
              <Body>{bodyText}</Body>
            </div>

            {onRecoverClick && (
              <div className={classes.row}>
                <PositiveButton
                  label={recoverActionText}
                  onClick={onRecoverClick}
                  data-test="error-component-recover-button"
                />
              </div>
            )}
          </div>
        </Card>
      </div>

      {onEscapeClick && <WhiteSecondaryButton label={escapeActionText} onClick={onEscapeClick} />}
    </div>
  );
};
