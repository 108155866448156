import { Card } from '@minna-technologies/minna-ui/components/Card';
import React from 'react';
import { CardHeader } from '@minna-technologies/minna-ui/components/Card/CardHeader';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { IntegrationPortalCancellationDetails } from '@minna-technologies/integration-portal-types/tech/minna/core/api/integrationportal';
import { colors } from '../../style/colors';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { format } from 'date-fns';

interface CancellationDetailsProps {
  cancellationDetails: IntegrationPortalCancellationDetails;
}

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '32px',
  },
  card: {
    marginBottom: '16px',
  },
}));

function formatDateString(date: string | undefined): string {
  if (date) {
    const parsedDate = new Date(Date.parse(date));
    return format(parsedDate, 'yyyy-MM-dd HH:mm:ss');
  } else {
    return '-';
  }
}

export const CancellationDetails = (props: CancellationDetailsProps) => {
  const classes = useStyles();
  // The type CategoryName and what is actually returned from the API differs. Casting it to any so that we can access `.name` without compile issues.
  const categoryName = props.cancellationDetails.serviceProvider.categoryName as any;
  const createdAtDate = formatDateString(props.cancellationDetails.createdAt);
  const lastUpdateDate = formatDateString(props.cancellationDetails.stateLastChangedAt);
  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <CardHeader title="User info" />
        <CardInformationField title={'User id:'} value={props.cancellationDetails.userId} />
        <CardInformationField title={'Branch:'} value={get(props.cancellationDetails.platform, 'branch', 'N/A')} />
      </Card>
      <Card className={classes.card}>
        <CardHeader title="Cancellation info" />
        <CardInformationField title={'Cancellation ID:'} value={props.cancellationDetails.cancellationId} />
        <CardInformationField title={'Created:'} value={createdAtDate} />
        <CardInformationField title={'Last updated:'} value={lastUpdateDate} />
        <CardInformationField title={'Merchant:'} value={props.cancellationDetails.serviceProvider.name} />
        <CardInformationField title={'Category name:'} value={categoryName.name} />
        <CardInformationField title={'Status:'} value={props.cancellationDetails.status.detailedCancellationStatus} />
      </Card>
    </div>
  );
};

const CardInformationField = ({ title, value }: { title: string; value: any }) => {
  return (
    <div style={{ marginBottom: '16px' }}>
      <Caption data-test={`caption-${title}`} color={colors.black80}>
        {title}
      </Caption>
      <Body data-test={`value-${title}`}>{value}</Body>
    </div>
  );
};
