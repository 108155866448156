import * as Sentry from '@sentry/react';
import { isProduction, isTestEnvironment } from '../services/utils/Environment';
import { merge } from 'lodash';
import get from 'lodash/get';

function getDsn() {
  if (isProduction()) {
    return 'https://aa18123c51cc4d679ae5d60dcfd0548b@o46236.ingest.sentry.io/6299522';
  }

  return 'https://48e58f25f5a84cf081b0d0db73c54890@o46236.ingest.sentry.io/6299668';
}

const isSentryEnabled = isProduction() || isTestEnvironment();
if (!isSentryEnabled) {
  // eslint-disable-next-line no-console
  console.log('Sentry is disabled. Logging messages to console.');
}

export function initSentry() {
  Sentry.init({
    dsn: getDsn(),
    beforeSend: (event): Sentry.Event | null => {
      if (isSentryEnabled) {
        return event;
      } else {
        const maybeException: Sentry.Exception | undefined = get(event, 'exception.values[0]');
        if (maybeException) {
          // eslint-disable-next-line no-console
          console.warn(
            'Sentry event: %s\n Stacktrace: %o, Entire Sentry event: %o',
            event.message || maybeException.value,
            maybeException.stacktrace,
            event
          );
        } else {
          // eslint-disable-next-line no-console
          console.warn('Sentry event: %s\n Entire Sentry event %o', event.message, event);
        }
        // Returning null will drop the message from getting further.
        return null;
      }
    },
  });
}

const client = {
  ...Sentry,
  captureException(error: any, event?: any): void {
    Sentry.withScope((scope) => {
      scope.addEventProcessor(async (event2) => merge(event2, event));
      Sentry.captureException(error);
    });
  },
  captureMessage(message: string, event?: any, level?: Sentry.Severity): void {
    Sentry.withScope((scope) => {
      scope.addEventProcessor(async (event2) => merge(event2, event));
      Sentry.captureMessage(message, level ?? Sentry.Severity.Error);
    });
  },
  captureExceptionWithMessage(error: any, message: string, event: any = {}): void {
    Sentry.withScope((scope) => {
      scope.addEventProcessor(async (event2) => {
        event2.message = message;
        // Group events into one sentry issue if they have the same message
        event.fingerprint = ['{{ default }}', message];

        return merge(event2, event);
      });
      Sentry.captureException(error);
    });
  },
  setGlobalTag(key: string, value: string) {
    Sentry.configureScope((scope) => {
      scope.setTag(key, value);
    });
  },
};

export { client as Sentry };
