import { AppDispatch, RootState } from '../setup';
import * as UserApi from '@minna-technologies/integration-portal-v1/UserApi';
import { ClientUserContext } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/user';
import { loginActions } from './login';
import { FeatureToggles } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/config';
import { ClientEnvironment } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { Sentry } from '../../utils/sentry';

// When updating this, also update the 'SupportedVersions` in integration-tests/utils/selectors.ts
export enum ConsoleViewOption {
  API_VERSION_3 = 'API_VERSION_3',
  API_VERSION_LEGACY = 'API_VERSION_LEGACY',
}

const StoredConsoleViewOptionName = 'ConsoleViewOption';

interface UserState {
  userContext: ClientUserContext | null;
  isLoadingUserContext: boolean | undefined;
  selectedViewOption: ConsoleViewOption;
}

const initialState: UserState = {
  userContext: null,
  isLoadingUserContext: undefined,
  selectedViewOption:
    (localStorage.getItem(StoredConsoleViewOptionName) as ConsoleViewOption) ?? ConsoleViewOption.API_VERSION_LEGACY, // Legacy for now since we don't have anything in Latest.
};

export const userSelectors = {
  userEnvironments: (state: RootState): ClientEnvironment[] | null => state.user.userContext?.environments,
  userContext: (state: RootState) => state.user?.userContext,
  user: (state: RootState) => state.user?.userContext?.user,
  isLoadingUserContext: (state: RootState) => state.user.isLoadingUserContext,
  featureToggles: (state: RootState): FeatureToggles => state.user?.userContext?.featureToggles,
  currentConsoleViewOption: (state: RootState): ConsoleViewOption => state.user.selectedViewOption,
};

export const userActions = {
  fetchUserContext,
  agreeToTerms,
  setConsoleViewOption,
};

export const actionTypes = {
  SET_USER_CONTEXT: 'SET_USER_CONTEXT',
  SET_IS_LOADING_USER_CONTEXT: 'SET_IS_LOADING_USER_CONTEXT',
  SET_CONSOLE_VIEW_OPTION: 'SET_CONSOLE_VIEW_OPTION',
};

const userEvents = {
  setUserContext: (userContext: ClientUserContext) => ({ type: actionTypes.SET_USER_CONTEXT, userContext }),
  setIsLoadingUserContext: (isLoading: boolean) => ({ type: actionTypes.SET_IS_LOADING_USER_CONTEXT, isLoading }),
  setConsoleViewOption: (consoleViewOption: ConsoleViewOption) => ({
    type: actionTypes.SET_CONSOLE_VIEW_OPTION,
    consoleViewOption,
  }),
};

export const userReducer = (state: RootState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_USER_CONTEXT:
      return {
        ...state,
        userContext: action.userContext,
      };
    case actionTypes.SET_IS_LOADING_USER_CONTEXT:
      return {
        ...state,
        isLoadingUserContext: action.isLoading,
      };
    case actionTypes.SET_CONSOLE_VIEW_OPTION:
      return {
        ...state,
        selectedViewOption: action.consoleViewOption,
      };
    default:
      return state;
  }
};

// ACTIONS
function fetchUserContext() {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(userEvents.setIsLoadingUserContext(true));
    try {
      const userContext = await UserApi.getUserContext();
      if (userContext) {
        // If this request is successful, then there is a valid session cookie so we should set isLoggedIn to true.
        dispatch(loginActions.isAlreadyLoggedIn());
        Sentry.setGlobalTag('platformName', userContext.user.platformName);
        Sentry.setGlobalTag('userId', userContext.user.id);
        Sentry.setGlobalTag('environments', userContext.environments.map((e) => e.environmentName).join(', '));
      }
      dispatch(userEvents.setUserContext(userContext));
      dispatch(userEvents.setIsLoadingUserContext(false));
    } catch (err) {
      dispatch(userEvents.setIsLoadingUserContext(false));
      if (getState().login.isLoggedIn) {
        Sentry.captureExceptionWithMessage(err, 'Failed to fetch user context when user was logged in.');
      }
    }
  };
}

function agreeToTerms() {
  return async (dispatch: AppDispatch) => {
    await UserApi.acceptTermsAndConditions();

    // Fetching user context again to get updated terms data.
    dispatch(userActions.fetchUserContext());
  };
}

function setConsoleViewOption(consoleViewOption: ConsoleViewOption) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().user.selectedViewOption !== consoleViewOption) {
      localStorage.setItem(StoredConsoleViewOptionName, consoleViewOption);
      dispatch(userEvents.setConsoleViewOption(consoleViewOption));
    }
  };
}
