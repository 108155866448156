import {
  CancellationStatus,
  CancellationStatusType,
} from '@minna-technologies/integration-portal-types/tech/minna/core/shared/cancellations/V2';
import React, { useState } from 'react';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { Radio } from '@minna-technologies/minna-ui/components/Inputs/Radio';
import { TextArea } from '@minna-technologies/minna-ui/components/Inputs/TextArea';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { Tab, Tabs } from '../../components/Tabs';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import {
  rejectedStatusesWithDescriptions,
  StatusOption,
  successfullStatusesWithDescriptions,
} from '../CancellationStatuses';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../style/colors';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { ClickedButtonAdditionalProps, mixpanelService } from '../../../services/mixpanel/mixpanelService';
import { Location, Page } from '../../../services/mixpanel/mixpanelEvents';

interface EditCancellationStatus {
  onStatusChange(status: CancellationStatusType, emailText: string): void;

  cancellationStatus: CancellationStatus | null;

  mixpanelPageProps: ClickedButtonAdditionalProps;
}

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: '32px',
  },
  tabsContainer: {
    paddingTop: '24px',
  },
  divider: {
    marginTop: '24px',
    marginBottom: '24px',
  },
  radio: {
    marginTop: '8px',
    marginBottom: '8px',
  },
  infoText: {
    color: colors.black80,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const EditCancellationStatusComponent: React.FunctionComponent<EditCancellationStatus> = (props) => {
  const [currentSelectedStatus, setSelectedStatus] = useState(successfullStatusesWithDescriptions[0]);
  const [serviceProviderEmailText, setServiceProviderEmailText] = useState('');
  const formMethods = useMinnaForm<{ status: StatusOption; emailText?: string }>();

  const isDisabled =
    !!props.cancellationStatus &&
    props.cancellationStatus.detailedCancellationStatus !== CancellationStatusType.Ongoing;

  function updateStatus(status: StatusOption) {
    if (!isDisabled) {
      mixpanelService.trackClickedButton(Page.CANCELLATION_DETAILS, 'Set cancellation state', {
        ...props.mixpanelPageProps,
        Location: Location.Center,
        'Cancellation state': status.status,
      });
      setSelectedStatus(status);
    }
  }

  function updateCancellation() {
    mixpanelService.trackClickedButton(Page.CANCELLATION_DETAILS, 'Update cancellation status', {
      ...props.mixpanelPageProps,
      Location: Location.Center,
      'Cancellation state': currentSelectedStatus.status,
    });
    props.onStatusChange(currentSelectedStatus.status, serviceProviderEmailText);
  }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Title>Update cancellation status</Title>
      <div className={classes.tabsContainer}>
        <Tabs maxWidthPercentage={50}>
          <Tab title="Successful statuses">{renderOptions(successfullStatusesWithDescriptions)}</Tab>
          <Tab title="Rejected statuses">{renderOptions(rejectedStatusesWithDescriptions)}</Tab>
        </Tabs>
      </div>
    </div>
  );

  function renderOptions(statuses: StatusOption[]) {
    return (
      <Card>
        <Form formMethods={formMethods} onSubmit={() => updateCancellation()}>
          {statuses.map((status) => {
            return (
              <div key={status.status}>
                <Radio
                  className={classes.radio}
                  checked={currentSelectedStatus && currentSelectedStatus.status === status.status}
                  value={status.status}
                  name={status.status}
                  label={status.title}
                  disabled={isDisabled}
                  onChange={() => updateStatus(status)}
                  helpText={status.description}
                  data-test="cancellation-status-radio"
                />
              </div>
            );
          })}
          <Divider className={classes.divider} />
          <div>
            <TextArea
              label="Merchant email response (Optional)"
              value={serviceProviderEmailText}
              disabled={isDisabled}
              onChange={(e) => setServiceProviderEmailText(e.target.value)}
              fullWidth
              data-test="merchant-email-response"
            />
            <Body className={classes.infoText}>
              This is the response that would come from a merchant, and will be forwarded to the consumer. For example,
              details about the cancellation date.
            </Body>
            <Divider className={classes.divider} />
            <div className={classes.buttonContainer}>
              <PrimaryButton label="Update status" type="submit" data-test="submit-button" disabled={isDisabled} />
            </div>
          </div>
        </Form>
      </Card>
    );
  }
};
