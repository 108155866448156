import React from 'react';

export const ExternalLink = ({
  link,
  children,
  onClick,
}: {
  link: string;
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <a href={link} target="_blank" rel="noreferrer" onClick={onClick}>
      {children}
    </a>
  );
};
