import React, { useState } from 'react';
import { colors } from '../../style/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { IconButton } from '@minna-technologies/minna-ui/components/Buttons/IconButton';
import { CloseIcon } from '@minna-technologies/minna-ui/icons/Close';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { StarRating } from '@minna-technologies/minna-ui/components/StarRating';

interface StarRatingProps {
  questionText: string;
  bodyText: string;
  onRatingGiven(currentRating: number): void;
  onClose(): void;
}
const useStyles = makeStyles(() => ({
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  starContainer: {
    marginTop: '16px',
    marginBottom: '30px',
  },
}));

export const StarRatingComponent = (props: StarRatingProps) => {
  const classes = useStyles();
  const [currentRating, setCurrentRating] = useState(0);

  const provideRating = () => {
    props.onRatingGiven(currentRating);
  };

  return (
    <div>
      <div className={classes.headerRow}>
        <Headline3 color={colors.primary}>{props.questionText}</Headline3>
        <IconButton onClick={() => props.onClose()}>
          <CloseIcon />
        </IconButton>
      </div>
      <Body>{props.bodyText}</Body>
      <div className={classes.starContainer}>
        <StarRating currentRating={currentRating} onRatingChange={(rating) => setCurrentRating(rating)} maxRating={5} />
      </div>
      <SecondaryButton label="Submit" onClick={() => provideRating()} />
    </div>
  );
};
