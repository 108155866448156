import React, { useState } from 'react';
import { BlueBasePage } from '../../components/templates/BlueBasePage';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { BodyBlack80 } from '../../components/text/BodyBlack80';
import { ExternalLink } from '../../components/ExternalLink';
import * as urls from '../../navigation/urls';

import { makeStyles } from '@material-ui/core/styles';
import { userActions } from '../../../store/ducks/user';
import { useDispatch } from 'react-redux';
import { Checkbox } from '@minna-technologies/minna-ui/components/Inputs/Checkbox';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';

const useStyles = makeStyles(() => ({
  card: {
    maxWidth: '296px',
  },
  bodyText: {
    marginTop: '10px',
  },
  checkbox: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  welcomeHeadline: {
    marginBottom: '15px',
    color: 'white',
  },
}));

export const TermsAndConditionAgreementPage = () => {
  const dispatch = useDispatch();
  const [tcState, setTcState] = useState<boolean>(false);

  const onContinueClicked = () => {
    dispatch(userActions.agreeToTerms());
  };

  const checkTcCheckbox = () => {
    setTcState(!tcState);
  };

  const classes = useStyles();
  return (
    <BlueBasePage>
      <div className={classes.container}>
        <Headline1 color={'white'} className={classes.welcomeHeadline}>
          Welcome to the Minna Console
        </Headline1>
        <Card className={classes.card}>
          <div>
            <Headline3>Before proceeding</Headline3>
            <BodyBlack80 className={classes.bodyText}>
              By signing up your account is subject to the terms stated in the{' '}
              <ExternalLink link={urls.userAgreement}>user agreement</ExternalLink>.
              <Checkbox
                className={classes.checkbox}
                name="TermsAndConditions"
                checked={tcState}
                onChange={checkTcCheckbox}
                data-test="terms-and-conditions-checkbox"
                value={tcState}
                label="I accept the user agreement"
              />
              <PrimaryButton
                label="Continue"
                disabled={!tcState}
                onClick={onContinueClicked}
                fullWidth
                data-test="terms-and-conditions-continue"
              />
            </BodyBlack80>
          </div>
        </Card>
      </div>
    </BlueBasePage>
  );
};
