import React, { ComponentType } from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../style/colors';
import { IconProps } from '@minna-technologies/minna-ui/icons';
import { mixpanelService } from '../../../services/mixpanel/mixpanelService';
import { Location, Page } from '../../../services/mixpanel/mixpanelEvents';
import { ConsoleViewOption } from '../../../store/ducks/user';

export interface SimpleMenuItem {
  title: string;
  icon: ComponentType<IconProps>;
  linkToExternal?: boolean;
  path: string;
  mixpanelProps?: {
    Button: string;
    Environment?: string;
    Version?: ConsoleViewOption;
  };
}

const createStyles = makeStyles(() => ({
  activeListItem: {
    fontWeight: 'bold',
    backgroundColor: colors.primary + '!important',
    color: colors.white,
  },
  listItemSelectedButton: {
    minWidth: '1px',
    '&:hover': {
      backgroundColor: colors.primaryHover + '!important',
      color: colors.black80,
    },
  },
  listItemIcon: {
    width: 24 + 16 + 'px',
    minWidth: 24 + 16 + 'px',
  },
  icon: {
    color: colors.grey1,
    ':hover': {
      color: colors.grey1,
    },
    ':selected': {
      color: colors.white,
    },
  },
}));

export const IconMenuItem = ({
  item,
  currentPath,
  currentPage,
}: {
  item: SimpleMenuItem;
  currentPath: string;
  currentPage: Page;
}) => {
  const to = item.linkToExternal ? { pathname: item.path } : item.path;

  const trackMixpanelEvent = () => {
    if (item.mixpanelProps) {
      mixpanelService.trackClickedButton(currentPage, item.mixpanelProps.Button, {
        Location: Location.Menu,
        ...item.mixpanelProps,
      });
    }
  };

  // This is to avoid a warning in the console where a ref is missing when using a Link as a ListItem component.
  const LinkWithTo: any = React.forwardRef((props: unknown, ref: React.Ref<any>) => (
    <Link ref={ref} {...props} to={to} onClick={trackMixpanelEvent} />
  ));
  LinkWithTo.displayName = 'LinkWithTo';

  const isSelected = currentPath === item.path;
  const classes = createStyles();
  const Icon = item.icon;
  return (
    <ListItem
      selected={isSelected}
      classes={{ selected: classes.activeListItem, button: classes.listItemSelectedButton }}
      disableRipple
      button
      component={LinkWithTo}
      target={item.linkToExternal ? '_blank' : ''}
      data-test={'icon-menu-item'}
    >
      <ListItemIcon classes={{ root: classes.listItemIcon }}>
        <Icon nativeColor={isSelected ? colors.white : colors.grey1} />
      </ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
};
