import React from 'react';
import { IconProps, SvgIcon } from '@minna-technologies/minna-ui/icons';

export const CodeIcon: React.ComponentType<IconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_309_2959)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.15937 3.96971C8.45224 4.26263 8.4522 4.7375 8.15929 5.03037L1.71941 11.4693C1.71937 11.4693 1.71945 11.4692 1.71941 11.4693C1.57888 11.6099 1.49982 11.8007 1.49982 11.9995C1.49982 12.1983 1.57877 12.389 1.71933 12.5297C1.71935 12.5297 1.7193 12.5296 1.71933 12.5297L8.15933 18.9697C8.45222 19.2626 8.45222 19.7374 8.15933 20.0303C7.86643 20.3232 7.39156 20.3232 7.09867 20.0303L0.658667 13.5903C0.236857 13.1684 -0.000183105 12.5961 -0.000183105 11.9995C-0.000183105 11.4029 0.236777 10.8307 0.658586 10.4088L7.09871 3.96963C7.39162 3.67676 7.8665 3.6768 8.15937 3.96971ZM15.8406 3.96971C16.1335 3.6768 16.6084 3.67676 16.9013 3.96963L23.3413 10.4086C23.7631 10.8306 24.0002 11.4029 24.0002 11.9995C24.0002 12.5961 23.7632 13.1683 23.3414 13.5903L16.9013 20.0303C16.6084 20.3232 16.1336 20.3232 15.8407 20.0303C15.5478 19.7374 15.5478 19.2626 15.8407 18.9697L22.2806 12.5298C22.2806 12.5297 22.2806 12.5298 22.2806 12.5298C22.4211 12.3891 22.5002 12.1983 22.5002 11.9995C22.5002 11.8006 22.4212 11.6099 22.2806 11.4693L15.8407 5.03037C15.5478 4.7375 15.5478 4.26263 15.8406 3.96971Z"
          fill="#4A4B4B"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_2959">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
