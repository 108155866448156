import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as urls from '../../navigation/urls';
import { VerifySignupPage } from './VerifySignupPage';
import { SelectPasswordPage } from './SelectPasswordPage';
import { SignupCompletedPage } from './SignupCompletedPage';

export const SignupRouter: React.FunctionComponent = () => (
  <Switch>
    <Route path={urls.completeSignupPage} exact component={VerifySignupPage} />
    <Route path={urls.selectPasswordPage} exact component={SelectPasswordPage} />
    <Route path={urls.signupCompletedPage} exact component={SignupCompletedPage} />
  </Switch>
);
