import React from 'react';
import { ErrorComponent } from './ErrorComponent';
import { PhoneIllustration } from '@minna-technologies/minna-ui/illustrations/Phone';
import { useHistory } from 'react-router-dom';
import { loginPage } from '../urls';

export const SessionTimeoutError = () => {
  const history = useHistory();
  return (
    <ErrorComponent
      headerText={'Your session has expired'}
      bodyText={'Please log in again.'}
      recoverActionText={'Go to login'}
      illustration={<PhoneIllustration />}
      onRecoverClick={() => history.push(loginPage)}
    />
  );
};
