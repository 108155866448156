import firebase from 'firebase/compat/app';
// eslint-disable-next-line
import 'firebase/compat/auth';
import get from 'lodash/get';
import { isProduction } from '../utils/Environment';

const firebaseConfig = isProduction()
  ? {
      // Needed for Firebase
      // nosemgrep: generic.secrets.security.detected-generic-api-key.detected-generic-api-key
      apiKey: 'AIzaSyAVVrbapGN6qjxuwjjTXCPOmUjfmnqosWA',
      authDomain: 'minna-integration-portal.firebaseapp.com',
      databaseURL: 'https://minna-integration-portal.firebaseio.com',
      projectId: 'minna-integration-portal',
      storageBucket: 'minna-integration-portal.appspot.com',
      messagingSenderId: '150068929206',
      appId: '1:150068929206:web:0784530ab808e291edbc17',
    }
  : {
      // Needed for Firebase
      // nosemgrep: generic.secrets.security.detected-generic-api-key.detected-generic-api-key
      apiKey: 'AIzaSyBjEAxR7SeC7_IkLX-8lFskmtnAWopfwPs',
      authDomain: 'minna-integration-portal-dev.firebaseapp.com',
      databaseURL: 'https://minna-integration-portal-dev.firebaseio.com',
      projectId: 'minna-integration-portal-dev',
      storageBucket: 'minna-integration-portal-dev.appspot.com',
      messagingSenderId: '506151023908',
      appId: '1:506151023908:web:c2d31f7771f630c9f1efb1',
    };

const app: firebase.app.App = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();

export const signInWithEmailAndPassword = async (
  email: string,
  password: string
): Promise<firebase.auth.UserCredential> => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const isSignInWithEmailLink = (): boolean => auth.isSignInWithEmailLink(window.location.href);
export const signInWithEmailLink = async (email: string) => auth.signInWithEmailLink(email, window.location.href);
export const isUserAuthenticated = (): boolean => !!auth.currentUser;
export const updatePassword: (password: string) => Promise<any> = async (password: string) =>
  auth.currentUser?.updatePassword(password);
export const signOut = async () => auth.signOut();
export const sendPasswordResetEmail = async (email: string, url: string) => auth.sendPasswordResetEmail(email, { url });
export const verifyPasswordResetCode = async (actionCode: string) => auth.verifyPasswordResetCode(actionCode);
export const confirmPasswordReset: (actionCode: string, newPassword: string) => Promise<any> = async (
  actionCode: string,
  newPassword: string
) => auth.confirmPasswordReset(actionCode, newPassword);

export const getErrorReasonFromFirebaseError = (error: Error, fallback: string) => {
  const maybeErrorCode = get(error, 'code');
  const maybeErrorReason = get(error, 'message');
  if (maybeErrorCode && maybeErrorReason) {
    return maybeErrorReason as string;
  } else {
    // eslint-disable-next-line no-console
    console.log(error);
    return fallback;
  }
};
