import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { LoginPage } from './login/LoginPage/LoginPage';
import * as urls from './navigation/urls';
import { CancellationDetailsPage } from './cancellation/details/CancellationDetailsPage';
import { Dashboard } from './dashboard/Dashboard';
import { SignupRouter } from './login/SignupPage/SignupRouter';
import { CancellationsListPage } from './cancellation/list/CancellationsListPage';
import { RequestResetPasswordPage } from './login/ResetPassword/RequestResetPasswordPage';
import { RouteWithMenu } from './navigation/RouteWithMenu';
import { useDispatch, useSelector } from 'react-redux';
import { userActions, userSelectors } from '../store/ducks/user';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { WorkInProgressIllustration } from '@minna-technologies/minna-ui/illustrations/WorkInProgress';
import { loginSelectors } from '../store/ducks/login';
import { UnexpectedError } from './navigation/error/UnexpectedError';
import { PageNotFound } from './navigation/error/PageNotFound';
import { ErrorHandlerComponent } from './error/ErrorHandlerComponent';
import { RequireLoggedIn } from './login/AuthenticationRequired/RequireLoggedIn';
import { trackingActions } from '../store/ducks/tracking';
import { FirebaseEmailHandlingPage } from './login/FirebaseEmailHandling/FirebaseEmailHandlingPage';
import { ResetPasswordCompletedPage } from './login/ResetPassword/ResetPasswordCompletedPage';
import { GetStartedPage } from './getstarted/GetStartedPage';
import { initMixpanelService } from '../services/mixpanel/mixpanelService';
import { Page } from '../services/mixpanel/mixpanelEvents';
import { AuthenticationPage } from './authentication/AuthenticationPage';

export const AppRouter = () => {
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const userContext = useSelector(userSelectors.userContext);
  const featureToggles = useSelector(userSelectors.featureToggles);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userActions.fetchUserContext());
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && userContext.user) {
      dispatch(trackingActions.fetchCsatInteractionsHistory());
      initMixpanelService(userContext.user, featureToggles).then(); // ignore floating promise
    }
  }, [userContext, featureToggles]);

  return (
    <Router>
      <ErrorHandlerComponent>
        <Switch>
          <Redirect exact from={urls.landingPage} to={urls.loginPage} />
          <Route path={urls.loginPage} exact>
            {isLoggedIn ? <Redirect to={urls.dashboard} /> : <LoginPage />}
          </Route>
          <Route path={urls.requestResetPasswordPage} exact component={RequestResetPasswordPage} />
          <Route path={urls.resetPasswordCompletedPage} exact component={ResetPasswordCompletedPage} />
          <Route path={urls.emailHandlingPage} exact component={FirebaseEmailHandlingPage} />
          <Route path={urls.completeSignupPage} component={SignupRouter} />
          <Route
            path={urls.jiraServiceDesk}
            exact
            render={(routerProps) => {
              // To ease linking to our external service desk we define a route that, when hit, will redirect the user
              // in a new tab. We then do history.goBack() to not leave the users console on a white screen.
              window.open(urls.jiraServiceDeskExternalLink, '_blank');
              routerProps.history.goBack();
              return <div />;
            }}
          />
          <RequireLoggedIn>
            <RouteWithMenu path={urls.dashboard} exact component={Dashboard} page={Page.DASHBOARD} />
            <RouteWithMenu
              path={urls.cancellationDetailsPage(':cancellationId', ':environmentName')}
              exact
              component={CancellationDetailsPage}
              page={Page.CANCELLATION_DETAILS}
            />
            <RouteWithMenu
              path={urls.cancellationPage(':environmentName')}
              exact
              component={CancellationsListPage}
              page={Page.CANCELLATION_LIST}
            />
            <RouteWithMenu
              path={urls.authenticationPage(':environmentName')}
              exact
              component={AuthenticationPage}
              page={Page.AUTHENTICATION}
            />
            <RouteWithMenu
              path={urls.securityManagementPage(':environmentName')}
              exact
              component={PlaceholderPageComponent}
              page={Page.SECURITY}
            />
            <RouteWithMenu
              path={urls.getStartedPage(':environmentName')}
              exact
              component={GetStartedPage}
              page={Page.GET_STARTED}
            />
          </RequireLoggedIn>
          <Route path={urls.errorPage} exact component={UnexpectedError} />
          <Route component={PageNotFound} />
        </Switch>
      </ErrorHandlerComponent>
    </Router>
  );
};

// Remove this when not needed anymore
function PlaceholderPageComponent() {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '128px' }}>
      <div style={{ textAlign: 'center' }}>
        <Headline1>The best is yet to come!</Headline1>
        <br />
        <Title>We are currently working to expand the functionality of our Console.</Title>
        <Title>Look back here in a while to find new and useful features!</Title>
        <br />
        <br />
        <br />
        <WorkInProgressIllustration style={{ width: '300px', height: 'auto' }} />
      </div>
    </div>
  );
}
