import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancellationActions, cancellationSelectors } from '../../../store/ducks/cancellation';
import { CancellationsListComponent } from './CancellationsListComponent';
import { makeStyles } from '@material-ui/core/styles';
import {
  CancellationStatusSelector,
  IntegrationPortalCancellationSearchResult,
} from '@minna-technologies/integration-portal-types/tech/minna/core/api/integrationportal';
import { useHistory } from 'react-router-dom';
import { cancellationDetailsPage } from '../../navigation/urls';
import { CancellationSearchInput } from '../search/CancellationSearchInput';
import { Tab, Tabs } from '../../components/Tabs';
import { colors } from '../../style/colors';
import { useEnvironmentName } from '../../components/EnvironmentNameHook';
import { PageHeader } from '../../components/PageHeader';
import { EnvironmentIcon } from '@minna-technologies/minna-ui/icons/Environment';
import { userSelectors } from '../../../store/ducks/user';
import { PageNotFound } from '../../navigation/error/PageNotFound';
import { ClientEnvironment } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { Page } from '../../../services/mixpanel/mixpanelEvents';
import { mixpanelService } from '../../../services/mixpanel/mixpanelService';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '16px 16px 16px 0',
  },
  header: {
    marginBottom: '32px',
  },
  searchAndList: {
    marginTop: '55px',
  },
  searchInputContainer: {
    maxWidth: '50%',
  },
}));

export const CancellationsListPage = () => {
  const dispatch = useDispatch();
  const cancellations = useSelector(cancellationSelectors.cancellationsSearchResults);
  const history = useHistory();
  const [cancellationStatus, setCancellationStatus] = useState(CancellationStatusSelector.OngoingCancellations);
  const environmentName = useEnvironmentName();
  const userEnvironments = useSelector(userSelectors.userEnvironments);
  const currentEnvironment: ClientEnvironment | undefined = userEnvironments?.find(
    (environment) => environment.environmentName.toLowerCase() == environmentName.toLowerCase()
  );
  const classes = useStyles();
  useEffect(() => {
    refreshSearch(cancellationStatus);
  }, []);

  useEffect(() => {
    mixpanelService.trackViewedPage(Page.CANCELLATION_LIST, { Environment: environmentName });
  }, []);

  const goToCancellationDetails = (cancellation: IntegrationPortalCancellationSearchResult) => {
    mixpanelService.trackClickedButton(Page.CANCELLATION_LIST, 'Cancellation', { Environment: environmentName });
    history.push(cancellationDetailsPage(cancellation.cancellationId, environmentName));
  };

  const refreshSearch = (cancellationStatus: CancellationStatusSelector, maybeCancellationQuery?: string) => {
    setCancellationStatus(cancellationStatus);
    dispatch(cancellationActions.listCancellations(environmentName, cancellationStatus, maybeCancellationQuery));
  };

  const renderComponent = () => {
    return (
      <div className={classes.searchAndList}>
        <div className={classes.searchInputContainer}>
          <CancellationSearchInput
            onSearchTriggered={(cancellationQuery: string) => refreshSearch(cancellationStatus, cancellationQuery)}
          />
        </div>
        <CancellationsListComponent
          onCancellationClicked={(cancellation) => goToCancellationDetails(cancellation)}
          cancellations={cancellations}
        />
      </div>
    );
  };

  if (currentEnvironment === undefined) {
    return <PageNotFound />;
  } else {
    return (
      <div className={classes.container}>
        <PageHeader
          title={'Cancel testing'}
          subTitle={environmentName}
          icon={<EnvironmentIcon nativeColor={colors.primary} />}
          environmentUrl={currentEnvironment.integrationApiUrl}
          currentPage={Page.CANCELLATION_LIST}
        />
        <Tabs
          activeTabColor={colors.white}
          activeTabBackgroundColor={colors.primary}
          passiveTabBackgroundColor={colors.white}
          maxWidthPercentage={50}
        >
          <Tab
            title="Ongoing cancellations"
            onClick={() => refreshSearch(CancellationStatusSelector.OngoingCancellations)}
          >
            {renderComponent()}
          </Tab>
          <Tab
            title="Closed cancellations"
            onClick={() => refreshSearch(CancellationStatusSelector.ClosedCancellations)}
          >
            {renderComponent()}
          </Tab>
        </Tabs>
      </div>
    );
  }
};
