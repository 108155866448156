import React from 'react';
import { APIKeyProps } from '../../store/ducks/authentication';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
  body: {
    paddingBottom: '8px',
  },
}));

export const APIKeyCard = ({ apiKey }: { apiKey: APIKeyProps }) => {
  const classes = useStyles();
  return (
    <div>
      <Card data-test="api-key-card">
        <div>
          <Body className={classes.body} data-test="api-key-card-name">
            <b>Name: </b> {apiKey.name}
          </Body>
          <Body className={classes.body} data-test="api-key-card-market">
            <b>Market: </b> {apiKey.market}
          </Body>
          <Body className={classes.body} data-test="api-key-card-created-at">
            <b>Created at: </b>{' '}
            <span title={format(new Date(apiKey.createdAt), 'yyyy-MM-dd HH:mm:ss zzzz')}>
              {format(new Date(apiKey.createdAt), 'yyyy-MM-dd')}
            </span>
          </Body>
          <Body className={classes.body} data-test="api-key-card-prefix">
            <b>Key: </b> {apiKey.prefix}.******************
          </Body>
        </div>
      </Card>
    </div>
  );
};
