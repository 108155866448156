import React, { ReactElement } from 'react';
import { colors } from '../style/colors';
import { CheckCircleIcon } from '@minna-technologies/minna-ui/icons/CheckCircle';

import { makeStyles } from '@material-ui/core/styles';

interface CheckboxListProps {
  listItems: string[] | ReactElement[];
}

const useStyles = makeStyles(() => ({
  itemContainer: {
    display: 'flex',
    marginBottom: '16px',
    alignItems: 'center',
  },
  text: {
    marginLeft: '16px',
  },
}));

export const CheckboxList = (props: CheckboxListProps) => {
  const classes = useStyles();
  return (
    <div>
      {props.listItems.map((text: any, index: number) => (
        <div className={classes.itemContainer} key={index}>
          <CheckCircleIcon nativeColor={colors.primary} bold />
          <span className={classes.text}>{text}</span>
        </div>
      ))}
    </div>
  );
};
