import React from 'react';
import { RouteProps } from 'react-router-dom';
import { SessionTimeoutError } from '../../navigation/error/SessionTimeoutError';
import { loginSelectors } from '../../../store/ducks/login';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../store/ducks/user';
import { TermsAndConditionAgreementPage } from '../LoginPage/TermsAndConditionAgreementPage';

/**
 * All routes which requires authentication (e.g. all pages that also renders the menu), needs to be wrapped by this component to allow
 * the user to be redirected to the login page if unauthorized.
 */
export const RequireLoggedIn: React.StatelessComponent<RouteProps> = (props) => {
  const isLoggedIn = useSelector(loginSelectors.isLoggedIn);
  const isLoadingUserContext = useSelector(userSelectors.isLoadingUserContext);

  // We should only show the SessionTimeoutError-page if the user is not logged in and the isLoadingUserContext failed (aka is false)
  const userNotAuthenticated = !isLoggedIn && isLoadingUserContext === false;
  // User should accept terms on first login.
  const userHasAcceptedTerms = useSelector(userSelectors.user)?.acceptedCurrentTermsAndConditions;

  if (!userHasAcceptedTerms && isLoadingUserContext === false && !userNotAuthenticated) {
    return <TermsAndConditionAgreementPage />;
  } else {
    return <>{userNotAuthenticated ? <SessionTimeoutError /> : props.children}</>;
  }
};
