import React, { ReactElement } from 'react';
import { colors } from '../style/colors';
import { ExternalLink } from './ExternalLink';

export const ExternalLinkWithIcon = ({
  link,
  icon,
  text,
  onClick,
}: {
  link: string;
  icon: ReactElement;
  text: string;
  onClick?: () => void;
}) => {
  return (
    <ExternalLink link={link} onClick={onClick}>
      <span>
        {React.cloneElement(icon, {
          nativeColor: colors.primary,
          size: 16,
          style: { verticalAlign: 'bottom', paddingRight: '4px' },
        })}
        <span style={{ verticalAlign: 'bottom' }}>{text}</span>
      </span>
    </ExternalLink>
  );
};
