import React from 'react';
import { SkeletonCard } from '@minna-technologies/minna-ui/components/SkeletonCard';
import { Headline2 } from '@minna-technologies/minna-ui/components/Typography/Headline2';
import { Skeleton } from '@minna-technologies/minna-ui/components/Skeleton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';

export const SkeletonLoadingPage = () => {
  return (
    <div>
      <SkeletonCard>
        <Headline2>
          <Skeleton width="30%" />
        </Headline2>
        <Body>
          <Skeleton width="80%" />
        </Body>
        <Body>
          <Skeleton width="60%" />
        </Body>
      </SkeletonCard>
      <SkeletonCard opacity={0.7}>
        <Headline2>
          <Skeleton width="30%" />
        </Headline2>
        <Body>
          <Skeleton width="80%" />
        </Body>
        <Body>
          <Skeleton width="60%" />
        </Body>
      </SkeletonCard>
      <SkeletonCard opacity={0.4}>
        <Headline2>
          <Skeleton width="30%" />
        </Headline2>
        <Body>
          <Skeleton width="80%" />
        </Body>
        <Body>
          <Skeleton width="60%" />
        </Body>
      </SkeletonCard>
    </div>
  );
};
