import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { APIKeyProps, authenticationActions, authenticationSelectors } from '../../store/ducks/authentication';
import { ClientEnvironment } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { CreateApiKeyComponent } from './CreateApiKeyComponent';
import { ListApiKeysComponent } from './ListAPIKeysComponent';
import { makeStyles } from '@material-ui/core/styles';
import { ViewableApiKey } from './ViewableAPIKey';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
    padding: '16px 16px 16px 0px',
  },
  viewableAPIKeyContainer: {
    marginTop: '92px',
  },
}));

export const APIKeysPage = ({ currentEnvironment }: { currentEnvironment: ClientEnvironment }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const apiKeys: APIKeyProps[] = useSelector(authenticationSelectors.apiKeys);
  const temporaryPlainTextApiKey = useSelector(authenticationSelectors.temporaryPlainTextApiKey);
  const newKeyPrefix = temporaryPlainTextApiKey ? temporaryPlainTextApiKey.split('.')[0] : undefined;
  const maybeNewlyCreatedAPIKey = newKeyPrefix && apiKeys.find((key) => key.prefix === newKeyPrefix);

  useEffect(() => {
    dispatch(authenticationActions.listAPIKeys(currentEnvironment.environmentName));
  }, [currentEnvironment]);

  return (
    <div className={classes.container} data-test="API-keys-page">
      <div>
        <CreateApiKeyComponent environmentName={currentEnvironment.environmentName} />
        {temporaryPlainTextApiKey && maybeNewlyCreatedAPIKey ? (
          <div className={classes.viewableAPIKeyContainer}>
            <ViewableApiKey apiKey={maybeNewlyCreatedAPIKey} plainTextApiKey={temporaryPlainTextApiKey} />
          </div>
        ) : null}
      </div>
      <ListApiKeysComponent apiKeys={apiKeys} />
    </div>
  );
};
