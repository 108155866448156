import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../style/colors';

interface TabsProps {
  children: React.ReactElement<TabProps>[];
  activeTabColor?: string;
  activeTabBackgroundColor?: string;
  passiveTabBackgroundColor?: string;
  maxWidthPercentage: number;
  defaultActiveTabIndex?: number;
}

export const Tabs: React.FunctionComponent<TabsProps> = (props) => {
  const useStyles = makeStyles(() => ({
    tabs: {
      display: 'flex',
      maxWidth: props.maxWidthPercentage + '%',
      cursor: 'pointer',
    },
    tab: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px 8px 16px 8px',
      backgroundColor: props.passiveTabBackgroundColor || colors.grey4,
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      minWidth: '250px',
      textAlign: 'center',
    },
    activeTab: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: '16px 8px 16px 8px',
      backgroundColor: props.activeTabBackgroundColor || colors.white,
      color: props.activeTabColor || colors.black80,
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
      minWidth: '250px',
      textAlign: 'center',
    },
  }));

  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState(
    props.defaultActiveTabIndex ? props.children[props.defaultActiveTabIndex] : props.children[0]
  );

  const tabClicked = (tab: React.ReactElement<TabProps>) => {
    setCurrentTab(tab);
    // eslint-disable-next-line
    tab.props.onClick && tab.props.onClick();
  };

  return (
    <div>
      <div>
        <div className={classes.tabs}>
          {!!props.children &&
            props.children.map((option: React.ReactElement<TabProps>) => {
              const active = option.props.title === currentTab.props.title;
              return (
                <div
                  onClick={() => tabClicked(option)}
                  className={active ? classes.activeTab : classes.tab}
                  key={option.props.title}
                  data-test={`tab-${option.props.title}`}
                >
                  {option.props.title}
                </div>
              );
            })}
        </div>
        <div>
          {props.children.map((option: React.ReactElement<TabProps>) => {
            const active = option.props.title === currentTab.props.title;
            if (active) {
              return <div key={option.props.title}>{option}</div>;
            } else return <div key={option.props.title} />;
          })}
        </div>
      </div>
    </div>
  );
};

interface TabProps {
  title: string;
  children: React.ReactNode;

  onClick?(): void;
}

export const Tab = (props: TabProps) => {
  return <div>{props.children}</div>;
};
