import { pwnedPassword } from 'hibp';

// Regex to verify that that password is between 8 and 64 characters
const passwordLengthRegex = /^.{8,64}$/;

export const isPasswordLengthValid = (password: string) => passwordLengthRegex.test(password);

export async function isPwnedPassword(password: string): Promise<boolean> {
  return await pwnedPassword(password)
    .then((numberOfPwns) => numberOfPwns > 0)
    .catch((error) => {
      // eslint-disable-next-line
      console.log('error: ', error);
      return true;
    });
}
