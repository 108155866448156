import React, { useState } from 'react';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { colors } from '../../style/colors';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { TextInput } from '@minna-technologies/minna-ui/components/Inputs/TextInput';
import * as Firebase from '../../../services/firebase/Firebase';
import { makeStyles } from '@material-ui/core/styles';
import { CardHeader } from '@minna-technologies/minna-ui/components/Card/CardHeader';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { landingPage, loginPage } from '../../navigation/urls';
import { useHistory } from 'react-router-dom';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';

const useStyles = makeStyles(() => ({
  container: {
    background: colors.primaryGradient,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  card: {
    width: '360px',
    height: '280px',
    display: 'flex',
    flexDirection: 'column',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '100%',
  },
  confirmationContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    justifyContent: 'space-between',
    gap: '8px',
  },
  textContainer: {
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-around',
  },
}));

export const RequestResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const formMethods = useMinnaForm<{ email: string }>();
  const history = useHistory();

  const sendResetEmailLink = async () => {
    await Firebase.sendPasswordResetEmail(email, `${window.location.origin.toString()}${loginPage}`)
      .then(() => setShowConfirm(true))
      .catch(() => setShowConfirm(true)); // We should not leak if an email exists or not. Therefore we always show the confirm screen
  };

  const classes = useStyles();

  function showForm() {
    return (
      <>
        <CardHeader title="Enter your email address" />
        <Form onSubmit={sendResetEmailLink} formMethods={formMethods}>
          <div className={classes.formContent}>
            <TextInput
              onChange={(e) => setEmail(e.target.value)}
              label={'Email'}
              value={email}
              name={'email'}
              type={'email'}
              fullWidth
              data-test={'reset-password-email'}
            />
            <PrimaryButton type="submit" label="Reset password" fullWidth data-test="reset-password-button" />
          </div>
        </Form>
      </>
    );
  }

  function showConfirmation() {
    return (
      <>
        <CardHeader title="Check your inbox" />
        <div className={classes.confirmationContent}>
          <Body className={classes.textContainer} data-test="reset-password-confirmation">
            If the email address you supplied is registered with our service you will receive instructions to your inbox
            on how to reset your password.
          </Body>
          <Caption>Please remember to check your spam folder.</Caption>
          <div className={classes.buttonContainer}>
            <PrimaryButton
              label="Go to login page"
              onClick={() => history.push(landingPage)}
              fullWidth
              data-test="go-to-start-page"
            />
            <SecondaryButton
              label="I didn't receive an email"
              onClick={() => setShowConfirm(false)}
              fullWidth
              data-test="try-again"
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={classes.container}>
      <Card className={classes.card}>{!showConfirm ? showForm() : showConfirmation()}</Card>
    </div>
  );
};
