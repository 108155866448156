import React, { useState } from 'react';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { StarRatingComponent } from './components/StarRatingComponent';
import { CommentComponent } from './components/CommentComponent';
import { ThankUserComponent } from './components/ThankUserComponent';
import { makeStyles } from '@material-ui/core/styles';

interface StarAndCommentRatingProps {
  starQuestionText: string;
  starBodyText: string;
  textQuestionText: string;
  textBodyText: string;
  'data-test': string;
  onRatingGiven(currentRating: number): void;
  onTextFeedbackGiven(text: string): void;
  onClose(): void;
}

enum FeedbackStates {
  STAR_RATING,
  TEXT_FEEDBACK,
  THANK_USER,
}
const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxWidth: '984px',
  },
}));

export const StarAndCommentRating = (props: StarAndCommentRatingProps) => {
  const [state, setState] = useState(FeedbackStates.STAR_RATING);

  const classes = useStyles();
  const provideRatingAndMoveOn = (rating: number) => {
    setState(FeedbackStates.TEXT_FEEDBACK);
    props.onRatingGiven(rating);
  };

  const provideTextFeedbackAndMoveOn = (text: string) => {
    setState(FeedbackStates.THANK_USER);
    props.onTextFeedbackGiven(text);
  };

  const getCurrentComponent = () => {
    switch (state) {
      case FeedbackStates.STAR_RATING:
        return (
          <StarRatingComponent
            questionText={props.starQuestionText}
            bodyText={props.starBodyText}
            onRatingGiven={(rating) => provideRatingAndMoveOn(rating)}
            onClose={() => props.onClose()}
          />
        );
      case FeedbackStates.TEXT_FEEDBACK:
        return (
          <CommentComponent
            questionText={props.textQuestionText}
            bodyText={props.textBodyText}
            onTextFeedbackGiven={(text) => provideTextFeedbackAndMoveOn(text)}
            onClose={() => props.onClose()}
          />
        );
      case FeedbackStates.THANK_USER:
        return <ThankUserComponent onClose={() => props.onClose()} />;
    }
  };

  return (
    <Card className={classes.container} data-test={props['data-test']}>
      {getCurrentComponent()}
    </Card>
  );
};
