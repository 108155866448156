import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancellationActions, cancellationSelectors } from '../../../store/ducks/cancellation';
import { EditCancellationStatusComponent } from './EditCancellationStatusComponent';
import { CancellationStatusType } from '@minna-technologies/integration-portal-types/tech/minna/core/shared/cancellations/V2';
import { CancellationDetails } from './CancellationDetails';
import { makeStyles } from '@material-ui/core/styles';
import { BackButton } from '../../components/BackButton';
import { Snackbar } from '@minna-technologies/minna-ui/components/Snackbar';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { PageHeader } from '../../components/PageHeader';
import { useEnvironmentName } from '../../components/EnvironmentNameHook';
import { Location, Page } from '../../../services/mixpanel/mixpanelEvents';
import { mixpanelService } from '../../../services/mixpanel/mixpanelService';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';

interface CancellationDetailsPageProps {
  match: any;
}

const useStyles = makeStyles(() => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '0.75fr 0.25fr',
    gap: '16px',
  },
}));

export const CancellationDetailsPage: React.FunctionComponent<CancellationDetailsPageProps> = ({ match }) => {
  const dispatch = useDispatch();
  const environmentName = useEnvironmentName();
  const cancellationDetails = useSelector(cancellationSelectors.cancellationDetails);
  const cancellationIsSuccessfullyUpdated = useSelector(cancellationSelectors.cancellationStatusIsUpdated);

  useEffect(() => {
    dispatch(cancellationActions.fetchCancellationDetails(match.params.cancellationId, match.params.environmentname));
  }, []);

  const mixpanelPageProps = {
    Environment: environmentName,
    'Cancellation ID': match.params.cancellationId,
  };
  useEffect(() => {
    mixpanelService.trackViewedPage(Page.CANCELLATION_DETAILS, mixpanelPageProps);
  }, []);

  const updateCancellation = (
    cancellationStatusType: CancellationStatusType,
    maybeServiceProviderEmailText?: string
  ) => {
    dispatch(
      cancellationActions.updateCancellationStatus(
        match.params.cancellationId,
        match.params.environmentname,
        cancellationStatusType,
        maybeServiceProviderEmailText
      )
    );
  };
  const classes = useStyles();

  const goBack = () => {
    mixpanelService.trackClickedButton(Page.CANCELLATION_DETAILS, 'Back', {
      ...mixpanelPageProps,
      Location: Location.TopLeft,
    });
    history.back();
  };

  if (!cancellationDetails) {
    return (
      <div data-test="cancellation-not-found">
        <Card>The cancellation could not be found</Card>
      </div>
    );
  } else {
    return (
      <div>
        <Snackbar
          autoClose
          variant={cancellationIsSuccessfullyUpdated === false ? 'error' : 'success'}
          open={cancellationIsSuccessfullyUpdated !== null}
          message={
            cancellationIsSuccessfullyUpdated === false
              ? 'Failed to update cancellation. Contact our support for more information.'
              : 'Cancel status Successfully updated.'
          }
          onClose={() => dispatch(cancellationActions.resetCancellationStatusUpdated())}
        />
        <PageHeader
          title={`Cancellation ID: ${match.params.cancellationId}`}
          subTitle={capitalizeFirstLetter(environmentName)}
          icon={<BackButton onClick={goBack} />}
          currentPage={Page.CANCELLATION_DETAILS}
        />
        <div className={classes.content}>
          <EditCancellationStatusComponent
            onStatusChange={(cancellationStatusType, maybeEmailText) =>
              updateCancellation(cancellationStatusType, maybeEmailText)
            }
            cancellationStatus={cancellationDetails && cancellationDetails.status}
            mixpanelPageProps={mixpanelPageProps}
          />
          <CancellationDetails cancellationDetails={cancellationDetails} />
        </div>
      </div>
    );
  }
};
