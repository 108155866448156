import React from 'react';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { useDispatch, useSelector } from 'react-redux';
import { ConsoleViewOption, userActions, userSelectors } from '../../../store/ducks/user';
import { mixpanelService } from '../../../services/mixpanel/mixpanelService';
import { Page } from '../../../services/mixpanel/mixpanelEvents';
import { externalVersionInformation } from '../urls';
import { BodyBlack80 } from '../../components/text/BodyBlack80';
import { makeStyles } from '@material-ui/core/styles';
import { ExternalLink } from '../../components/ExternalLink';

const useStyles = makeStyles(() => ({
  bodyText: {
    marginTop: '8px',
    marginBottom: '16px',
  },
}));

export const VersionInfoCard = ({ page }: { page: Page }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentVersion = useSelector(userSelectors.currentConsoleViewOption);
  const version = currentVersion === ConsoleViewOption.API_VERSION_3 ? 'v3' : 'v2';
  const earlierOrLatest =
    currentVersion === ConsoleViewOption.API_VERSION_3 ? 'an earlier version' : 'the latest version';

  const goToVersionInfo = () => {
    mixpanelService.trackClickedButton(page, 'Version information', {
      'Link name': 'Version information',
      'Link URL': externalVersionInformation,
      Version: currentVersion,
    });
    window.open(externalVersionInformation, '_blank');
  };

  const toggleVersion = () => {
    if (currentVersion === ConsoleViewOption.API_VERSION_3) {
      dispatch(userActions.setConsoleViewOption(ConsoleViewOption.API_VERSION_LEGACY));
    } else {
      dispatch(userActions.setConsoleViewOption(ConsoleViewOption.API_VERSION_3));
    }
  };

  return (
    <Card>
      <div>
        <Title>You are using API - {version}</Title>
        <BodyBlack80 className={classes.bodyText}>
          To learn more about the differences between our versions,{' '}
          <ExternalLink link={externalVersionInformation} onClick={goToVersionInfo}>
            click here.{' '}
          </ExternalLink>
          You can also switch to <a onClick={() => toggleVersion()}>{earlierOrLatest}</a> if you need to.
        </BodyBlack80>
      </div>
    </Card>
  );
};
