import { SimpleMenuItem } from './IconMenuItem';
import React, { ReactElement } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../style/colors';
import { SubMenuItem } from './SubMenuItem';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Page } from '../../../services/mixpanel/mixpanelEvents';

export interface MenuItemWithSubMenu {
  title: string;
  icon?: ReactElement | null;
  linkToExternal?: boolean;
  path: string;
  subMenuItems: SimpleMenuItem[];
}

const createStyles = makeStyles(() => ({
  subMenuHeaderList: {
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
  },
  selectedSubMenuHeaderList: {
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      backgroundColor: colors.white,
    },
  },
  subMenuHeader: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
  },
  activeSubMenuItemText: {
    color: colors.grey1,
    fontWeight: 'bold',
    fontFamily: 'Roboto, Helvetica, sans-serif', // Need to use `disableTypography` on these elements to be able to make them bold. Then we need to reset the correct rules according to Minna UI
  },
  inactiveSubMenuItemText: {
    color: colors.grey1,
    fontFamily: 'Roboto, Helvetica, sans-serif', // Need to use `disableTypography` on these elements to be able to make them bold. Then we need to reset the correct rules according to Minna UI
  },
  sublistRoot: {
    display: 'flex',
    flexDirection: 'column',
    width: 'inherit',
    borderRadius: '8px',
    backgroundColor: colors.background,
    marginTop: '8px',
  },
  listItemIcon: {
    width: 24 + 16 + 'px',
    minWidth: 24 + 16 + 'px',
  },
}));

export const MenuItemWithSubMenuSupport = ({
  item,
  currentPath,
  currentPage,
  selected,
  onClick,
}: {
  item: MenuItemWithSubMenu;
  currentPath: string;
  currentPage: Page;
  selected: boolean;
  onClick(): void;
}) => {
  const toggleActive = () => {
    onClick();
  };

  const classes = createStyles();
  return (
    <ListItem
      classes={{
        button: selected ? classes.selectedSubMenuHeaderList : classes.subMenuHeaderList,
      }}
      disableRipple
      button
      onClick={() => toggleActive()}
      data-test="environment-menu-header"
    >
      <span className={classes.subMenuHeader}>
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          {item.icon && React.cloneElement(item.icon, { bold: selected })}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={<Body>{item.title}</Body>}
          className={selected ? classes.activeSubMenuItemText : classes.inactiveSubMenuItemText}
          data-test="environment-menu-header-text"
        />
      </span>
      {selected && (
        <List
          classes={{
            root: classes.sublistRoot,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {/*Need to stop the IconMenuItems click events, else it will trigger the parent to open and close when child is clicked*/}
          {item.subMenuItems.map((sub) => (
            <SubMenuItem item={sub} key={sub.title + item.title} currentPath={currentPath} currentPage={currentPage} />
          ))}
        </List>
      )}
    </ListItem>
  );
};
