import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '../../components/Tabs';
import { colors } from '../../style/colors';
import { IntroCardTemplate } from '../../components/templates/IntroCardTemplate';
import { NumberedList } from '../../components/NumberedList';
import { AccessTokenComponent } from '../../authentication/AccessTokenComponent';
import { BodyBlack80 } from '../../components/text/BodyBlack80';
import { Highlight } from './Highlight';
import { BookIcon } from '../../style/icons/BookIcon';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { ExternalLink } from '../../components/ExternalLink';
import { docsLink } from '../../navigation/urls';
import * as urls from '../../navigation/urls';
import { ClientEnvironment } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { mixpanelService } from '../../../services/mixpanel/mixpanelService';
import { Location, Page } from '../../../services/mixpanel/mixpanelEvents';

const useStyles = makeStyles(() => ({
  numberedListContent: {
    display: 'flex',
    alignItems: 'center',
  },
  smallPaddingRight: {
    paddingRight: '4px',
  },
  tabPadding: {
    paddingTop: '16px',
  },
}));

export const GetStartedWithLegacyVersions = ({ environment }: { environment: ClientEnvironment }) => {
  const classes = useStyles();

  const trackCopyAccessToken = () => {
    mixpanelService.trackClickedButton(Page.GET_STARTED, 'Copy access token', {
      Location: Location.Center,
      Environment: environment.environmentName,
    });
  };

  const trackExternalLinkClick = (buttonName: string) => {
    mixpanelService.trackClickedButton(Page.GET_STARTED, buttonName, {
      Location: Location.Center,
      Environment: environment.environmentName,
    });
  };

  return (
    <div data-test="get-started-with-legacy-card">
      <IntroCardTemplate
        headerText="To get started with this environment"
        introText="Get started quickly with a generated access token, or implement like in production: "
      >
        <Tabs
          activeTabColor={colors.white}
          activeTabBackgroundColor={colors.primary}
          passiveTabBackgroundColor={colors.background}
          maxWidthPercentage={70}
          defaultActiveTabIndex={environment.maybeAccessToken ? 0 : 1}
        >
          <Tab title={'Use a generated access token'}>{usingGeneratedAccessTokenTabContent()}</Tab>
          <Tab title={'Fetch access token programmatically'}>{usingClientKeysTabContent()}</Tab>
        </Tabs>
      </IntroCardTemplate>
    </div>
  );

  function usingGeneratedAccessTokenTabContent() {
    return (
      <>
        {environment.maybeAccessToken ? (
          <>
            <div className={classes.tabPadding}>
              <NumberedList
                listItems={[
                  <div key={1}>
                    {environment.maybeAccessToken && (
                      <AccessTokenComponent
                        accessToken={environment.maybeAccessToken}
                        onCopyAccessToken={trackCopyAccessToken}
                      />
                    )}
                    <br />
                    <div className={classes.numberedListContent}>
                      <BodyBlack80 className={classes.smallPaddingRight} component={'span'}>
                        Add this header to all HTTP requests:
                        <Highlight>Authorization: Bearer {environment.maybeAccessToken?.accessToken}</Highlight>
                      </BodyBlack80>
                    </div>
                  </div>,
                  <div key={2} className={classes.numberedListContent}>
                    <BookIcon className={classes.smallPaddingRight} nativeColor={colors.primaryDark} size={16} />
                    <Body className={classes.smallPaddingRight} color={colors.primary} variant={'link'}>
                      <ExternalLink
                        link={docsLink('/v1/reference/createuser')}
                        onClick={() => trackExternalLinkClick('Create a user link')}
                      >
                        Create a user
                      </ExternalLink>
                    </Body>
                  </div>,
                  <div key={3} className={classes.numberedListContent}>
                    <BookIcon className={classes.smallPaddingRight} nativeColor={colors.primaryDark} size={16} />
                    <Body className={classes.smallPaddingRight} color={colors.primary} variant={'link'}>
                      <ExternalLink
                        link={docsLink('/v2/reference/createcancellation')}
                        onClick={() => trackExternalLinkClick('Cancel a subscription link')}
                      >
                        Cancel a subscription
                      </ExternalLink>
                    </Body>
                    <BodyBlack80>on behalf of the user</BodyBlack80>
                  </div>,
                ]}
              />
            </div>
            <div className={classes.numberedListContent}>
              <BodyBlack80 className={classes.smallPaddingRight}>
                You can then use the tools under{' '}
                <Highlight linkTo={urls.cancellationPage(environment.environmentName)}>Cancel testing</Highlight>
                to test your requests.
              </BodyBlack80>
            </div>
          </>
        ) : (
          <div className={classes.tabPadding}>
            <BodyBlack80>
              <i>
                There is no generated access token available in this environment. Please refer to "Fetch access token
                programmatically"
              </i>
            </BodyBlack80>
          </div>
        )}
      </>
    );
  }

  function usingClientKeysTabContent() {
    return (
      <div className={classes.tabPadding} data-test="get-started-using-client-key">
        <NumberedList
          listItems={[
            <div key={1}>
              <div className={classes.numberedListContent}>
                <BodyBlack80 className={classes.smallPaddingRight}>
                  Register a client key for the environment under{' '}
                  <Highlight linkTo={urls.authenticationPage(environment.environmentName)}>Authentication</Highlight>
                </BodyBlack80>
              </div>
            </div>,
            <div key={2} className={classes.numberedListContent}>
              <BodyBlack80 className={classes.smallPaddingRight} component={'span'}>
                Encode your public key as a JWT and follow the steps in{' '}
                <ExternalLink
                  link={urls.externalAuthenticationJwtLink}
                  onClick={() => trackExternalLinkClick('Authentication link')}
                >
                  <span>
                    <BookIcon
                      className={classes.smallPaddingRight}
                      nativeColor={colors.primary}
                      size={16}
                      style={{ verticalAlign: 'bottom' }}
                    />
                    <span style={{ verticalAlign: 'bottom' }}>Authentication </span>
                  </span>
                </ExternalLink>
                to authenticate with the environment {environment.environmentName}.
              </BodyBlack80>

              <BodyBlack80></BodyBlack80>
            </div>,
            <div key={3} className={classes.numberedListContent}>
              <BookIcon className={classes.smallPaddingRight} nativeColor={colors.primary} size={16} />
              <Body className={classes.smallPaddingRight} color={colors.primary} variant={'link'}>
                <ExternalLink
                  link={docsLink('/v1/reference/createuser')}
                  onClick={() => trackExternalLinkClick('Create a user link')}
                >
                  Create a user
                </ExternalLink>
              </Body>
            </div>,
            <div key={4} className={classes.numberedListContent}>
              <BookIcon className={classes.smallPaddingRight} nativeColor={colors.primary} size={16} />
              <Body className={classes.smallPaddingRight} color={colors.primary} variant={'link'}>
                <ExternalLink
                  link={docsLink('/v2/reference/createcancellation')}
                  onClick={() => trackExternalLinkClick('Create a cancellation link')}
                >
                  Cancel a subscription
                </ExternalLink>
              </Body>
              <BodyBlack80>on behalf of the user</BodyBlack80>
            </div>,
          ]}
        />
        <div className={classes.numberedListContent}>
          <BodyBlack80 className={classes.smallPaddingRight}>
            You can then use the tools under{' '}
            <Highlight linkTo={urls.cancellationPage(environment.environmentName)}>Cancel testing</Highlight>
            to test your requests.
          </BodyBlack80>
        </div>
      </div>
    );
  }
};
