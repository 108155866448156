import React, { useState } from 'react';
import { colors } from '../../style/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { IconButton } from '@minna-technologies/minna-ui/components/Buttons/IconButton';
import { CloseIcon } from '@minna-technologies/minna-ui/icons/Close';
import { SecondaryButton } from '@minna-technologies/minna-ui/components/Buttons/SecondaryButton';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { TextArea } from '@minna-technologies/minna-ui/components/Inputs/TextArea';

interface CommentProps {
  questionText: string;
  bodyText: string;
  onTextFeedbackGiven(text: string): void;
  onClose(): void;
}
const useStyles = makeStyles(() => ({
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textFeedbackContainer: {
    maxWidth: '75%',
    marginTop: '16px',
    marginBottom: '30px',
  },
}));

export const CommentComponent = (props: CommentProps) => {
  const [currentTextFeedback, setCurrentTextFeedback] = useState('');

  const classes = useStyles();

  const provideTextFeedback = () => {
    props.onTextFeedbackGiven(currentTextFeedback);
  };

  return (
    <div>
      <div className={classes.headerRow}>
        <Headline3 color={colors.primary}>{props.questionText}</Headline3>
        <IconButton onClick={() => props.onClose()}>
          <CloseIcon />
        </IconButton>
      </div>
      <Body>{props.bodyText}</Body>
      <div className={classes.textFeedbackContainer}>
        <TextArea
          label=""
          value={currentTextFeedback}
          placeholder="Your comment"
          fullWidth
          onChange={(e) => setCurrentTextFeedback(e.target.value)}
        />
      </div>
      <SecondaryButton label="Send" onClick={() => provideTextFeedback()} />
    </div>
  );
};
