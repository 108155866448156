import React, { useState } from 'react';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { TextInput } from '@minna-technologies/minna-ui/components/Inputs/TextInput';
import { getErrorReasonFromFirebaseError, signOut } from '../../../services/firebase/Firebase';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { useHistory } from 'react-router-dom';
import { colors } from '../../style/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { LockShieldIcon } from '@minna-technologies/minna-ui/icons/LockShield';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { Snackbar } from '@minna-technologies/minna-ui/components/Snackbar';
import { isPasswordLengthValid, isPwnedPassword } from '../utils/passwordUtils';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { ChevronRightIcon } from '@minna-technologies/minna-ui/icons/ChevronRight';
import { Sentry } from '../../../utils/sentry';

const useStyles = makeStyles(() => ({
  cardContainer: {
    width: '360px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    padding: '16px',
  },
  loginIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  signupText: {
    paddingLeft: '12px',
    fontSize: '22px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
  },
  contentContainerText: {
    paddingBottom: '8px',
  },
  passwordRestrictionText: {
    paddingBottom: '16px',
  },
  passwordRestrictionList: {
    paddingLeft: '4px',
  },
}));

export interface PasswordWidgetProps {
  title: string;
  selectPasswordText: string;
  updatePassword: (password: string) => Promise<any>;
  nextPage: string;
}

/**
 * This will ask the user to fill in and update the user's password.
 * It's the responsibility of the parent to make sure that the user is authenticated before showing this widget.
 */
export const PasswordWidget: React.FunctionComponent<PasswordWidgetProps> = (props) => {
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const classes = useStyles();

  const selectPassword = () => {
    if (isPasswordLengthValid(password)) {
      return isPwnedPassword(password).then((isPasswordPwned: boolean) => {
        if (!isPasswordPwned) {
          // Password meets requirements of 8 to 64 characters and is not one of the most common passwords
          props
            .updatePassword(password)
            .then(async () => signOut().then(() => history.push(props.nextPage)))
            .catch((error) => {
              const errorMessage = getErrorReasonFromFirebaseError(error, 'Unknown error while updating password');
              Sentry.captureExceptionWithMessage(error, 'Got an error when trying to set user password in Firebase', {
                extra: { errorMessage },
              });
              setError(errorMessage);
            });
        } else {
          setError('Select a password that has not been part of any password breaches');
        }
      });
    } else {
      setError('Select a password between 8 and 64 characters');
    }
  };

  const passwordFormMethods = useMinnaForm<{ password: string }>();
  const isError = !!error;
  const isCorrectPasswordLength = isPasswordLengthValid(password);

  return (
    <Card className={classes.cardContainer}>
      <div className={classes.loginIconContainer}>
        <LockShieldIcon nativeColor={colors.secondary} />
        <Title className={classes.signupText}>{props.title}</Title>
      </div>

      <div className={classes.contentContainer}>
        <Body className={classes.contentContainerText}>Select a password:</Body>
        <Form
          onSubmit={() => selectPassword()}
          formMethods={passwordFormMethods}
          data-test={'complete-signup-password-form'}
        >
          <TextInput
            onChange={(e) => setPassword(e.target.value)}
            label={'Password'}
            value={password}
            name={'password'}
            type={'password'}
            data-test={'complete-signup-password'}
          />
          <Snackbar
            autoClose
            variant={'error'}
            open={isError}
            message={error}
            data-test={'select-password-error'}
            onClose={() => setError('')}
          />

          <div className={classes.passwordRestrictionText}>
            <Body>{props.selectPasswordText}</Body>
            <List>
              <ListItem>
                <ChevronRightIcon size={12} />
                <Body className={classes.passwordRestrictionList}>Between 8 and 64 characters</Body>
              </ListItem>
              <ListItem>
                <ChevronRightIcon size={12} />
                <Body className={classes.passwordRestrictionList}>
                  Not part of any password breaches. More information available on:{' '}
                  <a href={'https://haveibeenpwned.com/Passwords'}>haveibeenpwned.com/Passwords</a>
                </Body>
              </ListItem>
            </List>
          </div>
          <PrimaryButton
            type={'submit'}
            label={'Select password'}
            disabled={!isCorrectPasswordLength}
            data-test={'form-submit-button'}
          />
        </Form>
      </div>
    </Card>
  );
};
