import { FloatingButton } from '@minna-technologies/minna-ui/components/Buttons/FloatingButton';
import { ChevronLeftIcon } from '@minna-technologies/minna-ui/icons/ChevronLeft';
import React from 'react';
import { colors } from '../style/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: 'inherit',
    boxShadow: 'none',
  },
}));

export const BackButton = ({ onClick }: { onClick: () => void }) => {
  const classes = useStyles();
  return (
    <FloatingButton onClick={() => onClick()} className={classes.button} data-test="back-button" variant="inverse">
      <ChevronLeftIcon nativeColor={colors.primary} />
    </FloatingButton>
  );
};
