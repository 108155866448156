import React from 'react';
import { Select } from '@minna-technologies/minna-ui/components/Inputs/Select';
import { SelectOption } from '@minna-technologies/minna-ui/components/Inputs/Select/components/SelectOption';
import { ConsoleViewOption, userActions, userSelectors } from '../../../store/ducks/user';
import { useDispatch, useSelector } from 'react-redux';
import { mixpanelService } from '../../../services/mixpanel/mixpanelService';
import { Location, Page, pageFromUrl } from '../../../services/mixpanel/mixpanelEvents';

export const ViewSelector = () => {
  const dispatch = useDispatch();
  const currentViewOption = useSelector(userSelectors.currentConsoleViewOption);
  const onVersionChange = (newValue: ConsoleViewOption) => {
    const page: Page = pageFromUrl(new URL(window.location.href));
    mixpanelService.trackClickedButton(page, 'Current view', {
      Location: Location.Menu,
      Version: newValue, // This should be the new value, not the old
    });

    dispatch(userActions.setConsoleViewOption(newValue));
  };

  return (
    <div>
      <Select
        label="Current view"
        value={currentViewOption}
        onChange={(e) => onVersionChange(e.target.value as ConsoleViewOption)}
        data-test="view-selector"
      >
        <SelectOption
          value={ConsoleViewOption.API_VERSION_3}
          label="API V3 - Stable"
          data-test="view-selector-option-v3"
        />
        <SelectOption
          value={ConsoleViewOption.API_VERSION_LEGACY}
          label="API V2 - Legacy"
          data-test="view-selector-option-legacy"
        />
      </Select>
    </div>
  );
};
