import React, { ReactElement, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Subheading2 } from '@minna-technologies/minna-ui/components/Typography/Subheading2';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { ContentCopyIcon } from '../style/icons/ContentCopyIcon';
import Button from '@material-ui/core/Button';
import { colors } from '../style/colors';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Location, Page } from '../../services/mixpanel/mixpanelEvents';
import { mixpanelService } from '../../services/mixpanel/mixpanelService';
import { useEnvironmentName } from './EnvironmentNameHook';
import { ViewOptionBadge } from '../navigation/consoleviews/ViewOptionBadge';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: '16px',
  },
  leftContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '48px',
    width: '48px',
    backgroundColor: colors.white,
    borderColor: colors.grey4,
    borderRadius: '48px',
    border: '1px solid',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    paddingLeft: '16px',
  },
  mainHeaderContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  viewOptionBadge: {
    marginLeft: '16px',
  },
  environmentContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
  },
  urlPadding: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  copyButton: {
    backgroundColor: colors.background,
    border: '1px solid',
    borderRadius: '4px',
    borderColor: colors.grey4,
    color: colors.primaryDark,
  },
  activeCopyButton: {
    backgroundColor: colors.primary,
    border: '1px solid',
    borderRadius: '4px',
    borderColor: colors.grey4,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.primary,
    },
  },
  copyIcon: {
    fill: colors.primaryDark,
  },
}));

export const PageHeader = ({
  title,
  subTitle,
  icon,
  environmentUrl,
  currentPage,
  showConsoleViewOptionOnMainHeader,
}: {
  title: string;
  subTitle?: string;
  icon: ReactElement;
  environmentUrl?: string;
  currentPage: Page;
  showConsoleViewOptionOnMainHeader?: boolean;
}) => {
  const classes = useStyles();
  const [clickedCopy, setClickedCopy] = useState(false);

  const resetClickedCopy = () => {
    setTimeout(() => {
      setClickedCopy(false);
    }, 3000); //run this after 3 seconds
  };

  const environmentName = useEnvironmentName();
  const trackCopyEnvironmentUrl = () => {
    mixpanelService.trackClickedButton(currentPage, 'Copy environment URL', {
      Location: Location.TopRight,
      Environment: environmentName,
    });
  };

  return (
    <div className={classes.headerContainer}>
      <div className={classes.leftContainer}>
        <div className={classes.iconContainer}>{icon}</div>
        <div className={classes.textContainer}>
          {subTitle && (
            <Subheading2 color={colors.black60}>
              {subTitle}{' '}
              {!showConsoleViewOptionOnMainHeader ? (
                <span className={classes.viewOptionBadge}>
                  <ViewOptionBadge />
                </span>
              ) : null}
            </Subheading2>
          )}
          <div className={classes.mainHeaderContainer}>
            <Headline1>{title}</Headline1>{' '}
            {showConsoleViewOptionOnMainHeader ? (
              <span className={classes.viewOptionBadge}>
                <ViewOptionBadge />
              </span>
            ) : null}
          </div>
        </div>
      </div>
      {environmentUrl && (
        <Card variant={'border'} className={classes.environmentContainer}>
          <Caption variant={'bold'}>Environment URL</Caption>
          <Caption className={classes.urlPadding} color={colors.primaryDark} variant={'link'}>
            {environmentUrl}
          </Caption>
          <Button
            startIcon={<ContentCopyIcon nativeColor={clickedCopy ? colors.white : colors.primaryDark} />}
            className={clickedCopy ? classes.activeCopyButton : classes.copyButton}
            data-test={'page-header-copy-url-button'}
            onClick={() => {
              trackCopyEnvironmentUrl();
              navigator.clipboard.writeText(environmentUrl as string).then(() => {
                setClickedCopy(true);
                return resetClickedCopy();
              });
            }}
          >
            <Body color={clickedCopy ? colors.white : colors.primaryDark}>{clickedCopy ? 'Copied!' : 'Copy'}</Body>
          </Button>
        </Card>
      )}
    </div>
  );
};
