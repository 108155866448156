import { Typography, TypographyProps } from '@minna-technologies/minna-ui/components/Typography';
import React from 'react';
import { EllipsisProps, getEllipsisStyles } from './elipsis';
export const Overline: React.FunctionComponent<OverlineProps> = ({ style, ellipsis, maxWidth, ...otherProps }) => {
  /**
   * 0.75rem corresponds to 12px in Invision
   */
  const ellipsisStyles = getEllipsisStyles(maxWidth, ellipsis);
  const baseStyle: React.CSSProperties = {
    fontSize: '0.75rem',
    fontWeight: 500,
    letterSpacing: '2px',
    lineHeight: '1.33em',
    textTransform: 'uppercase',
    ...ellipsisStyles,
    ...style,
  };

  return <Typography {...otherProps} variant="overline" style={baseStyle} />;
};

type OverlineProps = Omit<TypographyProps, 'variant'> & EllipsisProps;
