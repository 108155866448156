import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import Button from '@material-ui/core/Button';
import { ContentCopyIcon } from '../style/icons/ContentCopyIcon';
import { MoreVerticalIcon } from '@minna-technologies/minna-ui/icons/MoreVertical';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import React from 'react';
import { ClientKeyProps } from '../../store/ducks/authentication';
import { makeStyles } from '@material-ui/core/styles';
import { mixpanelService } from '../../services/mixpanel/mixpanelService';
import { Location, Page } from '../../services/mixpanel/mixpanelEvents';
import { useEnvironmentName } from '../components/EnvironmentNameHook';

const useStyles = makeStyles(() => ({
  container: {
    marginLeft: '8px',
    width: '100%',
    height: 'fit-content',
  },
  clientKeyContainer: {
    padding: '16px',
    marginBottom: '16px',
    borderRadius: 10,
  },
  clientKeyContentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '8px',
  },
  clientKeyIdContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  body: {
    paddingBottom: '8px',
  },
  meatballButton: {
    display: 'none',
  },
}));

interface ClientKeyCardProps {
  clientKey: ClientKeyProps;
  setCopyClientKeyIdSnackbarContent: (copy: any) => void;
}

export const ClientKeyCard = (props: ClientKeyCardProps) => {
  const classes = useStyles();
  const clientKey = props.clientKey;
  const environmentName = useEnvironmentName();

  const trackCopyClientKeyId = () => {
    mixpanelService.trackClickedButton(Page.AUTHENTICATION, 'Copy client key ID', {
      Location: Location.Right,
      Environment: environmentName,
      'Client key name': clientKey.name,
      'Client key market': clientKey.market,
      'Client key namespace': clientKey.clientNamespaceAccessLevel,
    });
  };

  return (
    <Card key={clientKey.id} className={classes.clientKeyContainer}>
      <div data-test={'view-client-key-container'}>
        <div className={classes.clientKeyContentContainer}>
          <Body data-test={'view-client-key-name'}>
            <b>Name:</b> {clientKey.name}
          </Body>
        </div>
        <Body className={classes.body} data-test={'view-client-key-market'}>
          <b>Market:</b> {clientKey.market}
        </Body>
        <Body className={classes.body} data-test={'view-client-key-namespace'}>
          <b>Namespace:</b> {clientKey.clientNamespaceAccessLevel}
        </Body>
        <div className={classes.clientKeyContentContainer}>
          <div className={classes.clientKeyIdContainer}>
            <Body data-test={'view-client-key-id'}>
              <b>Id:</b> {clientKey.id}
            </Body>
            <Button
              data-test={'view-client-key-copy-id-button'}
              onClick={() => {
                trackCopyClientKeyId();
                navigator.clipboard
                  .writeText(clientKey.id)
                  .then(() =>
                    props.setCopyClientKeyIdSnackbarContent(`Copied the id of ${clientKey.name} to clipboard`)
                  );
              }}
            >
              <ContentCopyIcon />
            </Button>
          </div>
          <Button className={classes.meatballButton}>
            <MoreVerticalIcon />
          </Button>
        </div>
      </div>
    </Card>
  );
};
