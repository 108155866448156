import React from 'react';
import { ErrorComponent } from './ErrorComponent';
import { UserShrugIllustration } from '@minna-technologies/minna-ui/illustrations/UserShrug';
import { useHistory } from 'react-router-dom';
import { jiraServiceDesk } from '../urls';

export const UnexpectedError = () => {
  const history = useHistory();
  return (
    <ErrorComponent
      headerText={'An unexpected error occurred'}
      bodyText={
        'There was an error when we tried to perform the requested action. Please try again later, or contact our technical support.'
      }
      recoverActionText={'Contact support'}
      escapeActionText={'Go back'}
      illustration={<UserShrugIllustration />}
      onRecoverClick={() => history.push(jiraServiceDesk)}
      onEscapeClick={() => history.goBack()}
    />
  );
};
