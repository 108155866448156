import React, { useState } from 'react';
import { ContentCopyIcon } from '../style/icons/ContentCopyIcon';
import Button from '@material-ui/core/Button';
import { Snackbar } from '@minna-technologies/minna-ui/components/Snackbar';

export const CopyButton = ({
  contentToCopy,
  afterCopy,
  successMessage,
}: {
  contentToCopy: string;
  afterCopy?: (copied: string) => void;
  successMessage?: string;
}) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  return (
    <>
      <Button
        data-test={'copy-button'}
        style={{ padding: '0' }}
        onClick={() => {
          navigator.clipboard
            .writeText(contentToCopy)
            .then(() => successMessage && setShowSnackbar(true))
            .then(() => afterCopy && afterCopy(contentToCopy));
        }}
      >
        <ContentCopyIcon />
      </Button>
      <Snackbar
        autoClose
        variant={'default'}
        open={showSnackbar}
        message={successMessage}
        data-test={'copy-client-key-snackbar'}
        onClose={() => setShowSnackbar(false)}
      />
    </>
  );
};
