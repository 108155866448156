import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';
import { colors } from '../../style/colors';
import whiteLogo from '../../../assets/logo-white.svg';
import { useHistory } from 'react-router-dom';
import * as urls from '../urls';
import { Location, Page } from '../../../services/mixpanel/mixpanelEvents';
import { mixpanelService } from '../../../services/mixpanel/mixpanelService';

export const appHeaderHeight = 56;
const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    zIndex: 9999,
    position: 'fixed',
    width: '100%',
  },
  appBar: {
    height: appHeaderHeight + 'px',
  },
  appBarContent: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginLeft: '16px',
    cursor: 'pointer',
  },
  logo: {
    marginRight: '16px',
  },
}));

export const AppHeader = ({ currentPage }: { currentPage: Page }) => {
  const classes = useStyles();
  const history = useHistory();

  const onLogoClick = () => {
    mixpanelService.trackClickedButton(currentPage, 'Logo', {
      Location: Location.Menu,
    });
    history.push(urls.dashboard);
  };

  return (
    <div className={classes.root}>
      <AppBar classes={{ root: classes.appBar }} position="static">
        <div className={classes.appBarContent} onClick={onLogoClick} data-test={'app-header-logo'}>
          <img className={classes.logo} src={whiteLogo} alt="Minna Technologies logo" />
          <Headline3 color={colors.white}>Minna Console </Headline3>
        </div>
      </AppBar>
    </div>
  );
};
