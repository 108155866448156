export const white = '#ffffff';
export const green = '#81C784';
export const darkGreen = '#43A047';
export const blueTheme = '#00b5b9';
export const black = '#000000';
export const grey = '#f4f4f4';
export const grey100 = '#f5f5f5';
export const grey200 = '#eee';
export const purple = '#9575CD';
export const orange = '#FFB74D';
export const darkGray = 'rgba(34, 34, 34, 0.8)';
export const yellow = '#FFF176';
export const red = '#E57373';

export const colors = {
  primaryComplementary: '#0480A2',
  primaryDark: '#008487',
  primary: '#00B5B9',
  primaryGradient: 'radial-gradient(177.89% 100% at 100% 100%, #7DD7B6 0%, #0CA7B9 47.67%, #006E98 100%)',
  primaryHover: '#E9FFFF',
  secondaryDark: '#6B3596',
  secondary: '#B661EA',
  secondaryGradient: 'linear-gradient(90deg, #B661EA 5.21%, #F9B6FF 99.98%)',
  black: '#222222',
  black80: 'rgba(34, 34, 34, 0.8)',
  black60: 'rgba(34, 34, 34, 0.6)',
  grey1: '#4A4B4B',
  grey2: '#818181',
  grey3: '#B7B7B7',
  grey4: '#E5E5E5',
  background: '#F8F8F8',
  white: '#FFFFFF',
  accent: {
    info: '#7FA3FF',
    helpful: '#97E9C6',
    positive: '#68C958',
    attention: '#FFD467',
    warning: '#F1924A',
    danger: '#E43F5C',
  },
};
