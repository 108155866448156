import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PageHeader } from '../components/PageHeader';
import { colors } from '../style/colors';
import { IntroCardTemplate } from '../components/templates/IntroCardTemplate';
import { ClientEnvironment } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { EnvironmentIcon } from '@minna-technologies/minna-ui/icons/Environment';
import { Page } from '../../services/mixpanel/mixpanelEvents';
import { mixpanelService } from '../../services/mixpanel/mixpanelService';

const useStyles = makeStyles(() => ({
  backgroundContainer: {
    height: '90vh',
  },
  header: {
    marginBottom: '32px',
  },
  firstRow: {
    display: 'grid',
    gridTemplateColumns: 'minmax(250px, 0.7fr)',
    gap: '16px',
  },
  contactSupport: {
    color: colors.primaryDark,
    padding: '8px 0 0 0',
  },
}));

export const ProductionGetStarted = (props: { environment: ClientEnvironment }) => {
  const classes = useStyles();
  const environment = props.environment;

  useEffect(() => {
    mixpanelService.trackViewedPage(Page.GET_STARTED, { Environment: environment.environmentName });
  }, []);

  return (
    <div className={classes.backgroundContainer}>
      <PageHeader
        title={'Get started'}
        subTitle={environment.environmentName}
        environmentUrl={environment.integrationApiUrl}
        icon={<EnvironmentIcon nativeColor={colors.primary} />}
        currentPage={Page.GET_STARTED}
      />
      <div className={classes.firstRow}>
        <IntroCardTemplate headerText={'Welcome to Production!'}>{''}</IntroCardTemplate>
      </div>
    </div>
  );
};
