import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { PageHeader } from '../components/PageHeader';
import { colors } from '../style/colors';
import { IntroCardTemplate } from '../components/templates/IntroCardTemplate';
import { ClientEnvironment } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { BodyBlack80 } from '../components/text/BodyBlack80';
import { CheckboxList } from '../components/CheckboxList';
import { EnvironmentIcon } from '@minna-technologies/minna-ui/icons/Environment';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import { mixpanelService } from '../../services/mixpanel/mixpanelService';
import { Page } from '../../services/mixpanel/mixpanelEvents';
import { VersionInfoCard } from '../navigation/consoleviews/VersionInfoCard';
import { GetStartedWithLegacyVersions } from './components/GetStartedWithLegacyVersions';
import { ConsoleViewSwitch, LegacyApiVersion, V3APIVersion } from '../navigation/consoleviews/ConsoleViewSwitch';
import { GetStartedWithV3 } from './components/GetStartedWithV3';
import { useSelector } from 'react-redux';
import { ConsoleViewOption, userSelectors } from '../../store/ducks/user';

const useStyles = makeStyles(() => ({
  backgroundContainer: {
    marginBottom: '64px',
  },
  header: {
    marginBottom: '32px',
  },
  welcomeContainer: {
    padding: '8px 0px',
  },
  firstRow: {
    display: 'grid',
    gridTemplateColumns: 'minmax(250px, 0.7fr)',
    gap: '16px',
  },
  contactSupport: {
    color: colors.primaryDark,
    padding: '8px 0 0 0',
  },
  numberedListContent: {
    display: 'flex',
    alignItems: 'center',
  },
  smallPaddingRight: {
    paddingRight: '4px',
  },
  tabPadding: {
    paddingTop: '16px',
  },
}));

export const TestEnvironmentGetStarted = (props: { environment: ClientEnvironment }) => {
  const classes = useStyles();
  const environment = props.environment;
  const viewVersion = useSelector(userSelectors.currentConsoleViewOption);

  useEffect(() => {
    mixpanelService.trackViewedPage(Page.GET_STARTED, {
      Environment: environment.environmentName,
      Version: viewVersion,
    });
  }, []);

  const listItemsLegacyVersions = [
    <BodyBlack80 key={1}>Authenticate to our APIs the same way you would in production</BodyBlack80>,
    <BodyBlack80 key={2}>
      Cancel subscriptions to a mocked set of merchants that mimic production data (without sending real requests to the
      merchant)
    </BodyBlack80>,
    <BodyBlack80 key={3}>
      Mimic Merchant responses to requests to cancel user’s subscriptions to test how they behave in your interface
    </BodyBlack80>,
  ];

  const listItemsV3Version = [
    <BodyBlack80 key={1}>Authenticate to our APIs the same way you would in production</BodyBlack80>,
  ];

  return (
    <div className={classes.backgroundContainer}>
      <PageHeader
        title={'Get started'}
        subTitle={capitalizeFirstLetter(environment.environmentName)}
        environmentUrl={environment.integrationApiUrl}
        icon={<EnvironmentIcon nativeColor={colors.primary} />}
        currentPage={Page.GET_STARTED}
      />
      <div className={classes.firstRow}>
        <IntroCardTemplate
          headerText={`Welcome to your ${capitalizeFirstLetter(environment.environmentName)} environment!`}
        >
          <div className={classes.welcomeContainer}>
            <BodyBlack80>
              This test environment is set up to help you get to know our APIs, and get more familiar with how to manage
              subscriptions on behalf of your users.
            </BodyBlack80>
            <br />
            <BodyBlack80>In this environment you can:</BodyBlack80>
          </div>
          <CheckboxList
            listItems={viewVersion === ConsoleViewOption.API_VERSION_3 ? listItemsV3Version : listItemsLegacyVersions}
          />
        </IntroCardTemplate>
        <VersionInfoCard page={Page.GET_STARTED} />
        <ConsoleViewSwitch>
          <LegacyApiVersion>
            <GetStartedWithLegacyVersions environment={environment} />
          </LegacyApiVersion>
          <V3APIVersion>
            <GetStartedWithV3 environment={environment} />
          </V3APIVersion>
        </ConsoleViewSwitch>
      </div>
    </div>
  );
};
