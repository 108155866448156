import React from 'react';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { makeStyles } from '@material-ui/core/styles';
import { APIKeyProps, authenticationActions } from '../../store/ducks/authentication';
import { useTheme } from '@material-ui/core';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { useDispatch } from 'react-redux';
import { CopyButton } from '../components/CopyButton';
import warningImage from '../../assets/Warning.svg';

const useStyles = (colors: any) =>
  makeStyles(() => ({
    infoContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '18px',
    },
    infoText: {
      marginLeft: '8px',
    },
    field: {
      paddingBottom: '8px',
    },
    box: {
      border: '1px solid',
      backgroundColor: colors.base.background,
      padding: '16px',
      marginBottom: '48px',
    },
    button: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
    },
  }));

export const ViewableApiKey = ({ apiKey, plainTextApiKey }: { apiKey: APIKeyProps; plainTextApiKey: string }) => {
  const { colors } = useTheme();
  const classes = useStyles(colors)();
  const dispatch = useDispatch();
  return (
    <div>
      <Card>
        <div className={classes.infoContainer}>
          <img src={warningImage} alt="Info sign" />
          <Body className={classes.infoText}>
            Copy and save your API key. We are unable to display it for you later.
          </Body>
        </div>
        <div className={classes.box}>
          <Body className={classes.field} data-test="viewable-api-key-card-name">
            <b>Name: </b> {apiKey.name}
          </Body>
          <Body className={classes.field} data-test="viewable-api-key-card-market">
            <b>Market: </b> {apiKey.market}
          </Body>
          <Body className={classes.field} data-test="viewable-api-key-card-prefix">
            <b>Prefix: </b> {apiKey.prefix}
          </Body>
          <Body className={classes.field} data-test="viewable-api-key-card-plain-text-api-key">
            <b>API key: </b> {plainTextApiKey} &nbsp;&nbsp; <CopyButton contentToCopy={plainTextApiKey} />
          </Body>
        </div>
        <div className={classes.button}>
          <PrimaryButton label="Close" onClick={() => dispatch(authenticationActions.removePlainTextAPIKey())} />
        </div>
      </Card>
    </div>
  );
};
