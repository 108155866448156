import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { errorPage } from '../../navigation/urls';
import { ResetPasswordPage } from '../ResetPassword/ResetPasswordPage';
import { UnexpectedError } from '../../navigation/error/UnexpectedError';
import { VerifySignupPage } from '../SignupPage/VerifySignupPage';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const FirebaseEmailHandlingPage = () => {
  const query = useQuery();
  const history = useHistory();

  const mode = query.get('mode');
  const maybeActionCode = query.get('oobCode');

  useEffect(() => {
    // Mode and oobCode (aka actionCode) are required, redirect to error page if either of them doesn't exist.
    if (!mode || !maybeActionCode) {
      return history.push(errorPage);
    }
  }, []);

  // Now this type conversion should be safe
  const actionCode = maybeActionCode as string;
  // Action code could also contain: 'recoverEmail' and 'verifyEmail'
  if (mode === 'resetPassword') {
    return <ResetPasswordPage actionCode={actionCode} />;
  } else if (mode === 'signIn') {
    return <VerifySignupPage />;
  } else {
    // If action code isn't one of the expected, show the error component.
    return <UnexpectedError />;
  }
};
