import { AxiosError } from 'axios';
import { errorActions } from '../../store/ducks/error';
import store from '../../store/setup';

/**
 * This function is used to handle common error cases when making httpCalls.
 * Wrap your call with this method, and it will show the user appropriate errors when they happen.
 *
 * The defined error codes are dispatched to a store. The `src/app/error/ErrorHandlerComponent.tsx` is listening for
 * errors, and will display a proper error component.
 *
 * Suggestions for future improvements.
 * - Look in to using axios.interceptors instead. Although this is a bit scary, since we might want to
 * allow errors sometimes, for example when fetching the user context. https://github.com/axios/axios#interceptors
 *
 */
export const callWithErrorHandler = async (httpCall: Promise<any>) => {
  return httpCall.catch((error: AxiosError) => {
    if (error.response) {
      store.dispatch(errorActions.setHttpErrorCode(error.response.status));
    }
    throw error;
  });
};
