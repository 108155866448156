import React, { useEffect } from 'react';
import { ErrorComponent } from './ErrorComponent';
import { UserMagnifyingGlassIllustration } from '@minna-technologies/minna-ui/illustrations/UserMagnifyingGlass';
import { useHistory } from 'react-router-dom';
import { mixpanelService } from '../../../services/mixpanel/mixpanelService';
import { Page } from '../../../services/mixpanel/mixpanelEvents';

export const PageNotFound = () => {
  const history = useHistory();
  useEffect(() => {
    mixpanelService.trackViewedPage(Page.ERROR);
  }, []);
  return (
    <ErrorComponent
      headerText={'Page not found'}
      bodyText={'We could not find the page you were looking for.'}
      recoverActionText={'Go back'}
      illustration={<UserMagnifyingGlassIllustration />}
      onRecoverClick={() => history.goBack()}
    />
  );
};
