import React, { useState, useEffect } from 'react';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { TextInput } from '@minna-technologies/minna-ui/components/Inputs/TextInput';
import { isSignInWithEmailLink, signInWithEmailLink } from '../../../services/firebase/Firebase';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { useHistory } from 'react-router-dom';
import { errorPage, selectPasswordPage } from '../../navigation/urls';
import { colors } from '../../style/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { UserIcon } from '@minna-technologies/minna-ui/icons/User';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { Snackbar } from '@minna-technologies/minna-ui/components/Snackbar';
import { Sentry } from '../../../utils/sentry';

const useStyles = makeStyles(() => ({
  backgroundContainer: {
    background: colors.primaryGradient,
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headline: {
    paddingBottom: '56px',
  },
  cardContainer: {
    width: '360px',
    height: '260px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    padding: '16px',
  },
  loginIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  signupText: {
    paddingLeft: '12px',
    fontSize: '22px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
  },
  contentContainerText: {
    paddingBottom: '8px',
  },
  signupCompletedText: {
    paddingBottom: '16px',
  },
}));

export const VerifySignupPage = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (!isSignInWithEmailLink()) {
      history.push(errorPage);
    }
  }, []);

  const validateEmailLink = () => {
    signInWithEmailLink(email)
      .then(() => {
        setError('');
        history.push(selectPasswordPage);
      })
      .catch((error) => {
        Sentry.captureExceptionWithMessage(error, 'User email link was invalid');
        // TODO check if "email invalid"
        setError('EMAIL_INVALID');
      });
  };

  const isError = !!error;

  const emailFormMethods = useMinnaForm<{ email: string }>();

  return (
    <div className={classes.backgroundContainer}>
      <div className={classes.componentContainer}>
        <Headline1 color={colors.white} className={classes.headline}>
          Welcome to the Minna Console
        </Headline1>
        <Card className={classes.cardContainer}>
          <div className={classes.loginIconContainer}>
            <UserIcon nativeColor={colors.secondary} />
            <Title className={classes.signupText}>Complete sign up</Title>
          </div>
          <div className={classes.contentContainer}>
            <Body className={classes.contentContainerText}>Please enter your email address:</Body>

            <Form
              onSubmit={() => validateEmailLink()}
              formMethods={emailFormMethods}
              data-test={'complete-signup-email-form'}
            >
              <TextInput
                onChange={(e) => setEmail(e.target.value)}
                error={isError}
                label={'Email'}
                value={email}
                name={'email'}
                type={'email'}
                data-test={'complete-signup-email'}
              />
              <Snackbar
                autoClose
                variant={'error'}
                open={isError}
                message={'Something went wrong. Did you use the email you registered with?'}
                data-test={'verify-email-error'}
                onClose={() => setError('')}
              />
              <PrimaryButton disabled={email === ''} type={'submit'} label={'Next'} data-test={'form-submit-button'} />
            </Form>
          </div>
        </Card>
      </div>
    </div>
  );
};
