import React from 'react';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { colors } from '../style/colors';
import { ArrowBackIcon } from '@minna-technologies/minna-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  noActiveKeysContainer: {
    border: '2px dashed',
    borderColor: colors.grey3,
    borderRadius: '8px',
    padding: '16px',
  },
  noActiveKeysText: {
    padding: '4px',
  },
  clientKeyIdContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

export const NoActiveKeysComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.noActiveKeysContainer}>
      <Body color={colors.black80} className={classes.noActiveKeysText}>
        No keys have been created yet.
      </Body>
      <div className={classes.clientKeyIdContainer}>
        <ArrowBackIcon />
        <Body variant={'bold'} color={colors.black80} className={classes.noActiveKeysText}>
          Create one here
        </Body>
      </div>
    </div>
  );
};
