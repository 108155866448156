import React from 'react';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../style/colors';
import { Location, Page } from '../../../services/mixpanel/mixpanelEvents';
import { mixpanelService } from '../../../services/mixpanel/mixpanelService';

export interface SimpleSubMenuItem {
  title: string;
  path: string;
  mixpanelProps?: {
    Button: string;
    Environment?: string;
  };
}

const createStyles = makeStyles(() => ({
  selectedButton: {
    backgroundColor: colors.primary + '!important',
    borderColor: colors.white,
    colors: colors.white,
    fill: colors.white,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
    '&:selected': {
      backgroundColor: colors.primary,
      color: colors.white,
    },
  },
  button: {
    borderColor: colors.primaryDark,
    '&:hover': {
      backgroundColor: colors.primaryHover,
    },
    '&:selected': {
      backgroundColor: colors.primary,
      color: colors.white,
    },
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'baseline',
  },
  selectedListItemText: {
    fontFamily: 'Roboto, Helvetica, sans-serif', // Need to use `disableTypography` on these elements to be able to make them bold. Then we need to reset the correct rules according to Minna UI
    color: colors.white,
  },
  listItemIcon: {
    width: 14 + 16 + 'px',
    minWidth: 14 + 16 + 'px',
  },
  listItemText: {
    fontFamily: 'Roboto, Helvetica, sans-serif', // Need to use `disableTypography` on these elements to be able to make them bold. Then we need to reset the correct rules according to Minna UI
    color: colors.primaryDark,
  },
  selectedIcon: {
    width: '12px',
    height: '20px',
    left: '36px',
    top: '8px',
    borderBottom: '2px solid',
    borderLeft: '2px solid',
    borderColor: colors.white,
    color: colors.white,
  },
  icon: {
    width: '12px',
    height: '20px',
    left: '36px',
    top: '8px',
    borderBottom: '2px solid',
    borderLeft: '2px solid',
    borderColor: colors.primaryDark,
    color: colors.primaryDark,
  },
}));

export const SubMenuItem = ({
  item,
  currentPath,
  currentPage,
}: {
  item: SimpleSubMenuItem;
  currentPath: string;
  currentPage: Page;
}) => {
  const trackMixpanelEvent = () => {
    if (item.mixpanelProps) {
      mixpanelService.trackClickedButton(currentPage, item.mixpanelProps.Button, {
        Location: Location.Menu,
        ...item.mixpanelProps,
      });
    }
  };

  // This is to avoid a warning in the console where a ref is missing when using a Link as a ListItem component.
  const LinkWithTo: any = React.forwardRef((props: unknown, ref: React.Ref<any>) => (
    <Link ref={ref} {...props} to={item.path} onClick={trackMixpanelEvent} />
  ));
  LinkWithTo.displayName = 'LinkWithTo';

  const isSelected = currentPath === item.path;
  const classes = createStyles();
  return (
    <ListItem
      classes={{
        button: isSelected ? classes.selectedButton : classes.button,
      }}
      disableRipple
      button
      component={LinkWithTo}
      data-test={'sub-menu-item'}
    >
      <div className={classes.listItemContainer}>
        <ListItemIcon classes={{ root: classes.listItemIcon }}>
          <div className={isSelected ? classes.selectedIcon : classes.icon} />
        </ListItemIcon>
        <ListItemText
          disableTypography
          className={isSelected ? classes.selectedListItemText : classes.listItemText}
          primary={item.title}
        />
      </div>
    </ListItem>
  );
};
