import React from 'react';
import { useEnvironmentName } from '../components/EnvironmentNameHook';
import { userSelectors } from '../../store/ducks/user';
import { useSelector } from 'react-redux';
import { ProductionGetStarted } from './ProductionGetStarted';
import { EnvironmentType } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { TestEnvironmentGetStarted } from './TestEnvironmentGetStarted';
import { PageNotFound } from '../navigation/error/PageNotFound';
import { SkeletonLoadingPage } from '../components/loading/SkeletonLoadingPage';

export const GetStartedPage = () => {
  const currentEnvironmentName = useEnvironmentName();
  const userEnvironments = useSelector(userSelectors.userEnvironments);
  const isLoadingUserContext = useSelector(userSelectors.isLoadingUserContext);

  const currentEnvironment = userEnvironments?.find(
    (environment) => environment.environmentName.toLowerCase() == currentEnvironmentName.toLowerCase()
  );
  if (currentEnvironment !== undefined) {
    switch (currentEnvironment.environmentType) {
      case EnvironmentType.Production:
        return <ProductionGetStarted environment={currentEnvironment} />;
      case EnvironmentType.TestEnvironment:
        return <TestEnvironmentGetStarted environment={currentEnvironment} />;
      case EnvironmentType.Development:
        return <TestEnvironmentGetStarted environment={currentEnvironment} />;
    }
  } else if (userEnvironments === undefined || isLoadingUserContext) {
    return <SkeletonLoadingPage />;
  } else {
    return <PageNotFound />;
  }
};
