import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../style/colors';

const useStyles = makeStyles(() => ({
  backgroundContainer: {
    background: colors.primaryGradient,
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  componentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const BlueBasePage = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles();

  return (
    <div className={classes.backgroundContainer}>
      <div className={classes.componentContainer}>{children}</div>
    </div>
  );
};
