import { FeatureToggleStatus } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/config';
import {
  CsatInteraction,
  CsatInteractionType,
  CsatType,
} from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/user/customersatisfaction';
import { isAfter, isBefore, parseISO, subDays } from 'date-fns';

export const shouldShowOverallCsat = (
  userCreatedAt: Date,
  csatInteraction: CsatInteraction[],
  mixpanelFeatureToggleStatus: FeatureToggleStatus
) => {
  if (mixpanelFeatureToggleStatus === FeatureToggleStatus.Disabled) {
    return false;
  }
  const now = Date.now();
  const fourDaysAgo = subDays(now, 4);
  const fourteenDaysAgo = subDays(now, 14);
  const thirtyDaysAgo = subDays(now, 30);

  const interactionsWithOverallCsatWidget = csatInteraction
    .filter((i) => i.csatType === CsatType.OverallCsat)
    .map((interaction) => ({
      interactionType: interaction.interactionType,
      at: parseISO(interaction.at),
    }));

  const answeredInteractionPast30Days = interactionsWithOverallCsatWidget
    .filter((interaction) => isAfter(interaction.at, thirtyDaysAgo))
    .filter((interaction) => interaction.interactionType === CsatInteractionType.AnsweredCsatQuestion);

  const dismissedInteractionsPast14Days = interactionsWithOverallCsatWidget
    .filter((interaction) => isAfter(interaction.at, fourteenDaysAgo))
    .filter((interaction) => interaction.interactionType === CsatInteractionType.DismissedCsatQuestion);

  if (answeredInteractionPast30Days.length > 0) {
    return false;
  }

  if (dismissedInteractionsPast14Days.length >= 3) {
    return false;
  }
  return isBefore(userCreatedAt, fourDaysAgo);
};
