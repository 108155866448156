import { CancellationStatusType } from '@minna-technologies/integration-portal-types/tech/minna/core/shared/cancellations/V2';

export interface StatusOption {
  status: CancellationStatusType;
  title: string;
  description: string;
}

export const successfullStatusesWithDescriptions: StatusOption[] = [
  {
    status: CancellationStatusType.ConfirmedByServiceProvider,
    title: 'Cancellation is confirmed',
    description: 'The cancellation request has been accepted and the contract will be cancelled.',
  },
  {
    status: CancellationStatusType.ConfirmedByUser,
    title: 'Confirmed by user',
    description: 'The user has reported this subscription to be cancelled when prompted',
  },
  {
    status: CancellationStatusType.CancelledWithUpcomingPayments,
    title: 'Cancelled with upcoming payments',
    description: "User's subscription has been cancelled but there are payments due which the user needs to address.",
  },
];

export const rejectedStatusesWithDescriptions: StatusOption[] = [
  {
    status: CancellationStatusType.NoSuchAccount,
    title: 'Account is missing',
    description: 'The merchant did not find an account that matched the cancellation info.',
  },
  {
    status: CancellationStatusType.NoResponseFromServiceProvider,
    title: 'No answer from merchant',
    description: 'The merchant has not answered the cancellation.',
  },
  {
    status: CancellationStatusType.HasBindingPeriod,
    title: 'User has binding period',
    description: 'The user has binding period.',
  },
  {
    status: CancellationStatusType.UserNotTheOwner,
    title: 'User is not the owner',
    description:
      'User is not the subscriber (for example the payer) of the subscription and cannot therefore cancel the contract itself.',
  },
  {
    status: CancellationStatusType.WrongUserInput,
    title: 'Wrong user input',
    description: 'User entered incorrect information for one or more of the cancellation questions.',
  },
  {
    status: CancellationStatusType.ServiceProviderContactedUser,
    title: 'Merchant contacted the user',
    description: 'The merchant confirms that they have reached out to the user to resolve the matter.',
  },
];
