import * as React from 'react';

export const getEllipsisStyles = (maxWidth?: string, ellipsis: boolean = false): React.CSSProperties => {
  return {
    textOverflow: ellipsis ? 'ellipsis' : undefined,
    overflow: ellipsis ? 'hidden' : undefined,
    maxWidth,
  };
};

export interface EllipsisProps {
  ellipsis?: boolean;
  maxWidth?: string;
}
