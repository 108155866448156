import { AppDispatch, RootState } from '../setup';
import * as CancellationApi from '@minna-technologies/integration-portal-v1/CancelApi';
import {
  CancellationStatusSelector,
  IntegrationPortalCancellationDetails,
  IntegrationPortalCancellationSearchResult,
} from '@minna-technologies/integration-portal-types/tech/minna/core/api/integrationportal';
import { CancellationId } from '@minna-technologies/integration-portal-types/tech/minna/core/shared/cancellations';
import { CancellationStatusType } from '@minna-technologies/integration-portal-types/tech/minna/core/shared/cancellations/V2';
import { callWithErrorHandler } from '../../app/error/CommonErrorListner';
import { EnvironmentName } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { Sentry } from '../../utils/sentry';

interface CancellationsState {
  cancellationDetails: null | IntegrationPortalCancellationDetails;
  cancellationUpdatedSuccessfully: boolean | null;
  cancellationSearchResults: IntegrationPortalCancellationSearchResult[];
}

const initialState: CancellationsState = {
  cancellationDetails: null,
  cancellationUpdatedSuccessfully: null,
  cancellationSearchResults: [],
};

export const cancellationSelectors = {
  cancellationDetails: (state: RootState) => state.cancellation.cancellationDetails,
  cancellationStatusIsUpdated: (state: RootState) => state.cancellation.cancellationUpdatedSuccessfully,
  cancellationsSearchResults: (state: RootState) => state.cancellation.cancellationSearchResults,
};

export const cancellationActions = {
  fetchCancellationDetails,
  updateCancellationStatus,
  resetCancellationStatusUpdated,
  listCancellations,
};

export const actionTypes = {
  SET_CANCELLATION_DETAILS: 'SET_CANCELLATION_DETAILS',
  SET_CANCELLATION_STATUS_UPDATED: 'SET_CANCELLATION_STATUS_UPDATED',
  SET_CANCELLATION_SEARCH_RESULT: 'SET_CANCELLATION_SEARCH_RESULT',
};

const cancellationEvents = {
  setCancellationDetails: (cancellationDetails: IntegrationPortalCancellationDetails | null) => ({
    type: actionTypes.SET_CANCELLATION_DETAILS,
    cancellationDetails,
  }),
  setCancellationSuccessfullyUpdated: (success: boolean | null) => ({
    type: actionTypes.SET_CANCELLATION_STATUS_UPDATED,
    cancellationUpdatedSuccessfully: success,
  }),
  setCancellationsSearchResult: (results: IntegrationPortalCancellationSearchResult[]) => ({
    type: actionTypes.SET_CANCELLATION_SEARCH_RESULT,
    cancellationSearchResults: results,
  }),
};

export const cancellationsReducer = (state: RootState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_CANCELLATION_DETAILS:
      return {
        ...state,
        cancellationDetails: action.cancellationDetails,
      };
    case actionTypes.SET_CANCELLATION_STATUS_UPDATED:
      return {
        ...state,
        cancellationUpdatedSuccessfully: action.cancellationUpdatedSuccessfully,
      };
    case actionTypes.SET_CANCELLATION_SEARCH_RESULT:
      return {
        ...state,
        cancellationSearchResults: action.cancellationSearchResults,
      };
    default:
      return state;
  }
};

// ACTIONS
function fetchCancellationDetails(cancellationId: CancellationId, environmentName: EnvironmentName) {
  return async (dispatch: AppDispatch) => {
    try {
      const cancellationDetails = await callWithErrorHandler(
        CancellationApi.getCancellationDetails(environmentName, cancellationId)
      );
      dispatch(cancellationEvents.setCancellationDetails(cancellationDetails));
    } catch (error) {
      cancellationEvents.setCancellationDetails(null);
      Sentry.captureExceptionWithMessage(error, 'Failed to fetch cancellation details', {
        extra: { environmentName, cancellationId },
      });
    }
  };
}

function updateCancellationStatus(
  cancellationId: CancellationId,
  environmentName: EnvironmentName,
  cancellationStatusType: CancellationStatusType,
  maybeServiceProviderEmailText?: string
) {
  return async (dispatch: AppDispatch) => {
    const data = {
      status: {
        text: 'dummy text',
        detailedCancellationStatus: cancellationStatusType,
        serviceProviderMessageToUser: maybeServiceProviderEmailText,
      },
    };
    try {
      const response = await callWithErrorHandler(
        CancellationApi.patchCancellationStatus(environmentName, cancellationId, data)
      );
      dispatch(cancellationEvents.setCancellationDetails(response));
      dispatch(cancellationEvents.setCancellationSuccessfullyUpdated(true));
    } catch (err) {
      Sentry.captureExceptionWithMessage(err, 'Failed to update a cancellation', {
        extra: { cancellationId, environmentName },
      });
      dispatch(cancellationEvents.setCancellationSuccessfullyUpdated(false));
    }
  };
}

function resetCancellationStatusUpdated() {
  return async (dispatch: AppDispatch) => {
    dispatch(cancellationEvents.setCancellationSuccessfullyUpdated(null));
  };
}

function listCancellations(
  environmentName: EnvironmentName,
  cancellationStatus: CancellationStatusSelector,
  maybeCancellationQuery?: string
) {
  return async (dispatch: AppDispatch) => {
    try {
      const searchResult = await callWithErrorHandler(
        CancellationApi.listCancellations(environmentName, cancellationStatus, maybeCancellationQuery)
      );
      dispatch(cancellationEvents.setCancellationsSearchResult(searchResult.cancellations));
    } catch (error) {
      Sentry.captureExceptionWithMessage(error, 'Failed to fetch cancellations', {
        extra: { environmentName, maybeCancellationQuery },
      });
      throw error;
    }
  };
}
