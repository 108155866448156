import { combineReducers } from 'redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { loginReducer } from './ducks/login';
import { cancellationsReducer } from './ducks/cancellation';
import { userReducer } from './ducks/user';
import { errorReducer } from './ducks/error';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authenticationReducer } from './ducks/authentication';
import { trackingReducer } from './ducks/tracking';

const reducers: any = combineReducers({
  login: loginReducer,
  cancellation: cancellationsReducer,
  authentication: authenticationReducer,
  user: userReducer,
  error: errorReducer,
  tracking: trackingReducer,
});

const composeEnhancers = composeWithDevTools({});

const store: any = createStore(
  reducers,
  {}, // initial state
  composeEnhancers(applyMiddleware(thunk))
);

/* eslint-disable import/no-default-export */
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
//https://redux.js.org/recipes/usage-with-typescript
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
