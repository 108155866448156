import React from 'react';
import { colors } from '../../style/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Headline3 } from '@minna-technologies/minna-ui/components/Typography/Headline3';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '24px',
    cursor: 'pointer',
  },
}));
export const BankName = ({ name, onClick }: { name: string; onClick(): void }) => {
  const classes = useStyles();
  return (
    <div className={classes.container} onClick={() => onClick()} data-test={'bank-name'}>
      <Headline3 color={colors.primaryDark}>{name}</Headline3>
    </div>
  );
};
