import React from 'react';
import { Badge } from '@minna-technologies/minna-ui/components/Badge';
import { useSelector } from 'react-redux';
import { ConsoleViewOption, userSelectors } from '../../../store/ducks/user';
import { useTheme } from '@material-ui/core';

export const ViewOptionBadge = () => {
  const { colors } = useTheme();
  const currentViewOption = useSelector(userSelectors.currentConsoleViewOption);
  const badgeLabel = currentViewOption === ConsoleViewOption.API_VERSION_3 ? 'V3' : 'V2';
  return (
    <span data-test="view-option-badge">
      <Badge backgroundColor={colors.element.badgeSurface} label={badgeLabel} />
    </span>
  );
};
