import React from 'react';
import { useSelector } from 'react-redux';
import { ConsoleViewOption, userSelectors } from '../../../store/ducks/user';

interface ConsoleViewSwitchProps {
  activeOnView: ConsoleViewOption;
}

export const LegacyApiVersion = ({ children }: { children: React.ReactElement }) => {
  return children;
};
LegacyApiVersion.defaultProps = {
  activeOnView: ConsoleViewOption.API_VERSION_LEGACY,
};
export const V3APIVersion = ({ children }: { children: React.ReactElement }) => {
  return children;
};
V3APIVersion.defaultProps = {
  activeOnView: ConsoleViewOption.API_VERSION_3,
};

/**
 * This component toggle different views depending on the view option (version) selected from the menu.
 *
 * Use it like this
 * <ConsoleViewSwitch>
 *   <LegacyVersion> Legacy version content here</LegacyVersion>
 *   <LatestVersion> Latest version content here </LatestVersion>
 * </ConsoleViewSwitch>
 */
export const ConsoleViewSwitch = ({ children }: { children: React.ReactElement<ConsoleViewSwitchProps>[] }) => {
  const currentViewOption = useSelector(userSelectors.currentConsoleViewOption);
  return <div>{children.filter((child) => child.props.activeOnView == currentViewOption).map((child) => child)}</div>;
};
