import React from 'react';
import { APIKeyProps } from '../../store/ducks/authentication';
import { NoActiveKeysComponent } from './NoActiveKeysComponent';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../style/colors';
import { APIKeyCard } from './APIKeyCard';

const useStyles = makeStyles(() => ({
  activeKeysTitle: {
    color: colors.black80,
    paddingBottom: '16px',
  },
  cardContainer: {
    marginBottom: '16px',
  },
}));

export const ListApiKeysComponent = ({ apiKeys }: { apiKeys: APIKeyProps[] }) => {
  const classes = useStyles();
  const ActiveKeysHeader = () => <Title className={classes.activeKeysTitle}>Active keys</Title>;
  if (apiKeys.length === 0) {
    return (
      <div>
        <ActiveKeysHeader />
        <NoActiveKeysComponent />
      </div>
    );
  } else {
    return (
      <div>
        <ActiveKeysHeader />
        {apiKeys.map((apiKey) => (
          <div className={classes.cardContainer} key={apiKey.id}>
            <APIKeyCard apiKey={apiKey} />
          </div>
        ))}
      </div>
    );
  }
};
