import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OverallCsatComponent } from '../customersatisfaction/OverallCsatComponent';
import { PayPerCallIcon } from '@minna-technologies/minna-ui/icons/PayPerCall';
import { ElectricityNetworkIcon } from '@minna-technologies/minna-ui/icons/ElectricityNetwork';
import { PageHeader } from '../components/PageHeader';
import { colors } from '../style/colors';
import { IntroCardTemplate } from '../components/templates/IntroCardTemplate';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { CardRowLink } from '@minna-technologies/minna-ui/components/CardRowLink';
import {
  externalActionsApiLink,
  externalCancellationApiLink,
  getStartedPage,
  jiraServiceDeskExternalLink,
  statusPageIo,
} from '../navigation/urls';
import { useHistory } from 'react-router-dom';
import { EnvironmentComponent } from './EnvironmentComponent';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../store/ducks/user';
import { DocumentIcon } from '@minna-technologies/minna-ui/icons/Document';
import { BookIcon } from '../style/icons/BookIcon';
import { Location, Page } from '../../services/mixpanel/mixpanelEvents';
import { mixpanelService } from '../../services/mixpanel/mixpanelService';
import { EnvironmentName } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { ConsoleViewSwitch, V3APIVersion, LegacyApiVersion } from '../navigation/consoleviews/ConsoleViewSwitch';
import { VersionInfoCard } from '../navigation/consoleviews/VersionInfoCard';
import { useVersionedDocsUrls } from '../navigation/useVersionedDocsUrls';
import { ChevronDownIcon } from '@minna-technologies/minna-ui/icons/ChevronDown';
import { CircleWithContent } from '../components/CircleWithContent';
import { useTheme } from '@material-ui/core';
import { EnvironmentIcon } from '@minna-technologies/minna-ui/icons/Environment';

const useStyles = makeStyles(() => ({
  backgroundContainer: {
    height: '90vh',
  },
  gridLayout: {
    display: 'grid',
    gridTemplateColumns: '0.65fr 0.35fr',
    gap: '16px',
    marginBottom: '16px',
  },
  versionInfoCardContainer: {
    marginBottom: '16px',
  },
  csatWidgetContainer: {
    position: 'absolute',
    bottom: '10%',
    width: '100%',
  },
  contactSupport: {
    color: colors.primaryDark,
  },
}));

export const Dashboard = () => {
  const usersEnvironments = useSelector(userSelectors.userEnvironments);
  const versionedDocsUrls = useVersionedDocsUrls();
  const currentVersionView = useSelector(userSelectors.currentConsoleViewOption);

  const navigateToExternal = (url: string) => {
    window.open(url, '_blank');
  };

  const contactSupportToAccessOtherEnvironments = () => {
    mixpanelService.trackClickedButton(Page.DASHBOARD, 'Contact support', {
      Location: Location.Center,
      Version: currentVersionView,
    });
    navigateToExternal(jiraServiceDeskExternalLink);
  };
  const contactOurSupport = () => {
    mixpanelService.trackClickedButton(Page.DASHBOARD, 'Contact support', {
      Location: Location.Right,
      Version: currentVersionView,
    });
    navigateToExternal(jiraServiceDeskExternalLink);
  };
  const usefulLinksAuthentication = () => {
    const link = versionedDocsUrls.authenticationArticle;
    mixpanelService.trackClickedButton(Page.DASHBOARD, 'Useful link', {
      Location: Location.Center,
      'Link name': 'Authentication',
      'Link URL': link,
      Version: currentVersionView,
    });
    navigateToExternal(link);
  };
  const usefulLinksCancelApi = () => {
    mixpanelService.trackClickedButton(Page.DASHBOARD, 'Useful link', {
      Location: Location.Center,
      'Link name': 'Cancel API',
      'Link URL': externalCancellationApiLink,
      Version: currentVersionView,
    });
    navigateToExternal(externalCancellationApiLink);
  };

  const usefulLinksActionApi = () => {
    mixpanelService.trackClickedButton(Page.DASHBOARD, 'Useful link', {
      Location: Location.Center,
      'Link name': 'Action API',
      'Link URL': externalActionsApiLink,
      Version: currentVersionView,
    });
    navigateToExternal(externalActionsApiLink);
  };
  const usefulLinksOperationalStatus = () => {
    mixpanelService.trackClickedButton(Page.DASHBOARD, 'Useful link', {
      Location: Location.Center,
      'Link name': 'Operational status',
      'Link URL': statusPageIo,
      Version: currentVersionView,
    });
    navigateToExternal(statusPageIo);
  };
  const usefulLinksGoToDocs = () => {
    const link = versionedDocsUrls.mainDocs;
    mixpanelService.trackClickedButton(Page.DASHBOARD, 'Useful link', {
      Location: Location.Center,
      'Link name': 'Go to docs',
      'Link URL': link,
      Version: currentVersionView,
    });
    navigateToExternal(link);
  };
  const goToEnvironment = (environmentName: EnvironmentName) => {
    mixpanelService.trackClickedButton(Page.DASHBOARD, 'Get started', {
      Location: Location.Center,
      Environment: environmentName,
      Version: currentVersionView,
    });
    history.push(getStartedPage(environmentName));
  };

  useEffect(() => {
    mixpanelService.trackViewedPage(Page.DASHBOARD);
  }, []);

  const history = useHistory();
  const classes = useStyles();
  const { colors: newColors } = useTheme();
  return (
    <div className={classes.backgroundContainer}>
      <PageHeader
        title={'Dashboard'}
        icon={<PayPerCallIcon nativeColor={colors.primary} />}
        currentPage={Page.DASHBOARD}
        showConsoleViewOptionOnMainHeader
      />
      <div className={classes.gridLayout}>
        <div>
          <div className={classes.versionInfoCardContainer}>
            <VersionInfoCard page={Page.DASHBOARD} />
          </div>
          <IntroCardTemplate
            headerText={'Select one of your environments to get started'}
            headerIcon={
              <CircleWithContent
                size={'24px'}
                backgroundColor={newColors.action.primary}
                color={newColors.textOn.primary}
              >
                <ChevronDownIcon nativeColor={newColors.textOn.primary} />
              </CircleWithContent>
            }
          >
            {!!usersEnvironments &&
              usersEnvironments.map((environment) => (
                <CardRowLink
                  key={environment.environmentName}
                  onClick={() => goToEnvironment(environment.environmentName)}
                  icon={<EnvironmentIcon nativeColor={colors.primaryDark} />}
                  body={
                    <EnvironmentComponent
                      key={environment.environmentName}
                      environmentType={environment.environmentType}
                      environmentName={environment.environmentName}
                    />
                  }
                />
              ))}
            <Divider fullWidth />
            <CardRowLink
              style={{ color: colors.primaryDark, paddingTop: '16px', paddingBottom: '8px' }}
              onClick={contactSupportToAccessOtherEnvironments}
              body={<span className={classes.contactSupport}>Contact us to access other environments</span>}
            />
          </IntroCardTemplate>
        </div>
        <IntroCardTemplate
          headerText={'Want to talk to a human?'}
          introText={'Contact our support and we’ll be happy to help you out as soon as we can!'}
        >
          <Divider fullWidth />
          <CardRowLink
            style={{ color: colors.primaryDark, padding: '16px 0 8px 0' }}
            onClick={contactOurSupport}
            body={<span className={classes.contactSupport}>Contact our support</span>}
          />
        </IntroCardTemplate>
      </div>
      <div className={classes.gridLayout}>
        <IntroCardTemplate headerText="Useful links">
          <CardRowLink
            style={{ color: colors.black80, paddingLeft: 0, paddingRight: 0 }}
            onClick={usefulLinksAuthentication}
            icon={<BookIcon nativeColor={colors.primary} />}
            body={'Authentication'}
            data-test="useful-links-authentication"
          />
          <ConsoleViewSwitch>
            <LegacyApiVersion>
              <CardRowLink
                style={{ color: colors.black80, paddingLeft: 0, paddingRight: 0 }}
                onClick={usefulLinksCancelApi}
                icon={<DocumentIcon nativeColor={colors.primary} />}
                body={'Cancel API guides'}
                data-test="cancel-api-guides-link"
              />
            </LegacyApiVersion>
            <V3APIVersion>
              <CardRowLink
                style={{ color: colors.black80, paddingLeft: 0, paddingRight: 0 }}
                onClick={usefulLinksActionApi}
                icon={<DocumentIcon nativeColor={colors.primary} />}
                body={'Action API guides'}
              />
            </V3APIVersion>
          </ConsoleViewSwitch>
          <CardRowLink
            style={{ color: colors.black80, paddingLeft: 0, paddingRight: 0 }}
            onClick={usefulLinksOperationalStatus}
            icon={<ElectricityNetworkIcon nativeColor={colors.primary} />}
            body={'Operational status'}
          />
          <Divider fullWidth />
          <CardRowLink
            style={{ color: colors.primaryDark, padding: '16px 0 8px 0' }}
            onClick={usefulLinksGoToDocs}
            body={<span className={classes.contactSupport}>Go to docs.minna.tech</span>}
          />
        </IntroCardTemplate>
      </div>
      <div className={classes.csatWidgetContainer}>
        <OverallCsatComponent />
      </div>
    </div>
  );
};
