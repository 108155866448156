import React, { useEffect } from 'react';
import { isUserAuthenticated, updatePassword } from '../../../services/firebase/Firebase';
import { useHistory } from 'react-router-dom';
import { signupCompletedPage, errorPage } from '../../navigation/urls';
import { colors } from '../../style/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { PasswordWidget } from '../PasswordWidget/PasswordWidget';

const useStyles = makeStyles(() => ({
  backgroundContainer: {
    background: colors.primaryGradient,
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headline: {
    paddingBottom: '56px',
  },
  cardContainer: {
    width: '360px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    padding: '16px',
  },
  loginIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  signupText: {
    paddingLeft: '12px',
    fontSize: '22px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
  },
  contentContainerText: {
    paddingBottom: '8px',
  },
  passwordRestrictionText: {
    paddingBottom: '16px',
  },
  passwordRestrictionList: {
    paddingLeft: '4px',
  },
}));

export const SelectPasswordPage = () => {
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    // Verify that the firebase user is properly set, so that we reset password for the correct user.
    // Otherwise redirect to the error page.
    if (!isUserAuthenticated()) {
      history.push(errorPage);
    }
  }, []);

  return (
    <div className={classes.backgroundContainer}>
      <div className={classes.componentContainer}>
        <Headline1 color={colors.white} className={classes.headline}>
          Welcome to the Minna Console
        </Headline1>
        <PasswordWidget
          title={'Complete sign up'}
          selectPasswordText="Select a password:"
          updatePassword={updatePassword}
          nextPage={signupCompletedPage}
        />
      </div>
    </div>
  );
};
