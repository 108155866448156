import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app/App';
import { initSentry } from './utils/sentry';

initSentry();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
