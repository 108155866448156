import React from 'react';
import { CircleWithContent } from './CircleWithContent';
import { colors } from '../style/colors';

import { makeStyles } from '@material-ui/core/styles';

interface NumberedListProps {
  listItems: string[] | any[];
}

const useStyles = makeStyles(() => ({
  itemContainer: {
    display: 'flex',
    marginBottom: '16px',
    alignItems: 'flex-start',
  },
  text: {
    marginLeft: '16px',
  },
}));

export const NumberedList = (props: NumberedListProps) => {
  const classes = useStyles();
  return (
    <div>
      {props.listItems.map((text: any, index: number) => (
        <div className={classes.itemContainer} key={index}>
          <CircleWithContent backgroundColor={colors.primary} size={'24px'} color={colors.white}>
            {index + 1}
          </CircleWithContent>
          <span className={classes.text}>{text}</span>
        </div>
      ))}
    </div>
  );
};
