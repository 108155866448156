export const landingPage = '/';
export const dashboard = '/dashboard';

export const loginPage = '/login';
export const requestResetPasswordPage = '/reset-password';
export const resetPasswordCompletedPage = '/reset-password/completed';
export const emailHandlingPage = '/email-handling';

export const completeSignupPage = '/complete-signup';
export const selectPasswordPage = '/complete-signup/select-password';
export const signupCompletedPage = '/complete-signup/completed';

export const getStartedPage = (environmentName: string) => `/${environmentName.toLowerCase()}/get-started`;
export const cancellationPage = (environmentName: string) => `/${environmentName.toLowerCase()}/cancellations`;
export const cancellationDetailsPage = (cancellationId: string, environmentName: string) =>
  `/${environmentName.toLowerCase()}/cancellations/${cancellationId}`;
export const authenticationPage = (environmentName: string) => `/${environmentName.toLowerCase()}/authentication`;
export const securityManagementPage = (environmentName: string) => `/${environmentName.toLowerCase()}/security`;
export const errorPage = '/error';

export const jiraServiceDeskExternalLink = 'https://minnatechnologies.atlassian.net/servicedesk/customer/portal/2';
export const jiraServiceDesk = '/service-desk';
export const statusPageIo = 'https://minnatechnologies.statuspage.io/';

// Link to docs.minna.tech with the user logged in
export function docsLink(readmePath?: string): string {
  if (readmePath) {
    return `/api/docs/login?redirectTo=${readmePath}`;
  } else {
    return `/api/docs/login`;
  }
}

export const externalAuthenticationJwtLink = docsLink('/v1/docs/authentication#create-jwt');
export const externalCancellationApiLink = docsLink('/v1/docs/cancel-native');
export const externalActionsApiLink = docsLink('/v3/docs/user-cancel');
export const externalVersionInformation = docsLink('/v2/docs/our-api-versions');
export const externalAuthenticationLinkLegacy = docsLink('/v1/docs/authentication');
export const externalAuthenticationLinkV3 = docsLink('/v3/docs/quick-start');
export const externalDocsMainLink = docsLink('/v1');
export const externalDocsMainLinkV3 = docsLink('/v3');
export const externalApiReferenceLink = docsLink('/v1/reference');
export const externalApiReferenceV3 = docsLink('/v3/reference');
export const integrationPortalSignupPage = 'https://developer.minna.tech';
export const userAgreement = 'https://developer.minna.tech/user-agreement/';
