import React from 'react';
import { Menu } from './Menu/Menu';
import { userSelectors } from '../../store/ducks/user';
import { useSelector } from 'react-redux';
import { AppHeader } from './Menu/AppHeader';
import { Route, RouteProps } from 'react-router-dom';
import { Page } from '../../services/mixpanel/mixpanelEvents';
import { SkeletonLoadingPage } from '../components/loading/SkeletonLoadingPage';

interface RouteWithMenuProps extends RouteProps {
  page: Page;
}

export const RouteWithMenu: React.StatelessComponent<RouteWithMenuProps> = ({ component, path, page, ...rest }) => {
  const userContext = useSelector(userSelectors.userContext);
  const isLoadingUserContext = useSelector(userSelectors.isLoadingUserContext);
  const userEnvironments = useSelector(userSelectors.userEnvironments);

  // eslint-disable-next-line react/display-name
  const renderComponent: any = (Component: any) => (props: any) => {
    return <Component {...props} />;
  };

  return (
    <Route
      path={path}
      {...rest}
      render={(renderRouteProps) => {
        return (
          <>
            <AppHeader currentPage={page} />
            <Menu userContext={userContext} currentPage={page}>
              {isLoadingUserContext || userEnvironments === undefined ? (
                <SkeletonLoadingPage />
              ) : (
                renderComponent(component)({ path, ...rest, ...renderRouteProps })
              )}
            </Menu>
          </>
        );
      }}
    />
  );
};
