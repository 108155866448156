import React, { useState } from 'react';
import { TextInput } from '@minna-technologies/minna-ui/components/Inputs/TextInput';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { IconButton } from '@minna-technologies/minna-ui/components/Buttons/IconButton';
import { EyeIcon } from '@minna-technologies/minna-ui/icons/EyeIcon';
import { EyeStrikeIcon } from '@minna-technologies/minna-ui/icons/EyeStrikeIcon';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { loginActions, loginSelectors } from '../../../store/ducks/login';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { LoginErrors } from '../../../services/authenticationService';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { colors } from '../../style/colors';
import { Snackbar } from '@minna-technologies/minna-ui/components/Snackbar';
import { useHistory } from 'react-router-dom';
import { SignupInstructions } from './SignupInstructions';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { requestResetPasswordPage } from '../../navigation/urls';
import whiteLogo from '../../../assets/logo-white.svg';

const useStyles = makeStyles(() => ({
  backgroundContainer: {
    background: colors.primaryGradient,
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  componentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginFormContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headline: {
    paddingBottom: '56px',
  },
  cardContainer: {
    width: '360px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    padding: '16px',
  },
  loginText: {
    marginBottom: '26px',
  },
  passwordInput: {
    marginBottom: '16px',
  },
  logo: {
    width: '96px',
    alignSelf: 'center',
    marginBottom: '56px',
  },
}));

export const LoginPage = () => {
  const [hideText, setHideText] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const maybeErrorCode = useSelector(loginSelectors.loginError);

  const resetPasswordClick = () => history.push(requestResetPasswordPage);

  const searchParams = new URLSearchParams(window.location.search);
  const redirectTo = searchParams.get('redirectTo');

  const attemptLogIn = () => {
    dispatch(loginActions.logIn(email.trim(), password.trim(), history, redirectTo));
  };

  const formMethods = useMinnaForm<{ email: string; password: string }>();
  const loginDisabled = email === '' || password === '';
  const isError = maybeErrorCode !== null;

  return (
    <div className={classes.backgroundContainer}>
      <div className={classes.componentContainer}>
        <div className={classes.loginFormContainer}>
          <img className={classes.logo} src={whiteLogo} alt="Minna Technologies logo" />
          <Headline1 color={colors.white} className={classes.headline}>
            Welcome to the Minna Console
          </Headline1>
          <Card className={classes.cardContainer}>
            <Title className={classes.loginText}>Login</Title>
            <Form onSubmit={() => attemptLogIn()} formMethods={formMethods}>
              <TextInput
                onChange={(e) => setEmail(e.target.value)}
                error={isError}
                label={'Email'}
                value={email}
                name={'email'}
                type={'email'}
                data-test={'email-login'}
              />
              <TextInput
                name={'password'}
                error={isError}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type={hideText ? 'password' : 'text'}
                label={'Password'}
                data-test={'password'}
                className={classes.passwordInput}
                helpText={
                  <Caption
                    variant="link"
                    onClick={resetPasswordClick}
                    color={colors.primaryDark}
                    data-test={'reset-password'}
                  >
                    Forgot password?
                  </Caption>
                }
                endAdornment={
                  <IconButton onClick={() => setHideText(!hideText)}>
                    {hideText ? <EyeIcon /> : <EyeStrikeIcon />}
                  </IconButton>
                }
              />
              <Snackbar
                autoClose
                variant={'error'}
                open={isError}
                message={errorTexts(maybeErrorCode)}
                data-test={'login-error'}
                onClose={() => dispatch(loginActions.resetLogin())}
              />
              <PrimaryButton disabled={loginDisabled} label={'Login'} type={'submit'} data-test={'login-button'} />
            </Form>
          </Card>
          <br />
          <Card className={classes.cardContainer}>
            <SignupInstructions />
          </Card>
        </div>
      </div>
    </div>
  );

  function errorTexts(maybeErrorCode: LoginErrors | null): string {
    switch (maybeErrorCode) {
      case LoginErrors.INVALID_EMAIL_OR_PASSWORD:
        return 'Incorrect email or password, try again.';
      case LoginErrors.TOO_MANY_REQUESTS:
        return 'You have made to many attempts. Try again later.';
      case LoginErrors.UNKNOWN_ERROR:
        return 'Something went wrong. Please contact our support or try again later.';
      default:
        return '';
    }
  }
};
