import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../style/colors';

interface HighlightProps {
  children: React.ReactNode;
  linkTo?: string;
}

const useStyles = makeStyles(() => ({
  highlightContainer: {
    backgroundColor: colors.background,
    border: '1px solid',
    borderColor: colors.grey4,
    color: colors.primaryDark,
    padding: '4px',
    marginRight: '4px',
  },
}));

export const Highlight = (props: HighlightProps) => {
  const classes = useStyles();
  if (props.linkTo) {
    return (
      // Only links we control. No user input
      // nosemgrep: typescript.react.security.audit.react-href-var.react-href-var
      <a href={props.linkTo} className={classes.highlightContainer}>
        {props.children}
      </a>
    );
  } else {
    return <p className={classes.highlightContainer}>{props.children}</p>;
  }
};
