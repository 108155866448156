import React, { ReactElement } from 'react';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { BodyBlack80 } from '../text/BodyBlack80';

interface Props {
  headerText: string;
  headerIcon?: ReactElement;
  introText?: string;
  children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  introText: {
    marginTop: '8px',
    marginBottom: '16px',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  icon: {
    marginRight: '8px',
  },
}));
export const IntroCardTemplate = (props: Props) => {
  const classes = useStyles();
  return (
    <div>
      <Card>
        <Title>
          <span className={classes.headerContent}>
            {props.headerIcon ? <span className={classes.icon}>{props.headerIcon}</span> : null} {props.headerText}
          </span>
        </Title>
        {props.introText && <BodyBlack80 className={classes.introText}>{props.introText}</BodyBlack80>}
        <div>{props.children}</div>
      </Card>
    </div>
  );
};
