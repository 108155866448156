import {
  ClientUser,
  IntegrationUserId,
} from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/user';
import mixpanel from 'mixpanel-browser';
import { Location, MixpanelEvents, Page } from './mixpanelEvents';
import {
  FeatureToggles,
  FeatureToggleStatus,
} from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/config/index';
import { ConsoleViewOption } from '../../store/ducks/user';
import { Market } from '@minna-technologies/integration-portal-types/tech/minna/models';

export interface ViewedPageAdditionalProps {
  Environment?: string;
  'Cancellation ID'?: string;
  Version?: ConsoleViewOption;
}

export interface ClickedButtonAdditionalProps {
  Location?: Location;
  Environment?: string;
  'Cancellation ID'?: string;
  'Cancellation state'?: string;
  'Link name'?: string;
  'Link URL'?: string;
  'Client key name'?: string;
  'Client key namespace'?: string;
  'Client key market'?: string;
  Version?: ConsoleViewOption;
  'API key name'?: string;
  'API key market'?: Market;
}

export interface iMixpanelService {
  initMixpanel(token: string): void;

  initMock(): void;

  identifyUser(userId: IntegrationUserId): void;

  setUserProperties(properties: any): void;

  trackOverallCsat(number: number): void;

  trackOverallCsatWithComment(number: number, comment: string): void;

  trackViewedPage(page: Page, additionalProps?: ViewedPageAdditionalProps): void;

  trackClickedButton(page: Page, buttonName: string, additionalProps?: ClickedButtonAdditionalProps): void;

  isInitialized(): boolean;
}

const MixpanelService = (): iMixpanelService => {
  let initialized = false;
  let isMock = false;

  const methods = {
    initMixpanel,
    initMock,
    identifyUser,
    setUserProperties,
    trackOverallCsat,
    trackOverallCsatWithComment,
    trackViewedPage,
    trackClickedButton,
    isInitialized,
  };
  return methods;

  function initMixpanel(token: string): void {
    mixpanel.init(token, { api_host: 'https://api-eu.mixpanel.com' });
    initialized = true;
  }

  function isInitialized() {
    return initialized;
  }

  function initMock(): void {
    // eslint-disable-next-line no-console
    console.log('***** Using mock implementation for Mixpanel. *****');
    initialized = true;
    isMock = true;
  }

  function identifyUser(userId: IntegrationUserId): void {
    mixpanel.identify(userId);
  }

  function setUserProperties(properties: any): void {
    mixpanel.people.set(properties);
  }

  function trackOverallCsat(number: number): void {
    track(MixpanelEvents.OVERALL_CSAT_RATING, {
      rating: number,
    });
  }

  function trackOverallCsatWithComment(number: number, comment: string): void {
    track(MixpanelEvents.OVERALL_CSAT_COMMENT, {
      rating: number,
      comment,
    });
  }

  function trackViewedPage(page: Page, additionalProps?: ViewedPageAdditionalProps) {
    const props = {
      Page: page,
      ...additionalProps,
    };
    track(MixpanelEvents.VIEWED_PAGE, props);
  }

  function trackClickedButton(page: Page, buttonName: string, additionalProps?: ClickedButtonAdditionalProps) {
    const props = {
      Page: page,
      Button: buttonName,
      ...additionalProps,
    };
    track(MixpanelEvents.CLICKED_BUTTON, props);
  }

  function track(event: MixpanelEvents, props: any) {
    runWhenInitialized(() => {
      if (isMock) {
        // Logging what mixpanel would have sent. Only active when mixpanel is disabled (local and test env)
        // nosemgrep: javascript.lang.security.audit.unsafe-formatstring.unsafe-formatstring
        console.log(`Mixpanel mock - ${event} - `, props); // eslint-disable-line no-console
      } else {
        mixpanel.track(event, props);
      }
    });
  }

  /**
   * Wait for mixpanelService to be initialized, and then run func()
   */
  function runWhenInitialized(func: () => void, retries: number = 0) {
    const MIXPANEL_POLLING_DELAY_MS = 300;
    const MIXPANEL_POLLING_MAX_RETRIES = 20;

    if (retries > MIXPANEL_POLLING_MAX_RETRIES) {
      // eslint-disable-next-line no-console
      console.warn('Could not track mixpanel event, timed out when waiting for Mixpanel to initialize');
    } else if (mixpanelService.isInitialized()) {
      func();
    } else {
      setTimeout(() => runWhenInitialized(func, retries + 1), MIXPANEL_POLLING_DELAY_MS);
    }
  }
};

export const mixpanelService: iMixpanelService = MixpanelService();

export async function initMixpanelService(user: ClientUser, featureToggles: FeatureToggles) {
  if (!mixpanelService?.isInitialized()) {
    if (featureToggles.mixpanel === FeatureToggleStatus.Enabled) {
      mixpanelService.initMixpanel(process.env.REACT_APP_MIXPANEL_TOKEN as string);
      mixpanelService.identifyUser(user.id);
      mixpanelService.setUserProperties({
        platformName: user.platformName,
        createdAt: user.createdAt,
      });
    } else {
      mixpanelService.initMock();
    }
  }
}
