import React from 'react';
import debounce from 'lodash/debounce';
import { SearchBar } from '@minna-technologies/minna-ui/components/Inputs/SearchBar';

interface CancellationSearchProps {
  onSearchTriggered(searchValue: string): void;
}

export const CancellationSearchInput = (props: CancellationSearchProps) => {
  return (
    <div>
      <SearchBar
        name="cancellation-search"
        data-test="cancellation-search"
        fullWidth
        onChange={debounce((e) => {
          props.onSearchTriggered(e.target.value);
        }, 500)}
        placeholder="Search for cancellation id, merchant id, merchant name or user id"
      />
    </div>
  );
};
