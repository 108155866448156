import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

interface Props {
  size: string;
  backgroundColor: string | React.CSSProperties['color'];
  color: string | React.CSSProperties['color'];
  children: React.ReactNode;
}

const useStyles = (props: Props) =>
  makeStyles(() => {
    return {
      container: {
        display: 'flex',
        width: props.size,
        height: props.size,
        backgroundColor: props.backgroundColor,
        borderRadius: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        color: props.color,
        padding: '4px',
      },
    };
  });

export const CircleWithContent = (props: Props) => {
  const classes = useStyles(props)();
  return (
    <div className={classes.container} data-test="circle-with-content">
      {props.children}
    </div>
  );
};
