import { AppDispatch, RootState } from '../setup';
import { authenticateUser, FailedValidation, LoginErrors, logoutUser } from '../../services/authenticationService';
import { dashboard, loginPage } from '../../app/navigation/urls';
import { Sentry } from '../../utils/sentry';

interface LoginState {
  isLoggedIn: boolean;
  loginError: null | string;
}

const initialState: LoginState = {
  isLoggedIn: false,
  loginError: null,
};

export const loginSelectors = {
  loginError: (state: RootState) => state.login.loginError,
  isLoggedIn: (state: RootState) => state.login.isLoggedIn,
};

export const actionTypes = {
  SUCCESSFUL_LOGIN: 'login/SUCCESSFUL_LOGIN',
  FAILED_LOGIN: 'login/FAILED_LOGIN',
  RESET_LOGIN: 'login/RESET_LOGIN',
  LOGOUT: 'login/LOGOUT',
};

const loginEvents = {
  successfulLogin: () => ({ type: actionTypes.SUCCESSFUL_LOGIN, firebaseData: '' }),
  failedLogin: (errorCode: LoginErrors) => ({ type: actionTypes.FAILED_LOGIN, errorCode: errorCode }),
  resetLogin: () => ({ type: actionTypes.RESET_LOGIN }),
  logout: () => ({ type: actionTypes.LOGOUT }),
};

export const loginActions = {
  logIn,
  resetLogin,
  logout,
  isAlreadyLoggedIn,
};

export const loginReducer = (state: RootState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.FAILED_LOGIN:
      return {
        ...state,
        loginError: action.errorCode,
      };
    case actionTypes.SUCCESSFUL_LOGIN:
      return {
        ...state,
        loginError: null,
        isLoggedIn: true,
      };
    case actionTypes.RESET_LOGIN:
      return {
        ...state,
        loginError: null,
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        loginError: null,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};

function logIn(email: string, password: string, history: any, redirectTo: string | null) {
  return async (dispatch: AppDispatch) => {
    const result = await authenticateUser(email, password);
    if (result.type === 'Failure') {
      const error = result as FailedValidation;
      dispatch(loginEvents.failedLogin(error.reason));
      if (error.reason === LoginErrors.TOO_MANY_REQUESTS) {
        Sentry.captureMessage('User attempted to many login requests');
      }
    } else {
      dispatch(loginEvents.successfulLogin());
      history.push(redirectTo ?? dashboard);
      if (redirectTo) {
        // Workaround: trigger a reload in order to avoid the "consent" screen when redirecting to /api/docs/login
        history.go(0);
      }
    }
  };
}

/**
 * When we know that the user is authenticated, i.e. there is a valid user session and cookie, then we should trigger the redux state to reflect that.
 * This can happen on a hard refresh for example, where the redux state is cleared but the cookie remains.
 * The only function that has a purpose to use this is currently: FetchUserContext
 */
function isAlreadyLoggedIn() {
  return async (dispatch: AppDispatch) => {
    dispatch(loginEvents.successfulLogin());
  };
}

function resetLogin() {
  return async (dispatch: AppDispatch) => {
    dispatch(loginEvents.resetLogin());
  };
}

function logout(history: any) {
  return async (dispatch: AppDispatch) => {
    const result = await logoutUser();
    if (result.type == 'Success') {
      dispatch(loginEvents.logout());
      history.push(loginPage);
    } else {
      // TODO Add Sentry alert here when sentry is added
    }
  };
}
