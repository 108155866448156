import { AppDispatch, RootState } from '../setup';

interface ErrorState {
  httpErrorCode?: number;
}

const initialState: ErrorState = {
  httpErrorCode: undefined,
};

export const errorSelectors = {
  httpErrorCode: (state: RootState) => state.error.httpErrorCode,
};

export const errorActions = {
  setHttpErrorCode,
  clearHttpErrorCode,
};

export const actionTypes = {
  SET_HTTP_ERROR_CODE: 'SET_HTTP_ERROR_CODE',
  CLEAR_HTTP_ERROR_CODE: 'CLEAR_HTTP_ERROR_CODE',
};

const errorEvents = {
  setHttpErrorCode: (errorCode: number | undefined) => ({ type: actionTypes.SET_HTTP_ERROR_CODE, errorCode }),
  clearHttpErrorCode: () => ({ type: actionTypes.CLEAR_HTTP_ERROR_CODE }),
};

export const errorReducer = (state: RootState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_HTTP_ERROR_CODE:
      return {
        ...state,
        httpErrorCode: action.errorCode,
      };
    case actionTypes.CLEAR_HTTP_ERROR_CODE:
      return {
        ...state,
        httpErrorCode: undefined,
      };
    default:
      return state;
  }
};

// ACTIONS
function setHttpErrorCode(httpErrorCode: number) {
  return async (dispatch: AppDispatch) => {
    dispatch(errorEvents.setHttpErrorCode(httpErrorCode));
  };
}

function clearHttpErrorCode() {
  return async (dispatch: AppDispatch) => {
    dispatch(errorEvents.clearHttpErrorCode());
  };
}
