import React, { useEffect } from 'react';
import { Market } from '@minna-technologies/integration-portal-types/tech/minna/models';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { RHTextInput } from '@minna-technologies/minna-ui/components/Form/RHTextInput';
import sortBy from 'lodash/sortBy';
import { SelectOption } from '@minna-technologies/minna-ui/components/Inputs/Select/components/SelectOption';
import { RHSelect } from '@minna-technologies/minna-ui/components/Form/RHSelect';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { APIKeyProps, authenticationActions, authenticationSelectors } from '../../store/ducks/authentication';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { mixpanelService } from '../../services/mixpanel/mixpanelService';
import { Location, Page } from '../../services/mixpanel/mixpanelEvents';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: '48px',
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginTop: '28px',
  },
}));

export const CreateApiKeyComponent = ({ environmentName }: { environmentName: string }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const formMethods = useMinnaForm<{ apiKeyName: string; market: Market }>();
  const availableMarkets = sortBy(Object.values(Market));
  const apiKeys: APIKeyProps[] = useSelector(authenticationSelectors.apiKeys);
  const routerLocation = useLocation();
  const maybeCreatedAPIKey = useSelector(authenticationSelectors.temporaryPlainTextApiKey);

  useEffect(() => {
    if (maybeCreatedAPIKey) {
      // If the user navigates we don't want to show the key the next time they visit authentication
      dispatch(authenticationActions.removePlainTextAPIKey());
    }
  }, [routerLocation.pathname]);

  // In BI we have some requirements on the name. This is just translated from the `require()` in ApiKeyName in BI.
  const validateName = (name: string) => {
    const maybeExistingKeyWithName = apiKeys.find((key) => key.name === name);
    if (maybeExistingKeyWithName) {
      return 'A key with the specified name already exists. Please choose another name.';
    } else if (name.length < 3 || name.length > 100) {
      return 'The name must be between 3 and 100 characters long.';
    } else if (!/^[\w\-\s]+$/.test(name)) {
      return 'The name must only contain alpha-numerical values.';
    } else return true;
  };

  const registerNewAPIKey = () => {
    const apiKeyName = formMethods.getValues().apiKeyName;
    const market = formMethods.getValues().market;
    mixpanelService.trackClickedButton(Page.AUTHENTICATION, 'Generate', {
      'API key name': apiKeyName,
      'API key market': market,
      Environment: environmentName,
      Location: Location.Center,
    });
    dispatch(authenticationActions.registerNewAPIKey(apiKeyName, market, environmentName));
  };

  return (
    <Card>
      <div className={classes.header}>
        <Title>Generate a new API key here</Title>
      </div>
      <Form onSubmit={() => registerNewAPIKey()} formMethods={formMethods}>
        <RHTextInput
          label="API key name"
          placeholder="e.g Key to test environment 5C"
          name="apiKeyName"
          helpText="Choose a name that will help you recognize the key"
          fullWidth
          validation={{ required: 'Please provide a name for the API key.', validate: validateName }}
          data-test="api-key-name-input"
        />
        <RHSelect
          label={'Market'}
          fullWidth
          name={'market'}
          helpText={'Market where the API key will be used.'}
          data-test={'register-api-key-market-input'}
          validation={{ required: 'Please select a market.' }}
          defaultValue={availableMarkets[0]}
        >
          {availableMarkets.map((enumMarket) => {
            return <SelectOption key={enumMarket} label={enumMarket} value={enumMarket} />;
          })}
        </RHSelect>
        <div className={classes.buttonContainer}>
          <PrimaryButton type="submit" label="Generate" data-test="generate-api-key-submit" />
        </div>
      </Form>
    </Card>
  );
};
