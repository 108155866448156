import React from 'react';
import { ExternalLink } from '../../components/ExternalLink';
import { NumberedList } from '../../components/NumberedList';
import { BodyBlack80 } from '../../components/text/BodyBlack80';
import { integrationPortalSignupPage } from '../../navigation/urls';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';

export const SignupInstructions = () => {
  return (
    <div>
      <Title>Don't have an account yet?</Title>
      <br />
      <NumberedList
        listItems={[
          <BodyBlack80 key={1}>
            Go to our {<ExternalLink link={integrationPortalSignupPage}>Developer page</ExternalLink>}
          </BodyBlack80>,
          <BodyBlack80 key={2}>Press "Get console access"</BodyBlack80>,
          <BodyBlack80 key={3}>Fill out your details and request an account </BodyBlack80>,
        ]}
      />
    </div>
  );
};
