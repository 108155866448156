import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { useMinnaForm } from '@minna-technologies/minna-ui/components/Form/use-form';
import { TextInput } from '@minna-technologies/minna-ui/components/Inputs/TextInput';
import { Form } from '@minna-technologies/minna-ui/components/Form';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { TextArea } from '@minna-technologies/minna-ui/components/Inputs/TextArea';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { Snackbar } from '@minna-technologies/minna-ui/components/Snackbar';
import { authenticationActions, authenticationSelectors, RegisterClientStatus } from '../../store/ducks/authentication';
import { Select } from '@minna-technologies/minna-ui/components/Inputs/Select';
import { SelectOption } from '@minna-technologies/minna-ui/components/Inputs/Select/components/SelectOption';
import { Market } from '@minna-technologies/integration-portal-types/tech/minna/models';
import sortBy from 'lodash/sortBy';
import { colors } from '../style/colors';
import { mixpanelService } from '../../services/mixpanel/mixpanelService';
import { Page } from '../../services/mixpanel/mixpanelEvents';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';

interface UploadClientKeyComponentProps {
  environmentName: string;
}

const useStyles = makeStyles(() => ({
  leftCard: {
    marginRight: '8px',
    width: '100%',
    height: 'fit-content',
  },
  divider: {
    marginTop: '24px',
  },
  registerButton: {
    paddingTop: '24px',
    float: 'right',
  },
  margin: { margin: '8px 0' },
}));

export const UploadClientKeyComponent = (props: UploadClientKeyComponentProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const registerClientKeyStatus = useSelector(authenticationSelectors.registerClientKeyStatus);
  const maybeRegisterErrorMessage = useSelector(authenticationSelectors.maybeRegisterErrorMessage);
  const [clientKeyName, setClientKeyName] = useState('');
  const [clientNamespace, setClientNamespace] = useState<string | undefined>(undefined);
  const [publicKey, setPublicKey] = useState('');
  const firstMarket = sortBy(Object.values(Market))[0]?.toString();
  const [market, setMarket] = useState(firstMarket);
  const formMethods = useMinnaForm<{ clientKeyName: string }>();

  function registerClientKey() {
    mixpanelService.trackClickedButton(Page.AUTHENTICATION, 'Register client key', {
      'Client key name': clientKeyName,
      'Client key namespace': clientNamespace,
      'Client key market': market,
    });
    const clientNamespaceOrDefault = clientNamespace ? clientNamespace : 'default';
    dispatch(
      authenticationActions.registerClientKey(
        props.environmentName,
        clientKeyName,
        clientNamespaceOrDefault,
        market,
        publicKey
      )
    );
    setClientKeyName('');
    setPublicKey('');
    setMarket(firstMarket);
    setClientNamespace(undefined);
  }

  const getSnackbarMessage = (status: RegisterClientStatus): string => {
    if (status === RegisterClientStatus.SUCCESS) {
      return 'Successfully registered client key';
    } else if (maybeRegisterErrorMessage !== undefined) {
      return maybeRegisterErrorMessage;
    } else {
      return 'Unexpected error registering client key';
    }
  };

  const showSnackbar = registerClientKeyStatus !== RegisterClientStatus.NOT_STARTED;
  const isSuccess = registerClientKeyStatus === RegisterClientStatus.SUCCESS ? 'success' : 'error';
  const snackbarMessage = getSnackbarMessage(registerClientKeyStatus);

  const canRegisterClientKey = clientKeyName && publicKey && market;
  const margin = { margin: '8px 0' };

  return (
    <Card className={classes.leftCard}>
      <Snackbar
        open={showSnackbar}
        variant={isSuccess}
        message={snackbarMessage}
        data-test={'register-client-key-snackbar'}
        onClose={() => dispatch(authenticationActions.resetRegisterClientKeyStatus())}
        autoClose
      />
      <div>
        <Title color={colors.black80}>Upload your public key to register a client key</Title>
        <Divider fullWidth className={classes.divider} />
        <Form onSubmit={() => registerClientKey()} formMethods={formMethods}>
          <TextInput
            onChange={(e) => setClientKeyName(e.target.value)}
            label={'Client Key Name'}
            value={clientKeyName}
            placeholder={"e.g. John Doe's test environment key"}
            name={'clientKeyName'}
            type={'text'}
            helpText={'Choose a name that will help you recognize the key.'}
            fullWidth
            data-test={'register-client-key-name-input'}
            style={margin}
          />
          <TextInput
            onChange={(e) => setClientNamespace(e.target.value)}
            label={'Client Namespace'}
            value={clientNamespace}
            placeholder="default"
            name={'clientNamespace'}
            type={'text'}
            helpText={'Namespace for segmenting users, for example between different branches.'}
            fullWidth
            data-test={'register-client-key-namespace-input'}
            style={margin}
          />
          <Select
            label={'Market'}
            fullWidth
            helpText={'Market where the client key will be used.'}
            data-test={'register-client-key-market-input'}
            onChange={(e) => setMarket(e.target.value)}
            style={margin}
          >
            {sortBy(Object.values(Market)).map((enumMarket) => {
              return <SelectOption key={enumMarket} label={enumMarket} value={enumMarket} />;
            })}
          </Select>
          <TextArea
            onChange={(e) => setPublicKey(e.target.value)}
            label={'Public Key'}
            value={publicKey}
            placeholder={'-----BEGIN PUBLIC KEY-----\n' + '...\n' + '...\n' + '...\n' + '-----END PUBLIC KEY-----'}
            helpText={
              "Public key, derived from the private key you'll use to create JWTs for requesting access tokens."
            }
            rows={10}
            name={'publicKey'}
            fullWidth
            data-test={'register-client-key-public-key-input'}
            style={margin}
          />
          <div className={classes.registerButton}>
            <PrimaryButton
              disabled={!canRegisterClientKey}
              type="submit"
              label="Register"
              data-test="register-client-key-button"
            />
          </div>
        </Form>
      </div>
    </Card>
  );
};
