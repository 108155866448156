import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { minnaTheme } from '@minna-technologies/minna-ui/styles/themes/minna';
import { Provider } from 'react-redux';
import configureStore from '../store/setup';
import { AppRouter } from './AppRouter';
/* eslint import/no-unassigned-import: "off" */
import './style/app.css';
import * as Sentry from '@sentry/react';

export const App = () => {
  return (
    <Sentry.ErrorBoundary>
      <MuiThemeProvider theme={minnaTheme}>
        <Provider store={configureStore}>
          <div data-test="minna-integration-portal">
            <AppRouter />
          </div>
        </Provider>
      </MuiThemeProvider>
    </Sentry.ErrorBoundary>
  );
};
