import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { errorActions, errorSelectors } from '../../store/ducks/error';
import { SessionTimeoutError } from '../navigation/error/SessionTimeoutError';
import { UnexpectedError } from '../navigation/error/UnexpectedError';
import { PageNotFound } from '../navigation/error/PageNotFound';
import { Sentry } from '../../utils/sentry';

export const ErrorHandlerComponent = ({ children }: { children: React.ReactNode }) => {
  const httpErrorCode = useSelector(errorSelectors.httpErrorCode);
  const history = useHistory();
  const dispatch = useDispatch();

  // Remove the error state when the user navigates. If we don't, the user would be "trapped" into error pages forever
  useEffect(() => {
    return history.listen(() => {
      if (httpErrorCode) {
        dispatch(errorActions.clearHttpErrorCode());
      }
    });
  }, [httpErrorCode]);

  switch (true) {
    case httpErrorCode === 401:
      return <SessionTimeoutError />;
    case httpErrorCode === 404:
      Sentry.captureMessage('User was shown a 404-page', { extra: { url: history.location } });
      return <PageNotFound />;
    case httpErrorCode >= 500:
      Sentry.captureMessage('User was shown an `Unexpected error page`', { extra: { url: history.location } });
      return <UnexpectedError />;
    default:
      return <>{children}</>;
  }
};
