import React from 'react';
import { PrimaryButton } from '@minna-technologies/minna-ui/components/Buttons/PrimaryButton';
import { useHistory } from 'react-router-dom';
import { loginPage } from '../../navigation/urls';
import { colors } from '../../style/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { UserIcon } from '@minna-technologies/minna-ui/icons/User';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';

const useStyles = makeStyles(() => ({
  backgroundContainer: {
    background: colors.primaryGradient,
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  componentContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  headline: {
    paddingBottom: '56px',
  },
  cardContainer: {
    width: '360px',
    height: '260px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    padding: '16px',
  },
  loginIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  signupText: {
    paddingLeft: '12px',
    fontSize: '22px',
  },
  contentContainer: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
}));

export const SignupCompletedPage = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.backgroundContainer}>
      <div className={classes.componentContainer}>
        <Headline1 color={colors.white} className={classes.headline}>
          Welcome to the Minna Console
        </Headline1>
        <Card className={classes.cardContainer}>
          <div className={classes.loginIconContainer}>
            <UserIcon nativeColor={colors.secondary} />
            <Title className={classes.signupText}>Complete sign up</Title>
          </div>
          <div className={classes.contentContainer}>
            <div>
              <Body>Signup completed!</Body>
              <Body>You can now log in and start using the portal.</Body>
            </div>
            <PrimaryButton
              onClick={() => history.push(loginPage)}
              label={'Go to login'}
              data-test={'continue-to-login'}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};
