import React, { useState, useEffect } from 'react';
import {
  confirmPasswordReset,
  getErrorReasonFromFirebaseError,
  verifyPasswordResetCode,
} from '../../../services/firebase/Firebase';
import { colors } from '../../style/colors';
import { makeStyles } from '@material-ui/core/styles';
import { Headline1 } from '@minna-technologies/minna-ui/components/Typography/Headline1';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { UserIcon } from '@minna-technologies/minna-ui/icons/User';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import { PasswordWidget } from '../PasswordWidget/PasswordWidget';
import { resetPasswordCompletedPage } from '../../navigation/urls';
import { Snackbar } from '@minna-technologies/minna-ui/components/Snackbar';

const useStyles = makeStyles(() => ({
  backgroundContainer: {
    background: colors.primaryGradient,
    height: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headline: {
    paddingBottom: '56px',
  },
  cardContainer: {
    minWidth: '360px',
    maxWidth: '420px',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    padding: '16px',
  },
  loginIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  signupText: {
    paddingLeft: '12px',
    fontSize: '22px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
  },
}));

export interface ResetPasswordComponentProps {
  actionCode: string;
}

export const ResetPasswordPage = (props: ResetPasswordComponentProps) => {
  const [error, setError] = useState('');
  const [email, setEmail] = useState<string | undefined>();
  const classes = useStyles();
  const isError = !!error;

  useEffect(() => {
    verifyPasswordResetCode(props.actionCode)
      .then((verifiedEmail: string) => setEmail(verifiedEmail))
      .catch((error) => {
        const errorMessage = getErrorReasonFromFirebaseError(error, 'Unknown error occurred during password reset');
        setError(errorMessage);
      });
  }, []);

  const selectPassword = async (password: string): Promise<any> => confirmPasswordReset(props.actionCode, password);

  const resetPassword = (
    <PasswordWidget
      title="Reset Password"
      selectPasswordText="Select a new password that is:"
      updatePassword={selectPassword}
      nextPage={resetPasswordCompletedPage}
    />
  );

  const invalidActionCode = (
    <Card className={classes.cardContainer}>
      <div className={classes.loginIconContainer}>
        <UserIcon nativeColor={colors.secondary} />
        <Title className={classes.signupText}>Reset password</Title>
      </div>
      <div className={classes.contentContainer}>
        <Body>Unfortunately your password reset code is invalid or has expired</Body>
      </div>
    </Card>
  );

  return (
    <div className={classes.backgroundContainer}>
      <Snackbar
        autoClose
        variant={'error'}
        open={isError}
        message={error}
        data-test={'select-password-error'}
        onClose={() => setError('')}
      />
      <div className={classes.componentContainer}>
        <Headline1 color={colors.white} className={classes.headline}>
          Welcome back to the Minna Console
        </Headline1>
        {email ? resetPassword : invalidActionCode}
      </div>
    </div>
  );
};
