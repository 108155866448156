import React, { useState } from 'react';
import { ClientAccessToken } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { BodyBlack80 } from '../components/text/BodyBlack80';
import { ContentCopyIcon } from '../style/icons/ContentCopyIcon';
import Button from '@material-ui/core/Button';
import { Snackbar } from '@minna-technologies/minna-ui/components/Snackbar';
import { makeStyles } from '@material-ui/core/styles';

import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
  tokenContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
}));
export const AccessTokenComponent = ({
  accessToken,
  onCopyAccessToken,
}: {
  accessToken: ClientAccessToken;
  onCopyAccessToken?: () => void;
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const parsedDate = new Date(Date.parse(accessToken.validUntil));

  const classes = useStyles();
  return (
    <div>
      <div>
        <span className={classes.tokenContainer}>
          <BodyBlack80 data-test="access-token-text">
            Access token: <b>{accessToken.accessToken}</b>
          </BodyBlack80>
          <Button
            data-test={'copy-access-token-button'}
            onClick={() => {
              if (onCopyAccessToken) {
                onCopyAccessToken();
              }
              navigator.clipboard.writeText(accessToken.accessToken).then(() => setSnackbarOpen(true));
            }}
          >
            <ContentCopyIcon />
          </Button>
        </span>
        <BodyBlack80>Valid until: {format(parsedDate, 'yyyy-MM-dd HH:mm:ss zzzz')}</BodyBlack80>
      </div>
      <Snackbar
        autoClose
        open={snackbarOpen}
        variant={'default'}
        message={'Copied the access token'}
        onClose={() => setSnackbarOpen(false)}
      />
    </div>
  );
};
