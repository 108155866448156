import { signInWithEmailAndPassword, signOut } from './firebase/Firebase';
import { FirebaseErrors } from './firebase/FirebaseEnums';
import * as AuthenticationApi from '@minna-technologies/integration-portal-v1/AuthenticationApi';
import { get } from 'lodash';

export enum LoginErrors {
  INVALID_EMAIL_OR_PASSWORD = 'INVALID_EMAIL_OR_PASSWORD',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export interface SuccessfulValidation {
  type: 'Success';
}

export interface FailedValidation {
  type: 'Failure';
  reason: LoginErrors;
}

export type ValidationResult = SuccessfulValidation | FailedValidation;

export const authenticateUser = async (email: string, password: string): Promise<ValidationResult> => {
  try {
    const firebaseResult = await signInWithEmailAndPassword(email, password);
    const idToken = await firebaseResult.user?.getIdToken();
    if (idToken) {
      await AuthenticationApi.authenticateIntegrationUser({ idToken: idToken });
      return { type: 'Success' };
    } else {
      return { type: 'Failure', reason: LoginErrors.UNKNOWN_ERROR };
    }
  } catch (error) {
    const errorCode = get(error, 'code', 'default');
    return { type: 'Failure', reason: firebaseErrorToApplicationError(errorCode) };
  }
};

export interface SuccessfulLogout {
  type: 'Success';
}
export interface FailedLogout {
  type: 'Failure';
  reason: Error;
}

export type LogoutResult = SuccessfulLogout | FailedLogout;

export const logoutUser = async (): Promise<LogoutResult> => {
  try {
    await AuthenticationApi.logout(); // Signout from console
    await signOut(); // Signout from Firebase
    return { type: 'Success' };
  } catch (error) {
    return { type: 'Failure', reason: error as Error };
  }
};

const firebaseErrorToApplicationError = (firebaseError: string): LoginErrors => {
  switch (firebaseError) {
    case FirebaseErrors.INVALID_EMAIL:
      return LoginErrors.INVALID_EMAIL_OR_PASSWORD;
    case FirebaseErrors.USER_NOT_FOUND:
      return LoginErrors.INVALID_EMAIL_OR_PASSWORD;
    case FirebaseErrors.INVALID_PASSWORD:
      return LoginErrors.INVALID_EMAIL_OR_PASSWORD;
    case FirebaseErrors.TOO_MANY_REQUESTS:
      return LoginErrors.TOO_MANY_REQUESTS;
    default:
      return LoginErrors.UNKNOWN_ERROR;
  }
};
