import { makeStyles } from '@material-ui/core/styles';
import * as urls from '../urls';
import { HelpIcon } from '@minna-technologies/minna-ui/icons/Help';
import { ElectricityNetworkIcon } from '@minna-technologies/minna-ui/icons/ElectricityNetwork';
import { EnvironmentIcon } from '@minna-technologies/minna-ui/icons/Environment';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { Overline } from '../../components/text/Overline';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { ClientUserContext } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/user';
import { Feature } from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/features';
import { colors } from '../../style/colors';
import { BankName } from './BankName';
import { UserBar } from './UserBar';
import { appHeaderHeight } from './AppHeader';
import { IconMenuItem, SimpleMenuItem } from './IconMenuItem';
import { MenuItemWithSubMenu, MenuItemWithSubMenuSupport } from './MenuItemWithSubMenuSupport';
import { loginActions } from '../../../store/ducks/login';
import { useDispatch, useSelector } from 'react-redux';
import { PayPerCallIcon } from '@minna-technologies/minna-ui/icons/PayPerCall';
import { AddIcon } from '@minna-technologies/minna-ui/icons/Add';
import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { CodeIcon } from '../../style/icons/CodeIcon';
import { BookIcon } from '../../style/icons/BookIcon';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';
import { mixpanelService } from '../../../services/mixpanel/mixpanelService';
import { Location, Page } from '../../../services/mixpanel/mixpanelEvents';
import { ViewSelector } from '../consoleviews/ViewSelector';
import { useVersionedDocsUrls, VersionedDocsUrls } from '../useVersionedDocsUrls';
import { ConsoleViewOption, userSelectors } from '../../../store/ducks/user';

const drawerWidth = 240;

const useMenuDrawerStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    paddingTop: '56px',
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: appHeaderHeight + 16 + 'px',
  },
  overline: {
    marginTop: '16px',
    marginLeft: '16px',
  },
  userBar: {
    marginBottom: '16px',
    marginLeft: '16px',
    width: 'inherit',
    // Setting width of a 'fixed' element. Need to subtract the margin left and "add" the margin right.
    maxWidth: drawerWidth - 16 - 16 + 'px',
  },
  viewSelector: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8px',
  },
  mainContent: {
    width: '100%',
    paddingLeft: '24px',
    paddingRight: '24px',
    paddingTop: appHeaderHeight + 24 + 'px',
  },
  listRoot: {
    backgroundColor: 'inherit',
  },
  otherEnvironmentsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '8px',
    margin: '8px 16px',
    paddingLeft: '8px',
    border: '2px dashed',
    borderColor: colors.grey3,
  },
  otherEnvironmentsText: {
    padding: '8px 0px 8px 12px',
  },
}));

const staticMenuItems = (versionedDocsUrls: VersionedDocsUrls): SimpleMenuItem[] => [
  {
    title: 'Docs',
    icon: BookIcon,
    path: versionedDocsUrls.mainDocs,
    linkToExternal: true,
    mixpanelProps: {
      Button: 'Docs',
    },
  },
  {
    title: 'API reference',
    icon: CodeIcon,
    path: versionedDocsUrls.apiReference,
    linkToExternal: true,
    mixpanelProps: {
      Button: 'API reference',
    },
  },
  {
    title: 'Operational status',
    icon: ElectricityNetworkIcon,
    path: urls.statusPageIo,
    linkToExternal: true,
    mixpanelProps: {
      Button: 'Operational status',
    },
  },
  {
    title: 'Contact',
    icon: HelpIcon,
    path: urls.jiraServiceDeskExternalLink,
    linkToExternal: true,
    mixpanelProps: {
      Button: 'Contact support',
    },
  },
];

const menuItemFromAction = (
  feature: Feature,
  environmentName: string,
  viewVersion: ConsoleViewOption
): SimpleMenuItem => {
  const styles = {
    subMenuItemIcon: {
      width: '12px',
      height: '20px',
      left: '36px',
      top: '8px',
      borderBottom: '2px solid',
      borderLeft: '2px solid',
      borderColor: colors.primaryDark,
    },
  };
  const SubMenuItemIcon = (props: unknown) => <div style={styles.subMenuItemIcon} {...props} />;

  switch (feature) {
    case Feature.GetStarted:
      return {
        title: 'Get started',
        path: urls.getStartedPage(environmentName),
        icon: SubMenuItemIcon,
        mixpanelProps: {
          Button: 'Get started',
          Environment: environmentName,
          Version: viewVersion,
        },
      };
    case Feature.CancellationManagement:
      return {
        title: 'Cancel testing',
        path: urls.cancellationPage(environmentName),
        icon: SubMenuItemIcon,
        mixpanelProps: {
          Button: 'Cancel testing',
          Environment: environmentName,
          Version: viewVersion,
        },
      };
    case Feature.AuthenticationManagement:
      return {
        title: 'Authentication',
        path: urls.authenticationPage(environmentName),
        icon: SubMenuItemIcon,
        mixpanelProps: {
          Button: 'Authentication',
          Environment: environmentName,
          Version: viewVersion,
        },
      };
    case Feature.Security:
      return {
        title: 'Security',
        path: urls.securityManagementPage(environmentName),
        icon: SubMenuItemIcon,
        mixpanelProps: {
          Button: 'Security',
          Environment: environmentName,
          Version: viewVersion,
        },
      };
    default:
      return {
        title: 'No op',
        path: '/',
        icon: SubMenuItemIcon,
      };
  }
};

interface MenuProps {
  children: React.ReactNode;
  userContext?: ClientUserContext;
  currentPage: Page;
}

export const Menu = (props: MenuProps) => {
  const classes = useMenuDrawerStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentVersion = useSelector(userSelectors.currentConsoleViewOption);
  const currentPath = useLocation().pathname;
  const versionedDocsUrls = useVersionedDocsUrls();
  const [activeEnvironment, setActiveEnvironment] = useState<MenuItemWithSubMenu | undefined>();
  const environmentMenuItems: MenuItemWithSubMenu[] = !props.userContext
    ? []
    : props.userContext.environments.map((environment) => ({
        title: capitalizeFirstLetter(environment.environmentName),
        icon: <EnvironmentIcon nativeColor={colors.grey1} />,
        path: '',
        subMenuItems: environment.enabledFeatures
          .filter(filterV2SpecificItemsInV3)
          .map((action: Feature) => menuItemFromAction(action, environment.environmentName, currentVersion))
          .filter((i: SimpleMenuItem) => i.title !== 'No op'),
      }));

  // Hides the "Cancellation testing" menu item if user has selected V3.
  function filterV2SpecificItemsInV3(feature: Feature) {
    if (currentVersion === ConsoleViewOption.API_VERSION_LEGACY) return true;
    else return feature !== Feature.CancellationManagement;
  }

  const bankNameOnClick = () => {
    mixpanelService.trackClickedButton(props.currentPage, 'Bank name', {
      Location: Location.Menu,
    });
    history.push(urls.dashboard);
  };

  const contactUsOnClick = () => {
    mixpanelService.trackClickedButton(props.currentPage, 'Contact support link', {
      Location: Location.Menu,
    });
    window.open(urls.jiraServiceDeskExternalLink, '_blank');
  };

  return (
    <div className={classes.root}>
      <Drawer className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }} anchor="left">
        {props.userContext && <BankName onClick={bankNameOnClick} name={props.userContext.user.platformName} />}
        <div className={classes.userBar}>
          {!!props.userContext && (
            <UserBar
              name={props.userContext.user.emailAddress}
              onSignOutClick={() => dispatch(loginActions.logout(history))}
            />
          )}
        </div>
        <Divider />
        <div className={classes.viewSelector}>
          <ViewSelector />
        </div>
        <IconMenuItem
          item={{
            title: 'Dashboard',
            icon: PayPerCallIcon,
            path: urls.dashboard,
            mixpanelProps: {
              Button: 'Dashboard',
              Version: currentVersion,
            },
          }}
          currentPath={currentPath}
          currentPage={props.currentPage}
        />
        <Overline className={classes.overline}>Environments</Overline>
        <List
          classes={{
            root: classes.listRoot,
          }}
        >
          {environmentMenuItems.map((item) => (
            <MenuItemWithSubMenuSupport
              key={item.title}
              item={item}
              currentPath={currentPath}
              currentPage={props.currentPage}
              selected={
                activeEnvironment?.title === item.title || item.subMenuItems.some((s) => s.path === currentPath)
              }
              onClick={() => updateOpenEnvironment(item)}
            />
          ))}
        </List>
        <div className={classes.otherEnvironmentsContainer}>
          <AddIcon nativeColor={colors.primaryDark} size={16} />
          <Caption color={colors.black80} className={classes.otherEnvironmentsText}>
            To get access to other environments, <a onClick={contactUsOnClick}>contact us</a>.
          </Caption>
        </div>
        <Divider />
        <Overline className={classes.overline}>Tools</Overline>
        <List>
          {staticMenuItems(versionedDocsUrls).map((item) => (
            <IconMenuItem key={item.title} item={item} currentPath={currentPath} currentPage={props.currentPage} />
          ))}
        </List>
      </Drawer>
      <main className={classes.mainContent}>{props.children}</main>
    </div>
  );

  function updateOpenEnvironment(clickedEnvironment: MenuItemWithSubMenu) {
    if (clickedEnvironment.title === activeEnvironment?.title) {
      setActiveEnvironment(undefined);
    } else setActiveEnvironment(clickedEnvironment);
  }
};
