import { AxiosError } from 'axios';

export interface BasicError {
  id: string;
  message: string;
  reason: string;
}

export interface BasicErrorResponse {
  error: BasicError;
}

const fromAxiosError = (axiosError: AxiosError): BasicErrorResponse | undefined => {
  try {
    return axiosError.response?.data as BasicErrorResponse;
  } catch (error) {
    return undefined;
  }
};

export const parseErrorToBasicErrorResponse = (error: Error): BasicErrorResponse | undefined => {
  try {
    return fromAxiosError(error as AxiosError);
  } catch (error) {
    return undefined;
  }
};
