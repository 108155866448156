import { Caption } from '@minna-technologies/minna-ui/components/Typography/Caption';
import { UserIcon } from '@minna-technologies/minna-ui/icons/User';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../style/colors';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    marginTop: '8px',
    padding: '8px',
    backgroundColor: colors.background,
    borderRadius: '50px',
  },
  userContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    fontSize: '12px',
  },
  userIcon: {
    marginRight: '8px',
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '180px',
  },
  divider: {
    transform: 'rotate(90deg)',
    width: '32px',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  signOutIcon: {
    cursor: 'pointer',
  },
}));

export const UserBar = ({ name, onSignOutClick }: { name: string; onSignOutClick(): void }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <UserIcon className={classes.userIcon} />
      <div className={classes.userContainer}>
        <Caption className={classes.text} data-test="menu-username">
          {name}
        </Caption>
        <Caption variant="link" color={colors.primary} onClick={() => onSignOutClick()} data-test="menu-logout">
          Sign out
        </Caption>
      </div>
    </div>
  );
};
