import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { authenticationActions, ClientKeyProps, authenticationSelectors } from '../../store/ducks/authentication';
import { UploadClientKeyComponent } from './UploadClientKeyComponent';
import { ListClientKeysComponent } from './ListClientKeysComponent';
import { PageNotFound } from '../navigation/error/PageNotFound';
import { Body } from '@minna-technologies/minna-ui/components/Typography/Body';
import { colors } from '../style/colors';
import { CircleWithContent } from '../components/CircleWithContent';
import { BookIcon } from '../style/icons/BookIcon';
import { Divider } from '@minna-technologies/minna-ui/components/Divider';
import { Title } from '@minna-technologies/minna-ui/components/Typography/Title';
import * as urls from '../navigation/urls';
import type {
  ClientAccessToken,
  ClientEnvironment,
} from '@minna-technologies/integration-portal-types/tech/minna/integrationportal/environment';
import { AccessTokenComponent } from './AccessTokenComponent';
import { Card } from '@minna-technologies/minna-ui/components/Card';
import { mixpanelService } from '../../services/mixpanel/mixpanelService';
import { Location, Page } from '../../services/mixpanel/mixpanelEvents';
import { ExternalLinkWithIcon } from '../components/ExternalLinkWithIcon';

const useStyles = makeStyles(() => ({
  textContainer: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    paddingBottom: '8px',
  },
  contentContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '16px',
    padding: '16px 16px 16px 0px',
  },
  accessTokenContainer: {
    maxWidth: '50%',
    paddingRight: '8px',
  },
}));

export const ClientKeysPage = ({ currentEnvironment }: { currentEnvironment: ClientEnvironment }) => {
  const dispatch = useDispatch();
  const clientKeys: ClientKeyProps[] = useSelector(authenticationSelectors.clientKeys);
  const maybeEnvironmentAccessToken: ClientAccessToken | undefined = currentEnvironment?.maybeAccessToken;

  useEffect(() => {
    dispatch(authenticationActions.listClientKeys(currentEnvironment.environmentName));
  }, [currentEnvironment.environmentName]);

  const trackExternalLinkClick = (buttonName: string) => {
    mixpanelService.trackClickedButton(Page.AUTHENTICATION, buttonName, {
      Location: Location.Center,
      Environment: currentEnvironment.environmentName,
    });
  };
  const trackCopyAccessToken = () => {
    mixpanelService.trackClickedButton(Page.AUTHENTICATION, 'Copy access token', {
      Location: Location.Center,
      Environment: currentEnvironment.environmentName,
    });
  };

  const classes = useStyles();

  const AccessTokenPart = () => (
    <div>
      <div className={classes.textContainer}>
        <Title color={colors.black80}>Option</Title>
        <CircleWithContent backgroundColor={colors.primary} size={'16px'} color={colors.white}>
          1
        </CircleWithContent>
        <Body color={colors.black80}>
          Use the below generated access token to authenticate with our API.{' '}
          <ExternalLinkWithIcon
            link={urls.externalAuthenticationJwtLink}
            icon={<BookIcon nativeColor={colors.primary} size={16} />}
            text={'Authentication'}
          />
        </Body>
      </div>
      <div className={classes.contentContainer}>
        {maybeEnvironmentAccessToken && (
          <Card>
            <AccessTokenComponent accessToken={maybeEnvironmentAccessToken} onCopyAccessToken={trackCopyAccessToken} />
          </Card>
        )}
      </div>
    </div>
  );

  const AddNewClientKeyPart = ({ showOptionNumber }: { showOptionNumber: boolean }) => (
    <div data-test="client-keys-add-new-page">
      <div className={classes.textContainer}>
        {showOptionNumber && (
          <>
            <Title color={colors.black80}>Option</Title>
            <CircleWithContent backgroundColor={colors.primary} size={'16px'} color={colors.white}>
              2
            </CircleWithContent>
          </>
        )}
        <Body color={colors.black80}>
          Authenticate by creating a personal key (go through{' '}
          <ExternalLinkWithIcon
            link={urls.externalAuthenticationLinkLegacy}
            onClick={() => trackExternalLinkClick('Authentication link')}
            icon={<BookIcon nativeColor={colors.primary} size={16} />}
            text={'Authentication'}
          />{' '}
          for full instructions)
        </Body>
      </div>
      <div className={classes.contentContainer}>
        <UploadClientKeyComponent environmentName={currentEnvironment.environmentName} />
        <ListClientKeysComponent clientKeys={clientKeys} />
      </div>
    </div>
  );

  if (currentEnvironment === undefined) {
    return <PageNotFound />;
  } else {
    return (
      <div>
        {maybeEnvironmentAccessToken ? (
          <>
            <AccessTokenPart />
            <Divider fullWidth />
            <AddNewClientKeyPart showOptionNumber />
          </>
        ) : (
          <AddNewClientKeyPart showOptionNumber={false} />
        )}
      </div>
    );
  }
};
